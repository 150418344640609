import _ from "lodash"
import { UnattendedEngineService } from "@/common/api/api.service"
import EngineService from "@/common/engine.service"

export default {
  saveWebsocketConnCompanyAction({ commit }, conn) {
    commit("saveWebsocketCommonConnection", conn)
  },
  unattendedEnginesGetList({ commit }, params) {
    if (!params) params = { p: 1, pp: 10 }
    return UnattendedEngineService.get(params).then(function (result) {
      let unattendedEnginesData = result.data.data
      let presetTreeviewEngines =
        EngineService.parseUnattendedPresetTreeviewEngines(
          unattendedEnginesData
        )
      commit("saveUnattendedPresetTreeviewEngines", presetTreeviewEngines)
      // let presetEngines = EngineService.parseUnattendedPresetEngines(unattendedEnginesData["engines_list"]);
      // commit('saveUnattendedPresetEngines', presetEngines);
    })
  },
  unattendedEnginesSingleInfoAction({ commit }, data) {
    let getParams = {
      slug: data.response._key,
    }
    return UnattendedEngineService.get(getParams).then(function (result) {
      let engineData = result.data.data
      return engineData
    })
  },
  unattendedSaveEnginesStatus({ commit }, data) {
    commit("saveUnattendedEnginesCPUGPUStatus", data)
  },
  unattendedResetEnginesStatus({ commit, state }) {
    _.forEach(state.allEngines, function (value, enginePk) {
      let dataEnginesInfo = {
        enginePk: enginePk,
        Status: { cpu: null, gpu: null },
        isUnattendedOnline: false,
        isLoginedAspx: false,
      }
      commit("saveUnattendedEnginesIsOnlineStatus", dataEnginesInfo)
      commit("saveUnattendedEnginesCPUGPUStatus", dataEnginesInfo)
      commit("saveEngineIsLoginedAspxStatus", dataEnginesInfo)
    })
  },
  unattendedIncomingConnectionCreateAction({ commit }, data) {
    return UnattendedEngineService.Connection.Incoming.create(data)
  },
  unattendedBidirectionalConnectionCreateAction({ commit }, data) {
    return UnattendedEngineService.Connection.Bidirectional.create(data)
  },
  unattendedInputsChangeOrderAction({ commit }, data) {
    return UnattendedEngineService.Input.order(data)
  },
  unattendedOutputsChangeOrderAction({ commit }, data) {
    return UnattendedEngineService.Output.order(data)
  },
  unattendedInputChangeAction({ state }, data) {
    return UnattendedEngineService.Input.change(data.slug, data.params)
  },
  unattendedOutputChangeAction({ state }, data) {
    return UnattendedEngineService.Output.change(data.slug, data.params)
  },
  unattendedEnginesConnectionDeleteAction({ state }, data) {
    return UnattendedEngineService.Connection.destroy(data)
  },
  unattendedSaveOnlineStatus({ commit }, data) {
    commit("saveUnattendedEnginesIsOnlineStatus", data)
  },

  initSingleEngineParams({ commit }, params) {
    commit("SET_WEB_ENGINE_PK", params.pk)
    commit("SET_WEB_ENGINE_NAME", params.name)
  },
  changeWebcameraStartedStatus({ commit }, status) {
    commit("MARK_WEBCAMERA_STARTED", status)
  },

  saveWebcameraNickname({ commit }, name) {
    commit("SAVE_WEBCAMERA_NICKNAME", name)
  },
  saveWebcameraPeerId({ commit }, peerId) {
    commit("SAVE_WEBCAMERA_PEER_ID", peerId)
  },
  saveWebengineActiveVideo({ commit }, data) {
    let activeValue = data ? data.deviceId : undefined
    commit("SET_WEB_ENGINE_ACTIVE_VIDEO", activeValue)
  },
  saveWebengineActiveAudio({ commit }, data) {
    let activeValue = data ? data.deviceId : undefined
    commit("SET_WEB_ENGINE_ACTIVE_AUDIO", activeValue)
  },
  setSelectedEngineAction({ commit }, value) {
    commit("setSelectedEngine", value)
  },
  setSelectedConnectionAction({ commit }, value) {
    commit("setSelectedConnection", value)
  },
  fillDataUnattendedEngines({ commit, state }, newData) {
    let app = this
    _.forEach(newData, function (value, index) {
      // console.log('value', value);
      // console.log('index', index);
      let dataMutation = {
        index: index,
        value: value,
      }
      commit("saveUnattendedEngines", dataMutation)
    })
  },
  // saveEnginesInfoAction ({commit}, data){
  //     commit('saveEnginesInfo', data)
  // },
  setWebsocketConnectionCompanyAction({ commit }, value) {
    return commit("setWebsocketConnectionCompany", value)
  },
}
