import Vue from "vue"
import vuetify from "@/plugins/vuetify"
import router from "./router"
import store from "./store"
import "./plugins"
import "./plugins/vee-validate"
import "./plugins/vue-axios"
import "./plugins/vue-session"
import "./plugins/vue-deepset"
import "./plugins/register-components"
import "./plugins/sentry"
import "./plugins/casl"
import "./filters"
import "@/validators/general"
import App from "./App.vue"
import ApiService from "./common/api/api.service"
import axiosInterceptor from "./common/api/axios.interceptor"

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")
Vue.config.devtools = true

const cbAxios = axiosInterceptor.cb.bind({ router, store })
ApiService.init(cbAxios)
