import UserService from "@/common/api/user.service"

export default {
  usersGetList({ commit, dispatch }, params) {
    dispatch("saveTableLoaderAction", true)
    UserService.usersList(params)
      .then((response) => {
        // console.groupCollapsed(response)
        commit("saveUsersList", response.data.data)
        dispatch("saveTableLoaderAction", false)
      })
      .catch((error) => {
        console.log("STORE userGetList ERROR")
        console.log(error)
        dispatch("saveTableLoaderAction", false)
      })
  },
  userCreate(context, formData) {
    return UserService.userCreate(formData.data)
  },
  userEdit({ state }, formData) {
    return UserService.userUpdate(formData.slug, formData.data)
  },
  userDelete({ state }, data) {
    // console.log('store user delete', data)
    return UserService.userDelete(data._key)
  },
  permanentlyUserDelete({ state }, data) {
    // console.log('store permanently user delete', data)
    return UserService.permanentlyUserDelete(data._key)
  },
  userProfileGetData({ state }) {
    return UserService.userItem()
  },
  userProfileEdit({ state }, data) {
    return UserService.userUpdate(data._key, data)
  },
}
