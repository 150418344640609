import ReconnectingWebSocket from "reconnecting-websocket"
import UserService from "@/common/api/user.service"
import _ from "lodash"

export const CommonConnectionService = {
  init(self) {
    let conn = new ReconnectingWebSocket(this.getUrl())
    let token = UserService.getToken()
    conn.addEventListener("open", () => {
      conn.send(
        JSON.stringify({
          path: "1.0.auth.login",
          args: {
            access_token: token,
          },
        })
      )
    })
    conn.addEventListener("message", (msg) => {
      // console.log(msg)
      if (Object.keys(msg).length > 0) {
        let data = JSON.parse(msg.data)
        let path = _.get(data, "path", "")
        if (path == "1.0.auth.login") {
          self.$store.dispatch("setAuthWsAction", true)
        }
      }
    })
    conn.addEventListener("close", () => {
      self.$store.dispatch("setAuthWsAction", false)
    })
    return conn
  },
  getUrl() {
    let websocketPort =
      process.env.VUE_APP_ARHT_WEBSOCKET_PORT == "80"
        ? ""
        : ":" + process.env.VUE_APP_ARHT_WEBSOCKET_PORT
    let address = process.env.VUE_APP_ARHT_WEB_DOMAIN + websocketPort
    let protocol = process.env.VUE_APP_ARHT_WEBSOCKET_PROTO || ""
    return protocol + "://" + address + "/ws/1.0/"
  },
  close(conn) {
    return new Promise((resolve, reject) => {
      if (conn) {
        conn.close()
        conn.addEventListener("close", () => {
          resolve()
        })
        conn.addEventListener("error", (err) => {
          reject(err)
        })
        // If there are no response from WS - reject
        setTimeout(() => {
          reject({ message: "Manual timeout on close WS connection" })
        }, 100000)
      } else {
        resolve()
      }
    })
  },
}
