import Vue from "vue"
import Vuex from "vuex"
import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"

Vue.use(Vuex)

const state = {
  supportLink: "https://partner.arhtmedia.com",
  signalServersList: [],
  signalServersListCount: 0,
  pagesList: [],
  pagesListCount: 0,
  defaultPage: {
    title: "",
    content: "",
  },
  activePage: {
    title: "",
    content: "",
  },
  turnServersList: [],
  turnServersListCount: 0,
}

export default {
  state,
  mutations,
  getters,
  actions,
}
