import Vue from "vue"
import Vuex from "vuex"
import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"

Vue.use(Vuex)

const state = {
  userRole: {},
  allPermissions: {},
  flatPermissions: [],
  rolesList: [],
  rolesCount: 0,
}

export default {
  state,
  mutations,
  getters,
  actions,
}
