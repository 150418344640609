import Vue from "vue"
import Vuex from "vuex"
import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"

Vue.use(Vuex)

const state = {
  websocketCommonConnection: null,
  dialog: false,
  loader: false,
  tableLoader: false,
  currentIp: null,
  companySignal: null,
  wsUrl: null,
}

export default {
  state,
  mutations,
  getters,
  actions,
}
