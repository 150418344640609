const state = {
  Sidebar_drawer: null,
  Customizer_drawer: false,
  SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
  SidebarBg: "",
  sidebarMini: false,
  Pallette: {
    actionButtons: {
      delete: "",
      play: "",
      edit: "",
      editBadge: "",
      newItem: "",
      bigPlay: "",
      generate: "",
    },
    statusChips: {
      active: "",
      notActive: "",
    },
    statusText: {
      active: "",
      notActive: "",
    },
  },
}

const mutations = {
  SET_SIDEBAR_DRAWER(state, payload) {
    state.Sidebar_drawer = payload
  },
  SET_CUSTOMIZER_DRAWER(state, payload) {
    state.Customizer_drawer = payload
  },
  SET_SIDEBAR_COLOR(state, payload) {
    state.SidebarColor = payload
  },
  set_InitPallette(state, payload) {
    state.Pallette = payload
  },
  SET_MINI_SIDEBAR(state, payload) {
    state.sidebarMini = payload
  },
}
const actions = {
  setMiniSidebar({ commit }, val) {
    commit("SET_MINI_SIDEBAR", val)
  },
  action_InitPallette(context) {
    context.commit("set_InitPallette", {
      actionButtons: {
        delete: "red",
        play: "green",
        edit: "indigo",
        editBadge: "green",
        newItem: "primary",
        bigPlay: "indigo darken-1",
        generate: "purple darken-1",
        menu: "grey lighten-5",
      },
      statusChips: {
        active: "light-green darken-2",
        notActive: "deep-orange lighten-2",
        pending: "indigo lighten-1",
      },
      statusText: {
        active: "light-green--text",
        notActive: "red--text",
      },
    })
  },
}
const getters = {
  get_Sidebar_drawer(state) {
    return state.Sidebar_drawer
  },
  get_Pallette(state) {
    return state.Pallette
  },
  sidebarMini(state) {
    return state.sidebarMini
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
