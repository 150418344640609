import Vue from "vue"
import Vuex from "vuex"
import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"
import UserService from "@/common/api/user.service"

Vue.use(Vuex)

const state = {
  errors: {},
  user: UserService.getCurrentUser(),
  isAuthenticated: !!UserService.getToken(),
  wsIsAuthenticated: false,
  userPermissions: [],
}

export default {
  state,
  mutations,
  getters,
  actions,
}
