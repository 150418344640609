import * as VueDeepSet from "vue-deepset"
import UserService from "@/common/api/user.service"
import { destroyHashLogin } from "@/common/hashlogin.service"
import ApiService from "@/common/api/api.service"

export default {
  setError(state, error) {
    state.errors = error
  },
  setAuth(state, receive) {
    let receiveUser = {
      _key: receive.user._key,
      email: receive.user.email,
      first_name: receive.user.first_name,
      last_name: receive.user.last_name,
      group_key: receive.user.group_key,
      role_key: receive.user.role_key,
      role_name: receive.user?.role?.name,
      terms_privacy: receive.user.terms_privacy,
    }
    state.isAuthenticated = true
    state.user = receiveUser
    state.errors = {}
    UserService.saveCurrentUser(receiveUser)
    UserService.saveToken(receive.access_token)
    ApiService.setHeader()
  },
  PURGE_AUTH(state) {
    destroyHashLogin() // @todo this functionality must be deleted after user rules implementation
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    UserService.destroyToken()
    UserService.destroyCurrentUser()
  },
  setAuthWs(state, receive) {
    state.wsIsAuthenticated = receive
  },
  ACCEPT_TERMS_PRIVACY(state) {
    VueDeepSet.vueSet(state.user, ["terms_privacy"], true)
  },
  CREATE_SESSION(state, permissions) {
    state.userPermissions = permissions
  },
  DESTROY_SESSION(state) {
    state.userPermissions = []
  },
}
