import { get } from "lodash"

export default {
  getWebsocketCommonConnection: (state) => state.websocketCommonConnection,
  getDialog: (state) => state.dialog,
  getShowLoader: (state) => state.loader,
  getShowTableLoader: (state) => state.tableLoader,
  getCurrentIp: (state) => state.currentIp,
  companySignalKey: (state) =>
    get(state.companySignal, "data.signal_server_key"),
  wsUrl: (state) => state.wsUrl,
}
