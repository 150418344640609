import Vue from "vue"
import _ from "lodash"
import EngineService from "@/common/engine.service"
import * as VueDeepSet from "vue-deepset"

export default {
  saveWebsocketCommonConnection(state, value) {
    state.websocketCommonConnection = value
  },
  saveUnattendedEngines(state, data) {
    Vue.set(state.allEngines, data.index, data.value)
  },
  saveUnattendedPresetEngines(state, value) {
    state.presetEngines = value
  },
  saveUnattendedPresetTreeviewEngines(state, value) {
    Vue.set(state, "presetTreeviewEngines", value)
  },
  saveUnattendedTreeviewSingleEngine(state, data) {
    try {
      //this.$vuexSet(data.paramsData);
      let oldData = _.get(
        state.presetTreeviewEngines,
        data.paramsData.pathString
      )
      let dataUpdate = { ...data.engineData, ...oldData }
      console.log("data.engineData", data.engineData)
      console.log("dataUpdate", oldData)
      console.log("dataUpdate", dataUpdate)

      VueDeepSet.vueSet(
        state.presetTreeviewEngines,
        data.paramsData.pathString,
        dataUpdate
      )

      //this.$vueSet(state.presetTreeviewEngines, data.paramsData.pathString, data.engineData);

      //EngineService.saveUnattedendSingle(state, data);

      // if (data.paramsData.preset) {
      //     console.log('presseeetttsave', state.presetTreeviewEngines[data.paramsData.preset]['children'])
      //     Vue.set(state.presetTreeviewEngines[data.paramsData.preset]['children'], data.paramsData.response.id, data.engineData);
      // } else {
      //     console.log('1111enngggogoggogoogo', state.presetTreeviewEngines);
      //     console.log('enngggogoggogoogo', data.paramsData.response.id);
      //     Vue.set(state.presetTreeviewEngines, data.paramsData.response.id, data.engineData);
      // }
    } catch (err) {
      // console.log('error om SAVE_UNATTENDED_TREEVIEW_SINGLE_ENGINE', err)
    }
  },
  saveUnattendedEnginesIsOnlineStatus(state, data) {
    let engine = state.allEngines[data.enginePk]
    if (!_.isUndefined(engine) && typeof engine === "object") {
      Vue.set(
        state.allEngines[data.enginePk],
        "isUnattendedOnline",
        data.isUnattendedOnline
      )
    }
    let findedTree = EngineService.treeDeepFindById(
      state.presetTreeviewEngines,
      data.enginePk
    )
    if (findedTree) {
      findedTree.isUnattendedOnline = data.isUnattendedOnline
    }
  },
  saveUnattendedEnginesCPUGPUStatus(state, data) {
    if (!_.isUndefined(state.allEngines[data.enginePk])) {
      Vue.set(state.allEngines[data.enginePk], "Status", data.Status)
    }
  },
  saveEngineIsLoginedAspxStatus(state, data) {
    Vue.set(
      state.allEngines[data.enginePk],
      "isLoginedAspx",
      data.isLoginedAspx
    )
  },
  setWebsocketConnectionCompany(state, value) {
    state.conn_websocket_company = value
  },

  CHANGE_BROWSER_WEBRTC_CONNECTION(state, data) {
    state.browserWebrtcConn = data
  },
  CHANGE_ENGINE_WEBRTC_SOURCES(state, data) {
    state.engineWebrtcSources = data
  },
  SET_WEB_ENGINE_PK(state, pk) {
    state.webEngine.pk = pk
  },
  SET_WEB_ENGINE_NAME(state, name) {
    state.webEngine.name = name
  },
  SET_WEB_ENGINE_ACTIVE_VIDEO(state, active_video) {
    state.webEngine.active_video = active_video
  },
  SET_WEB_ENGINE_ACTIVE_AUDIO(state, active_audio) {
    state.webEngine.active_audio = active_audio
  },
  MARK_WEBCAMERA_STARTED(state, status) {
    state.webcameraStarted = status
  },
  SET_ACTIVE_WEBRTC_CONSTRAINT(state, value) {
    state.activeWebrtcConstraints = value
  },
  saveWebsocketConnectionCompany(state, value) {
    state.websocketConnCompany = value
  },
  SAVE_WEBCAMERA_NICKNAME(state, value) {
    state.webEngine.nickName = value
  },
  SAVE_WEBCAMERA_PEER_ID(state, value) {
    state.webEngine.peerId = value
  },
  setSelectedEngine(state, value) {
    state.selectedEngine = value
  },
  setSelectedConnection(state, value) {
    state.selectedConnectionEngine = value
  },
  // saveEnginesInfo (state, data) {
  //     console.log('mutation SAVE_ENGINES_INFO--', data);
  //     Vue.set(state.status, data.engineId, data.value);
  // },
  SHOW_SETUP_STREAM_ITEM(state, value) {
    state.showSetupStreamItem = value
  },
}
