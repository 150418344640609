export default {
  userGroupsCount: 0,
  userGroupsList: [],
  defaultCompanyInfo: {
    name: "",
    signal_server_key: "",
    turn_servers: [],
    admin: {
      phone: "",
      email: "",
    },
    technical: {
      phone: "",
      email: "",
    },
    address: "",
    website: "",
    description: "",
  },
  companyInfo: {
    name: "",
    signal_server_key: "",
    turn_servers: [],
    admin: {
      phone: "",
      email: "",
    },
    technical: {
      phone: "",
      email: "",
    },
    address: "",
    website: "",
    description: "",
  },
}
