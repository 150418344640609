import UserService from "@/common/api/user.service"
import _ from "lodash"

export default {
  checkHashExists(context, hash) {
    return UserService.inviteCheck(hash).then((response) => {
      // console.log(response)
      return response
    })
    // .catch((error) => {
    //   console.log(error)
    //   return error;
    // });
  },
  signUpByhashAction(context, value) {
    return UserService.setUserDataByInvitation(value.slug, value.credentials)
  },
  getInvitesList({ commit, dispatch }, params) {
    if (!params) params = { params: { p: 1, pp: 10 } }
    UserService.invitesList(params)
      .then((response) => {
        dispatch("saveTableLoaderAction", true)
        // console.log(response)
        commit("saveInvitesList", response.data.data.result)
        commit("saveInvitesCount", response.data.data.full_count)
        dispatch("saveTableLoaderAction", false)
      })
      .catch((error) => {
        // console.log('STORE getInvitesList ERROR')
        console.log(error)
        dispatch("saveTableLoaderAction", false)
      })
  },
  createInvitation(context, formData) {
    return UserService.sendInvitation(formData.data)
  },
  deleteInvitation(context, _key) {
    return UserService.inviteDelete(_key)
  },
  resendEmailInvitation(context, _key) {
    return UserService.resendEmailInvitation(_key)
  },
  getSignUpMicrosoftLink(context, payload) {
    return UserService.getSignUpMicrosoftLink(payload)
  },
  signUpMicrosoft(context, payload) {
    return UserService.signUpMicrosoft(payload)
  },
  getLoginMicrosoftLink(context, payload) {
    return UserService.getLoginMicrosoftLink(payload)
  },
  loginMicrosoft(context, payload) {
    return UserService.loginMicrosoft(payload)
  },
}
