<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    :active-class="``"
    :class="{ 'v-list-item--active': isActive }"
    exact
    :data-testid="testid"
  >
    <v-list-item-icon v-if="item.icon">
      <v-icon v-text="item.icon" />
    </v-list-item-icon>

    <v-list-item-content v-if="item.title">
      <!-- <v-list-item-title v-text="item.title" /> -->
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Themeable from "vuetify/lib/mixins/themeable"
import { mapGetters } from "vuex"

export default {
  name: "BaseItem",

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        title: undefined,
        to: undefined,
        // permission: undefined
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
    testid: {
      type: String,
      default: "undefined",
    },
  },

  computed: {
    ...mapGetters({
      supportLink: "supportLink",
    }),
    href() {
      if (this.item.href) {
        if (this.item.href === "store_support_link") {
          return this.supportLink
        }
      }
      return this.item.href || (!this.item.to ? "#" : undefined)
    },
    isActive() {
      return (
        this.item.paths &&
        this.item.paths.some((val) => val === this.$route.name)
      )
    },
  },
}
</script>
