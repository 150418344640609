import { SpeedTestsService } from "@/common/api/api.service"
import _ from "lodash"
import * as VueDeepSet from "vue-deepset"

export default {
  saveInitializeSpeedTestAction({ commit, state }, data) {
    return new Promise((resolve) => {
      commit(
        "saveTestInProgress",
        _.has(data, "testInProgress")
          ? data.testInProgress
          : state.testInProgress
      )
      commit(
        "saveIsTestInitiator",
        _.has(data, "isTestInitiator")
          ? data.isTestInitiator
          : state.isTestInitiator
      )
      resolve()
    })
  },
  speedTestGetListAction({ commit }, getParams) {
    // let getParams = {
    //   params: {
    //     "keep": ["_key", "name", "finished"]
    //   }
    // };
    return SpeedTestsService.get(getParams)
  },
  speedTestGetSingleAction({ commit }, getParams) {
    return new Promise((resolve, reject) => {
      SpeedTestsService.get(getParams).then(function (response) {
        let data = response.data.data.test_room || {}
        commit("speedTestGetSingle", data)
        resolve(response.data.data)
      })
    })
  },
  speedTestSaveSingleByHash({ commit }, data) {
    return new Promise((resolve) => {
      commit("speedTestGetSingle", data)
      resolve()
    })
  },
  speedTestCreateAction({ state }, formData) {
    return SpeedTestsService.create(formData.data)
  },
  speedTestEditAction({ state }, formData) {
    return SpeedTestsService.update(formData.slug, formData.data)
  },
  speedTestDeleteAction({ state }, item) {
    return SpeedTestsService.destroy(item._key)
  },
  speedTestResetData({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit("SPEED_TEST_RESET_DATA", state)
      resolve()
    })
  },
  speedTestErrors({ commit, state }, val) {
    return new Promise((resolve) => {
      commit("SPEED_TEST_ERRORS", val)
      resolve()
    })
  },
  speedTestSaveSignalUrlAction({ commit }, value) {
    commit("speedTestSaveSignalUrl", value)
  },
  speedTestSaveTurnsUrlsAction({ commit }, value) {
    commit("speedTestSaveTurnsUrls", value)
  },
  speedTestSaveWorkerTasksAction({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit("speedTestSaveWorkerTasks", value)
      resolve()
    })
  },
  speedTestStopAllTasksAction({ commit, state }, value) {
    return new Promise((resolve, reject) => {
      _.forEach(state.workerTasks, (val, key) => {
        VueDeepSet.vueSet(state.workerTasks, [key, "status"], "stopped")
      })
      resolve()
    })
  },
  speedTestSaveWebrtcPeersAction({ commit }, data) {
    commit("speedTestSaveWebrtcPeers", data)
  },
  speedTestResetStatsAction({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit("speedTestResetStats", value)
      resolve()
    })
  },
  speedTestSaveIsFinishedAction({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit("speedTestSaveIsFinished", value)
      resolve()
    })
  },
  speedTestSaveSourceAction({ commit }, value) {
    commit("speedTestSaveSource", value)
  },
  speedTestSaveDestinationAction({ commit }, value) {
    commit("speedTestSaveDestination", value)
  },
  speedTestSaveCalculatedStatisticsAction({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit("speedTestSaveCalculatedStatistics", value)
      resolve()
    })
  },
  speedTestSaveRawStatisticsAction({ commit }, value) {
    commit("speedTestSaveRawStatistics", value)
  },
  speedTestSaveStatisticsAction({ commit }, value) {
    // not used anywhere, maybe to delete
    commit("speedTestSaveStatistics", value)
  },
  speedTestSaveClientIdAction({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit("speedTestSaveClientId", value)
      resolve(value)
    })
  },
  speedTestSaveRoomCliensAction({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit("speedTestSaveRoomCliens", value)
      resolve(value)
    })
  },
  speedTestSavePartnerInfoAction({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit("speedTestSavePartnerInfo", value)
      resolve(value)
    })
  },
  speedTestPartnerDisconnectedAction({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit("speedTestPartnerDisconnected", value)
      resolve(value)
    })
  },
  speedTestShowCompletedDialogAction({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit("speedTestShowCompletedDialog", value)
      resolve(value)
    })
  },
  speedTestGetPdf({ commit, state }, getParams) {
    return new Promise((resolve, reject) => {
      SpeedTestsService.getPdf(getParams)
        .then((resp) => {
          let blob = new Blob([resp.data], { type: "application/pdf" }),
            link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = state.activeSpeedTest.name + ".pdf"
          link.click()
          resolve()
        })
        .catch((err) => {
          console.log(err)
          reject()
        })
    })
  },
  speedTestGenerateLinkAction({ commit }, params) {
    return SpeedTestsService.generateLink(params)
  },
  speedTestHashInfoAction({ commit, dispatch }, hash) {
    return SpeedTestsService.getHashInfo(hash)
  },
  speedTestLoaderPdfAction({ commit }, val) {
    commit("speedTestLoaderPdf", val)
  },
  speedTestPostTestResultAction({ commit }, postData) {
    return SpeedTestsService.postSpeedTestResult(postData.slug, postData.data)
  },
  speedTestGetTestResultsAction({ commit }, slug) {
    return SpeedTestsService.getListSpeedTestResult(slug)
  },
  speedTestDeleteSpeedTestResultAction({ commit }, params) {
    return SpeedTestsService.deleteSpeedTestResult(params.slug, params.id)
  },
  speedTestSaveDuration({ commit }, val) {
    commit("SPEED_TEST_SAVE_DURATION", val)
  },
  speedTestSaveBandwidth({ commit }, val) {
    commit("SPEED_TEST_SAVE_BANDWIDTH", val)
  },
  speedTestSavePeerActiveCandidate({ commit }, val) {
    commit("SPEED_TEST_PEER_ACTIVE_CANDIDATE", val)
  },
  isSavingSpeedTest({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit("IS_SAVING_SPEED_TEST", value)
      resolve()
    })
  },
}
