<template>
  <svg
    :width="size"
    :height="size"
    :viewBox="`0 0 ${size} ${size}`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9375 6.99609C10.4898 6.99609 10.9375 6.54838 10.9375 5.99609C10.9375 5.44381 10.4898 4.99609 9.9375 4.99609C9.38522 4.99609 8.9375 5.44381 8.9375 5.99609C8.9375 6.54838 9.38522 6.99609 9.9375 6.99609Z"
      :fill="fill"
    />
    <path
      d="M1.9375 6.99609C2.48978 6.99609 2.9375 6.54838 2.9375 5.99609C2.9375 5.44381 2.48978 4.99609 1.9375 4.99609C1.38522 4.99609 0.9375 5.44381 0.9375 5.99609C0.9375 6.54838 1.38522 6.99609 1.9375 6.99609Z"
      :fill="fill"
    />
    <path
      d="M5.9375 10.9961C6.48978 10.9961 6.9375 10.5484 6.9375 9.99609C6.9375 9.44381 6.48978 8.99609 5.9375 8.99609C5.38522 8.99609 4.9375 9.44381 4.9375 9.99609C4.9375 10.5484 5.38522 10.9961 5.9375 10.9961Z"
      :fill="fill"
    />
    <path
      d="M9.11235 2.10969L6.91016 4.30566L7.61626 5.01376L9.81845 2.81779L9.11235 2.10969Z"
      :fill="fill"
    />
    <path
      d="M4.09703 4.83608L4.25203 4.99608L4.96203 4.29108L2.95203 2.27108H2.94703L2.79203 2.11108L2.08203 2.81608L4.09203 4.84108L4.09703 4.83608Z"
      :fill="fill"
    />
    <path
      d="M7.6425 6.96606L6.9375 7.67106L8.9325 9.67606L9.1075 9.85106L9.8175 9.14606L7.8225 7.14106L7.6425 6.96606Z"
      :fill="fill"
    />
    <path
      d="M4.23359 6.97107L2.21859 8.97607L2.05859 9.14107L2.76359 9.84607L4.77859 7.83607L4.94359 7.67607L4.23359 6.97107Z"
      :fill="fill"
    />
    <path
      d="M5.9375 2.99609C6.48978 2.99609 6.9375 2.54838 6.9375 1.99609C6.9375 1.44381 6.48978 0.996094 5.9375 0.996094C5.38522 0.996094 4.9375 1.44381 4.9375 1.99609C4.9375 2.54838 5.38522 2.99609 5.9375 2.99609Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: "#ffffff",
    },
    size: {
      type: Number,
      default: 11,
    },
  },
}
</script>
