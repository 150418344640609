export default {
  saveDialogStatus(state, value) {
    state.dialog = value
  },
  saveLoader(state, value) {
    state.loader = value
  },
  saveWsConn(state, value) {
    state.websocketCommonConnection = value
  },
  saveTableLoader(state, value) {
    state.tableLoader = value
  },
  saveCurrentIp(state, value) {
    state.currentIp = value
  },
  SAVE_COMPANY_SIGNAL(state, value) {
    state.companySignal = value
  },
  SET_WS_URL(state, value) {
    state.wsUrl = value
  },
}
