import _ from "lodash"
import {
  removeWebrtcSourcesDublicates,
  getCompleteUrl,
} from "@/common/webrtc/helpers"
import GeneralService from "@/common/general.service"
import {
  WEBRTC_SOURCES_NO_VIDEO_INPUT,
  WEBRTC_SOURCES_NO_AUDIO_INPUT,
  WEBRTC_SOURCES_SCREENCAPTURE_VIDEO_INPUT,
  WEBRTC_SOURCES_RESCAN_DEVICES,
} from "./constants"

export default {
  getAllWebrtcConstraints: (state) => state.webrtcConstraints,
  getWebrtcVideoSources: (state) => state.listWebrtcVideoSources,
  getWebrtcAudioSources: (state) => state.listWebrtcAudioSources,
  getAllVideoSources: (state, getters) => {
    let videoSourcesArr = removeWebrtcSourcesDublicates(
      getters.getWebrtcVideoSources
    )
    //if (!getters.isVideoWebrtcSourcesInitialised)
    videoSourcesArr.unshift(WEBRTC_SOURCES_NO_VIDEO_INPUT)
    videoSourcesArr.push(WEBRTC_SOURCES_SCREENCAPTURE_VIDEO_INPUT)
    return videoSourcesArr
  },
  getAllAudioSources: (state, getters) => {
    let audioSourcesArr = removeWebrtcSourcesDublicates(
      getters.getWebrtcAudioSources
    )
    //if (!getters.isAudioWebrtcSourcesInitialised)
    audioSourcesArr.unshift(WEBRTC_SOURCES_NO_AUDIO_INPUT)
    return audioSourcesArr
  },
  getSelectedWebrtcVideoDeviceId: (state) =>
    state.formMediaConstraints.video.deviceId,
  getSelectedWebrtcAudioDeviceId: (state) =>
    state.formMediaConstraints.audio.deviceId,
  getWebrtcPeers: (state) => state.peers,
  getRoomMembersWsConn: (state) => state.roomMembers,
  signalRoomMembersSenders: (state) => state.roomMembers.senders,
  webengineSendersSignal: (state) => state.webengineSendersSignal,
  getFormMediaConstraints: (state) => state.formMediaConstraints,
  getModelWebengine: (state) => state.modelWebengine,
  getMediaConstraints: (state, getters) => {
    const constraints = {}
    console.log(
      "constraints--getters.excludeSenderParams",
      getters.excludeSenderParams
    )
    if (!getters.excludeSenderParams) {
      constraints.audio = {
        autoGainControl: state.activeWebEngine.source.autoGainControl,
        echoCancellation: state.activeWebEngine.source.echoCancellation,
        noiseSuppression: state.activeWebEngine.source.noiseSuppression,
      }
      constraints.video = {}

      let sampleRate = state.activeWebEngine.source.sampleRate
      if (sampleRate) constraints.audio.sampleRate = sampleRate

      let sampleSize = state.activeWebEngine.source.sampleSize
      if (sampleSize) constraints.audio.sampleSize = sampleSize

      let currentScannedConstraints = getters.getCurrentScannedConstraints
      // frame Rate settings
      // @todo find best way to check best frameRate
      let scannedBestFrameRate = _.get(currentScannedConstraints, "frameRate")
      // if (scannedBestFrameRate) {
      //   // set frame rate that was scanned for best one
      //   _.set(constraints.video, 'frameRate', scannedBestFrameRate);
      // } else {
      // if (parseInt(state.activeWebEngine.source.min_framerate) !== 0) {
      //   _.set(constraints.video, 'frameRate.min', state.activeWebEngine.source.min_framerate);
      // }
      // if (parseInt(state.activeWebEngine.source.max_framerate) !== 0) {
      //   _.set(constraints.video, 'frameRate.max', state.activeWebEngine.source.max_framerate);
      // }
      //}

      _.set(constraints.video, "frameRate", { ideal: 30 })

      // resolution settings
      let scannedBestResolution =
        _.get(currentScannedConstraints, "width") &&
        _.get(currentScannedConstraints, "height")
      if (scannedBestResolution) {
        _.set(constraints.video, "width.exact", currentScannedConstraints.width)
        _.set(
          constraints.video,
          "height.exact",
          currentScannedConstraints.height
        )
      } else {
        _.set(
          constraints.video,
          "width.exact",
          state.formMediaConstraints.video.width.exact
        )
        _.set(
          constraints.video,
          "height.exact",
          state.formMediaConstraints.video.height.exact
        )
      }
      // _.set(constraints.video, 'width.ideal', 1280);
      // _.set(constraints.video, 'height.ideal', 720);

      console.log("constraints--22222", constraints)
    } else {
      constraints.video = {}
      constraints.audio = {}
    }

    // Setup deviceId
    let selectedVideoId = getters.getSelectedVideoSourceWebengine()
    if (_.isNull(selectedVideoId)) {
      constraints.video = false
    } else {
      _.set(constraints, "video.deviceId.exact", selectedVideoId)
    }
    let selectedAudioId = getters.getSelectedAudioSourceWebengine()
    if (_.isNull(selectedAudioId)) {
      constraints.audio = false
    } else {
      _.set(constraints, "audio.deviceId.exact", selectedAudioId)
    }

    return constraints
  },
  getIsLocalStreamStarted: (state) => state.isLocalStreamStarted,
  getIsPreviewRemoteStreamStarted: (state) =>
    state.isPreviewRemoteStreamStarted,
  getLocalStreamAudioVolume: (state) =>
    state.modelWebengine.local.audioVolume * 100,
  getLocalStreamAudioGain: (state) => state.modelWebengine.local.audioVolume,
  getLocalStreamIsMuted: (state) =>
    state.modelWebengine.local.audioVolume === 0, //state.modelWebengine.local.muted,
  getRemoteStreamAudioVolume: (state) =>
    state.modelWebengine.remote.audioVolume * 100,
  getRemoteStreamAudioGain: (state) => state.modelWebengine.remote.audioVolume,
  getRemoteStreamIsMuted: (state) =>
    state.modelWebengine.remote.audioVolume === 0,
  getActiveWebEngine: (state) => state.activeWebEngine,
  getGuestLink: (state) => state.guestLink,
  // get user name for webwngine to edit
  getUserNameWebengineForm: (state) =>
    state.activeWebEngine.source.user_name_webengine,
  // get user name for use in name of stream
  getUserNameSender: (state) =>
    state.activeWebEngine.source.user_name_webengine
      ? state.activeWebEngine.source.user_name_webengine
      : state.activeWebEngine.name,
  getSelectedVideoSourceWebengine:
    (state, getters) =>
    (params = {}) => {
      return _.get(state.activeWebEngine.source.video_source, [
        GeneralService.getBrowserName(),
      ])
    },
  getSelectedObjectVideoSourceWebengine: (state, getters) => {
    let selectedVideoId = getters.getSelectedVideoSourceWebengine()
    return selectedVideoId
      ? _.find(getters.getAllVideoSources, { deviceId: selectedVideoId })
      : null
  },
  getSelectedAudioSourceWebengine:
    (state, getters) =>
    (params = {}) => {
      return _.get(state.activeWebEngine.source.audio_source, [
        GeneralService.getBrowserName(),
      ])
    },
  getSelectedAspxStreamTableData: (state) =>
    state.activeWebEngine.remote.stream
      ? _.isArray(state.activeWebEngine.remote.stream)
        ? state.activeWebEngine.remote.stream
        : [state.activeWebEngine.remote.stream]
      : [],
  getActiveSignalServerUrl: (state, getters) => {
    let data = null
    if (state.activeWebEngine.signal_server_url) {
      data = state.activeWebEngine.signal_server_url
    } else {
      let pk = state.activeWebEngine.signal_server_key
      if (pk) {
        let finded = _.find(getters.signalServersList, { _key: pk })
        if (!_.isEmpty(finded)) {
          data = getCompleteUrl(finded.data)
        }
      }
    }
    return data
  },
  getActiveSignalRoom: (state, rootState) => {
    if (state.activeWebEngine.common_room) {
      return "aspx_room"
    }
    return (
      rootState.getCurrentUserCompanyRoom ||
      state.activeWebEngine.signal_server_room
    )
  },
  getActiveLocalTurnData: (state) => {
    let data = null
    let pk = state.activeWebEngine.source.turn_server_key
    if (pk) {
      data = _.find(state.modelWebengine.listLocalTurns, { _key: pk })
    }
    return data
  },
  getActiveRemoteTurnData: (state) => {
    let data = null
    let pk = state.activeWebEngine.remote.turn_server_key
    if (pk) {
      data = _.find(state.modelWebengine.listRemoteTurns, { _key: pk })
    }
    return data
  },
  getFilledSignalsList: (state, getters) => {
    return _.filter(getters.signalServersList, function (o) {
      return !_.isNull(o._key)
    })
  },
  getFilledTurnsList: (state) => {
    return _.filter(state.modelWebengine.listRemoteTurns, function (o) {
      return !_.isNull(o._key)
    })
  },
  existWebrtcPartenerSender: (state) => {
    return state.roomMembers.senders.length > 1
  },
  getActiveSignalServerKey: (state) => state.activeWebEngine.signal_server_key,
  getDefaultSignalServer: (state, rootState, getters) => {
    let signal = null
    let companyInfo = rootState.getCurrentUserCompanyInfo
    let activeSignalServer = _.get(companyInfo, "signal_server_key")
    if (activeSignalServer) {
      let signalData = _.find(rootState.signalServersList, function (o) {
        return o._key === activeSignalServer
      })
      signal = signalData
    } else {
      let firstSignal = _.get(rootState.signalServersList, "[0]")
      if (firstSignal) {
        signal = firstSignal
      }
    }
    return signal
  },
  rawWebrtcSources: (state) => state.rawWebrtcSources,
  rawVideoWebrtcSources: (state) => {
    return state.rawWebrtcSources.filter((s) => s.kind === "videoinput")
  },
  /**
   * Check if WebRTC sources are initialized, if some of sources have label and deviceId
   * - if resolutions for all video devices was scanned
   *
   * @param state
   * @returns Boolean
   */
  isWebrtcSourcesInitialised: (state) => {
    if (_.isNull(state.rawWebrtcSources)) return false
    return state.rawWebrtcSources.some(
      (o) => o.label !== "" && o.deviceId !== ""
    )
  },
  /**
   * Check if WebRTC video sources are initialized, if some of sources have label and deviceId
   *
   * @param state
   * @returns Boolean
   */
  isVideoWebrtcSourcesInitialised: (state) => {
    if (_.isNull(state.rawWebrtcSources)) return false
    return state.rawWebrtcSources.some(
      (o) => o.kind === "videoinput" && o.label !== "" && o.deviceId !== ""
    )
  },
  /**
   * Check if WebRTC video sources are initialized, if some of sources have label and deviceId
   *
   * @param state
   * @returns Boolean
   */
  isAudioWebrtcSourcesInitialised: (state) => {
    if (_.isNull(state.rawWebrtcSources)) return false
    return state.rawWebrtcSources.some(
      (o) => o.kind === "audioinput" && o.label !== "" && o.deviceId !== ""
    )
  },
  /**
   * Check if all video devices was scanned for detect best resolutions
   *
   * @param state
   * @returns Boolean
   */
  isVideoDevicesResolutionsInited: (state, getters) => {
    return getters.videoDevicesResolutionUnscanned.length === 0
  },
  /**
   * Get array of video devices sources that was't scanned for resolution determining
   */
  videoDevicesResolutionUnscanned: (state, getters) => {
    let alVideoDevicesIds = getters.rawVideoWebrtcSources
    if (!getters.isWebrtcSourcesInitialised && alVideoDevicesIds.length === 0) {
      return false
    }
    let resolutionScansDevicesIds = state.scannedConstraintsList
    return _.differenceBy(
      alVideoDevicesIds,
      resolutionScansDevicesIds,
      "deviceId"
    )
  },
  /**
   *
   */
  isAllWebrtcSetupsInitialised: (state, getters) => {
    return (
      getters.isWebrtcSourcesInitialised &&
      getters.isVideoDevicesResolutionsInited
    )
  },
  /**
   * Check if there is set flag to request sources init.
   * We must request sources once, event if there was unsuccesfull request.
   *
   * @param state
   * @returns Boolean
   */
  dialogWebrtcSources: (state) => state.dialogWebrtcSources,
  /**
   * Check if show dialog for start WebRTC sources initialisation
   * @param state
   * @returns Boolean
   */
  showDialogInitSources: (state, getters) => {
    return !getters.isAllWebrtcSetupsInitialised && state.dialogWebrtcSources
  },
  /**
   * Get main resolutions list to check
   * @param state
   * @returns {default.listWebcameraResolutions}
   */
  listWebcameraResolutions: (state) => state.listWebcameraResolutions,
  /**
   * Get string label that will be show below dropdown with selected resolution in form
   *
   * @param state
   * @param getters
   * @returns {string}
   */
  getResolutionString: (state, getters) => {
    let activeWebEngine = getters.getActiveWebEngine
    let width = _.get(activeWebEngine.source, "resolution.width")
    let height = _.get(activeWebEngine.source, "resolution.height")
    return width && height ? `Current resolution ${width}x${height}` : ""
  },
  /**
   * Get selected resolution in dropdown (if we find from list in dropdown)
   *
   * @param state
   * @param getters
   * @returns {function(*): *}
   */
  getSelectedResolutionWebengine: (state, getters) => {
    let selected = null
    let currentScannedConstraints = getters.getCurrentScannedConstraints
    let width = _.get(currentScannedConstraints, "width")
    let height = _.get(currentScannedConstraints, "height")
    if (width && height) {
      let finded = _.find(state.listWebcameraResolutions, {
        width: width,
        height: height,
      })
      if (finded) {
        selected = finded
      } else {
        // @todo maybe later make dinamic width & height for resolution not only select in dropdown
        // alert('There are not standart webcamera resolution for meke it selected in dropdown')
      }
    }
    return selected
  },
  getCurrentScannedConstraints: (state, getters) => {
    let oVideoSource = getters.getSelectedObjectVideoSourceWebengine
    return oVideoSource
      ? _.find(state.scannedConstraintsList, {
          deviceId: `${oVideoSource.deviceId}`,
        })
      : null
  },

  // getters remote stream
  getRemoteStreamStore: (state) => state.remoteStream,
  getRemoteAudioChannels: (state) => state.remoteAudioChannels,
  getAudioChannelsInfoRemote: (state) => {
    let audioTracks = state.remoteStream.getAudioTracks()
    let audioInfo = { channelsCount: 0 }

    if (audioTracks.length > 0) {
      // audio channels handling
      let audioCtx = new AudioContext()
      let source = audioCtx.createMediaStreamSource(state.remoteStream)
      audioInfo.channelsCount = source.channelCount
    }
    return audioInfo
  },
  getSenderVideoCodec: (state) => state.activeWebEngine.source.video_codec,
  getWebengineBandwidth: (state) => state.activeWebEngine.remote.bandwidth,
  scannedConstraintsList: (state) => state.scannedConstraintsList,
  unchangedScannedConstraintsList: (state) =>
    state.unchangedScannedConstraintsList,
  getWebengineCurrentReceived: (state) => state.currentReceived,
  getWebengineCurrentSended: (state) => state.currentSended,
  getWebengineCurrentLost: (state) => state.currentLost,
  peerEncodersParams: (state) => {
    return {
      maxFramerate: {
        val: parseInt(state.activeWebEngine.source.maxFramerate),
        kinds: ["video"],
      },
      ptime: {
        val: state.activeWebEngine.source.ptime
          ? parseInt(state.activeWebEngine.source.ptime)
          : "",
        kinds: ["audio"],
      },
    }
  },
  errorsLocalStream: (state) => state.errorsLocalStream,
  errorsRemoteStream: (state) => state.errorsRemoteStream,
  doStreamRestart: (state) => state.doStreamRestart,
  excludeSenderParams: (state) => state.excludeSenderParams,
  useJanus: (state) => state.useJanus,
}
