const { detect } = require("detect-browser")

export const getBrowserName = () => {
  const browser = detect()
  return browser.name
}

export const formatBytes = (bytes, decimals) => {
  if (bytes == 0) return "0 Bytes"
  var k = 1024,
    dm = decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export const BytesToMBits = (bytes, decimals) => {
  var dm = decimals || 3
  return parseFloat((bytes / 125000).toFixed(dm))
}

export const emptyHtmlElm = (elm) => {
  if (elm) {
    while (elm.firstChild) {
      elm.removeChild(elm.firstChild)
    }
  }
}

export const pad = (number, size) => {
  var s = String(number)
  while (s.length < (size || 2)) {
    s = "0" + s
  }
  return s
}

export const next = function (elem, selector) {
  // Get the next element
  var nextElem = elem.nextElementSibling
  console.log("")

  // If there's no selector, return the next element
  if (!selector) {
    return nextElem
  }

  // Otherwise, check if the element matches the selector
  if (nextElem && nextElem.matches(selector)) {
    return nextElem
  }

  // if it's not a match, return null
  return null
}

export const prev = function (elem, selector) {
  // Get the prev element
  var prevElem = elem.previousElementSibling

  // If there's no selector, return the next element
  if (!selector) {
    return prevElem
  }

  // Otherwise, check if the element matches the selector
  if (prevElem && prevElem.matches(selector)) {
    return prevElem
  }

  // if it's not a match, return null
  return null
}

export const slugify = function (string) {
  return (
    string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      /* eslint-disable-next-line */
      .replace(/[^\w\-]+/g, "")
      /* eslint-disable-next-line */
      .replace(/\-\-+/g, "-")
      /* eslint-disable-next-line */
      .replace(/^-+/, "")
      .replace(/-+$/, "")
  )
}

/**
 * Function
 * @param objValue
 * @param srcValue
 * @returns {*}
 */
export const fnCustomizerStore = function (objValue, srcValue) {
  if (Array.isArray(srcValue)) {
    return srcValue
  }
  if (srcValue instanceof Object && Object.keys(srcValue).length === 0) {
    return srcValue
  }
}

export default {
  getBrowserName,
  formatBytes,
  BytesToMBits,
  emptyHtmlElm,
  pad,
  next,
  prev,
  slugify,
  fnCustomizerStore,
}
