import _ from "lodash"
export default {
  SET_USER_GROUPS_LIST(state, value) {
    state.userGroupsList = value
  },
  SET_USER_GROUPS_COUNT(state, count) {
    state.userGroupsCount = count
  },
  CURRENT_USER_COMPANY_INFO(state, value) {
    let defaultCompany = {
      name: "",
      signal_server_key: "",
      admin: {
        phone: "",
        email: "",
      },
      technical: {
        phone: "",
        email: "",
      },
      address: "",
      website: "",
      description: "",
    }
    state.companyInfo = { ...defaultCompany, ...value }
  },
  SAVE_SELECTED_COMPANY_IN_LIST(state, items) {
    if (items.length > 0) {
      items.forEach((item) => {
        if (!_.find(state.userGroupsList, { _key: item._key })) {
          state.userGroupsList.push(item)
        }
      })
    }
  },
  RESET_COMPANY_INFO(state) {
    state.companyInfo = state.defaultCompanyInfo
  },
}
