import _ from "lodash"

export default {
  getRcEngineData: (state) => state.rcEngineData,
  getActivePlaylist: (state) => {
    return _.get(state.rcEngineData, ["playlists", state.activePlaylistKey])
  },
  getRogersConcertPlaylist: (state) => state.rogersConcertPlaylist,
  rogersFileIsPlayngStarted: (state) => (key) => {
    return _.get(state.rogersConcertPlaylist, `[${key}].played`)
  },
  getRogersActiveFile: (state) => {
    return _.find(state.rogersConcertPlaylist, { played: true })
  },
  getRogersActiveFileKey: (state) => {
    return _.findKey(state.rogersConcertPlaylist, { played: true })
  },
}
