import { Ability } from "@casl/ability"

export default {
  currentUser(state) {
    return typeof state.user === "object" ? state.user : JSON.parse(state.user)
  },
  isAuthenticated: (state) => state.isAuthenticated,
  isAuthenticatedWs: (state) => state.wsIsAuthenticated,
  ability: () => new Ability(),
  isSuperUser: (state) =>
    state.userPermissions.find((p) => p.subject === "Superadmin"),
}
