import Vue from "vue"
import Vuex from "vuex"
import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"

Vue.use(Vuex)

const state = {
  streamsConfigs: {
    commonRoom: false,
    activeSignalKey: null,
    activeCompany: null,
  },
  defaultActiveStream: {
    signal_server_key: null,
  },
  activeStream: {
    signal_server_key: null,
  },
  streamsActive: [],
  streamsActiveCount: 0,
  streamsInactive: [],
  streamsInactiveCount: 0,
  streamActiveItem: {},
  streamsPeerReceiver: null,
  dialogPreviewStream: false,
  dialogPreviewStreamHistory: false,
}

export default {
  state,
  mutations,
  getters,
  actions,
}
