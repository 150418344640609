import _ from "lodash"

export default {
  streamsInitActiveCompany({ commit, dispatch, getters, state }) {
    return new Promise((resolve, reject) => {
      let company = state.streamsConfigs.activeCompany
        ? state.streamsConfigs.activeCompany
        : getters.getUserGroupsList[0]
      dispatch("streamsSaveActiveCompany", company)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          resolve()
          console.log("streamsInitActiveCompany error", error)
        })
    })
  },
  streamsSaveActiveCompany(
    { commit, state, rootState, getters, dispatch },
    val
  ) {
    return new Promise((resolve) => {
      dispatch("companyProfileGetData", val._key)
        .then(function (response) {
          commit("STREAMS_SAVE_ACTIVE_COMPANY", response.data.data.group)
          resolve()
        })
        .catch((error) => {
          resolve()
          console.log("streamsSaveActiveCompany error", error)
        })
    })
  },
  streamsInitActiveSignal({ commit, state, rootState, getters }) {
    return new Promise((resolve) => {
      let signalKey = state.streamsConfigs.activeSignalKey
      if (!signalKey) {
        let companyDefaultSignalKey = _.get(
          getters.streamsDefaultSignalServer,
          "_key"
        )
        if (
          _.find(getters.signalServersList, { _key: companyDefaultSignalKey })
        ) {
          signalKey = companyDefaultSignalKey
        } else {
          signalKey = _.get(rootState.signalServersList, "[0]._key")
        }
      }
      commit("STREAMS_INIT_ACTIVE_SIGNAL", signalKey)
      resolve()
    })
  },
  streamsSaveActiveSignal({ commit, state, rootState, getters }, val) {
    return new Promise((resolve) => {
      commit("STREAMS_SAVE_ACTIVE_SIGNAL", val)
      resolve()
    })
  },
  streamsSaveCommonRoom({ commit, state, rootState, getters }, val) {
    return new Promise((resolve) => {
      commit("STREAMS_SAVE_COMMON_ROOM", val)
      resolve()
    })
  },
  streamsSaveActiveList({ commit, state, rootState, getters }, val) {
    return new Promise((resolve) => {
      commit("STREAMS_SAVE_ACTIVE_LIST", val)
      resolve()
    })
  },
  streamsSaveInactiveList({ commit, state, rootState, getters }, val) {
    return new Promise((resolve) => {
      commit("STREAMS_SAVE_INACTIVE_LIST", val)
      resolve()
    })
  },
  resetStreamsActiveList({ commit }) {
    return new Promise((resolve) => {
      commit("RESET_STREAMS_ACTIVE_LIST")
      resolve()
    })
  },
  resetStreamsInactiveList({ commit }) {
    return new Promise((resolve) => {
      commit("RESET_STREAMS_INACTIVE_LIST")
      resolve()
    })
  },
  setStreamsPeerReceiver({ commit }, val) {
    return new Promise((resolve) => {
      commit("SET_STREAMS_PEER_RECEIVER", val)
      resolve()
    })
  },
  saveDialogPreviewStream({ commit }, val) {
    return new Promise((resolve) => {
      commit("SAVE_DIALOG_PREVIEW_STREAM", val)
      resolve()
    })
  },
  saveDialogPreviewStreamHistory({ commit }, val) {
    return new Promise((resolve) => {
      commit("SAVE_DIALOG_PREVIEW_STREAM_HISTORY", val)
      resolve()
    })
  },
  setStreamActiveItem({ commit }, val) {
    return new Promise((resolve) => {
      commit("SET_STREAM_ACTIVE_ITEM", val)
      resolve()
    })
  },
}
