export const WEBRTC_SOURCES_RESCAN_DEVICES = {
  deviceId: "scan-webrtc-devices",
  text: "Re-Scan devices",
}
export const WEBRTC_SOURCES_NO_VIDEO_INPUT = {
  deviceId: null,
  text: "No video",
}
export const WEBRTC_SOURCES_NO_AUDIO_INPUT = {
  deviceId: null,
  text: "No audio",
}
export const WEBRTC_SOURCES_SCREENCAPTURE_VIDEO_INPUT = {
  deviceId: "screencapture",
  text: "Screencapture",
}

export default {
  WEBRTC_SOURCES_RESCAN_DEVICES,
  WEBRTC_SOURCES_NO_VIDEO_INPUT,
  WEBRTC_SOURCES_NO_AUDIO_INPUT,
  WEBRTC_SOURCES_SCREENCAPTURE_VIDEO_INPUT,
}
