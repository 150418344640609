const module = {
  route: null,
  store: null,
  cb: function (error) {
    if (
      this.router.app._route.name === "sign_up_from_hash" ||
      this.router.app._route.name === "change_password"
    ) {
      return Promise.resolve(error)
    }

    if (error?.response?.status === 401) {
      this.store.dispatch("logout")
      this.router.push("/login")
    }
    // else if (error?.response?.status === 404) {
    //   router.push({name: 'root_not_found_component'});
    // }
    return Promise.reject(error)
  },
}

export default module
