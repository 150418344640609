import _ from "lodash"
export default {
  setUserRole(state, receive) {
    state.userRole = receive
  },
  setFlatPermissions(state, receive) {
    state.flatPermissions = receive
  },
  setAllPermissions(state, receive) {
    state.allPermissions = receive
  },
  saveRolesList(state, receive) {
    state.rolesList = receive
    // .then(function (response) {
    //   let data = response.data.data;
    //   let allRoles = data.roles;
    //   let activeRoles = allRoles.filter(v => v.is_active);
    //   app.roles_list = activeRoles;
    // }).catch(({response}) => {
    //   console.log('error on getRolesList', response);
    // });
    // state.rolesList = receive.filter(v => v.is_active)
  },
  saveRolesCount(state, receive) {
    state.rolesCount = receive
  },
}
