import _ from "lodash"
import * as VueDeepSet from "vue-deepset"

export default {
  SAVE_RAW_WEBRTC_SOURCES(state, data) {
    let devicesInfoArr = []
    data.forEach((deviceInfo) => {
      devicesInfoArr.push({
        deviceId: deviceInfo.deviceId,
        groupId: deviceInfo.groupId,
        kind: deviceInfo.kind,
        label: deviceInfo.label,
      })
    })
    VueDeepSet.vueSet(state, "rawWebrtcSources", devicesInfoArr)
  },
  RESET_ALL_WEBRTC_SOURCES(state) {
    state.rawWebrtcSources = []
    state.listWebrtcVideoSources = []
    state.listWebrtcAudioSources = []
    state.parsedListWebrtcVideoSources = []
    state.parsedListWebrtcAudioSources = []
  },
  SET_DIALOG_WEBRTC_SOURCES_STATUS(state, status) {
    state.dialogWebrtcSources = status
  },
  SAVE_LIST_WEBRTC_VIDEO_SOURCES(state, data) {
    state.listWebrtcVideoSources = data
    let filteredVideos = _.filter(data, function (v) {
      return v.label != "" && !_.isNull(v.deviceId)
    })
    if (filteredVideos.length > 0) {
      state.parsedListWebrtcVideoSources = filteredVideos
    }
  },
  SAVE_LIST_WEBRTC_AUDIO_SOURCES(state, data) {
    state.listWebrtcAudioSources = data
    let filteredAudios = _.filter(data, function (v) {
      return v.label != "" && !_.isNull(v.deviceId)
    })
    if (filteredAudios.length > 0) {
      state.parsedListWebrtcAudioSources = filteredAudios
    }
  },
  SAVE_FORM_SELECTED_WEBRTC_VIDEO_SOURCE(state, value) {
    let mergedData = { ...state.activeWebEngine.source.video_source, ...value }
    VueDeepSet.vueSet(
      state.activeWebEngine,
      ["source", "video_source"],
      mergedData
    )
  },
  SAVE_FORM_SELECTED_WEBRTC_AUDIO_SOURCE(state, value) {
    let mergedData = { ...state.activeWebEngine.source.audio_source, ...value }
    VueDeepSet.vueSet(
      state.activeWebEngine,
      ["source", "audio_source"],
      mergedData
    )
  },
  SAVE_LOCAL_STREAM_TYPE_SELECTED(state, value) {
    VueDeepSet.vueSet(state.modelWebengine, ["localStreamTypeSelected"], value)
  },
  SAVE_LIST_MEMBERS_WS_CONN(state, value) {
    state.roomMembers = value
  },
  RESET_LIST_MEMBERS_WS_CONN(state, value) {
    state.roomMembers = { all: [], senders: [], receivers: [] }
  },
  SAVE_WEBENGINE_SENDERS_SIGNAL(state, list) {
    state.webengineSendersSignal = list
  },
  SAVE_LIST_LOCAL_TURNS(state, value) {
    VueDeepSet.vueSet(state.modelWebengine, ["listLocalTurns"], value)
  },
  SAVE_LIST_REMOTE_TURNS(state, value) {
    VueDeepSet.vueSet(state.modelWebengine, ["listRemoteTurns"], value)
  },
  SAVE_TURN_LOCAL(state, value) {
    VueDeepSet.vueSet(
      state.activeWebEngine,
      ["source", "turn_server_key"],
      value
    )
  },
  SAVE_TURN_REMOTE(state, value) {
    VueDeepSet.vueSet(
      state.activeWebEngine,
      ["remote", "turn_server_key"],
      value
    )
  },
  SAVE_COMMON_ROOM_SIGNAL(state, value) {
    VueDeepSet.vueSet(state.modelWebengine, ["commonRoomSignal"], value)
  },
  SAVE_INPUT_STREAM(state, value) {
    VueDeepSet.vueSet(state.activeWebEngine, ["remote", "stream"], value)
  },
  SAVE_IS_LOCAL_STREAM_STARTED(state, value) {
    state.isLocalStreamStarted = value
  },
  SAVE_IS_PREVIEW_REMOTE_STREAM_STARTED(state, value) {
    state.isPreviewRemoteStreamStarted = value
  },
  SAVE_VOLUME_AUDIO_LOCAL_STREAM(state, value) {
    VueDeepSet.vueSet(state.modelWebengine, ["local", "audioVolume"], value)
  },
  SAVE_VOLUME_AUDIO_REMOTE_STREAM(state, value) {
    VueDeepSet.vueSet(state.modelWebengine, ["remote", "audioVolume"], value)
  },
  WEBENGINES_GET_SINGLE(state, value) {
    state.activeWebEngine = { ...state.activeWebEngine, ...value }
  },
  WEBENGINES_RESET_DATA(state) {
    let resetedDataActiveWebengine = _.cloneDeep(state.defaultDataWebengine)
    _.forEach(resetedDataActiveWebengine, (value, key) => {
      VueDeepSet.vueSet(state.activeWebEngine, [key], value)
    })
    VueDeepSet.vueSet(state.activeWebEngine, ["remote", "stream"], null)
    state.isPreviewRemoteStreamStarted = false
    state.isLocalStreamStarted = false
    state.modelWebengine.remote.muted = false
    state.guestLink = ""
  },
  WEBENGINES_SAVE_SELECTED_SIGNAL(state, value) {
    VueDeepSet.vueSet(state.activeWebEngine, ["signal_server_key"], value)
  },
  WEBENGINES_SET_ACTIVE_SIGNAL(state, value) {
    VueDeepSet.vueSet(state.activeWebEngine, ["signal_server_key"], value)
  },
  WEBENGINES_SET_USER_NAME(state, value) {
    VueDeepSet.vueSet(
      state.activeWebEngine,
      ["source", "user_name_webengine"],
      value
    )
  },
  // remote streams mutations
  SAVE_REMOTE_STREAM(state, stream) {
    console.log("SAVE_REMOTE_STREAM")
    if (stream) {
      if (!state.remoteStream) {
        state.remoteStream = new MediaStream()
      }

      let aTracks = stream.getAudioTracks()
      let finalAudioStream = new MediaStream()
      let aMediaStream = new MediaStream()
      aTracks.forEach((track) => aMediaStream.addTrack(track))
      let vTracks = stream.getVideoTracks()

      if (aTracks.length > 0) {
        // fix when no sound
        let a = new Audio()
        a.muted = true
        a.srcObject = aMediaStream
        a.addEventListener("canplaythrough", () => {
          a = null
        })

        // audio channels handling
        var AudioContext =
          window.AudioContext || // Default
          window.webkitAudioContext || // Safari and old versions of Chrome
          false
        let audioCtx = new AudioContext()
        let source = audioCtx.createMediaStreamSource(stream)
        let channelCount = source.channelCount
        let splitter = audioCtx.createChannelSplitter(channelCount)
        source.connect(splitter)
        let merger = audioCtx.createChannelMerger(channelCount)

        let counterKey = state.remoteAudioChannels.length
        for (let i = 0; i < channelCount; i++) {
          let gainNode = audioCtx.createGain()
          gainNode.gain.setValueAtTime(1, audioCtx.currentTime)
          splitter.connect(gainNode, i)
          gainNode.connect(merger, 0, i)
          state.remoteAudioChannels.push({
            key: counterKey + i,
            muted: false,
            gainNode: gainNode,
          })
        }
        let dest = audioCtx.createMediaStreamDestination()
        merger.connect(dest)
        finalAudioStream = dest.stream

        state.rAudioCtx = audioCtx
        state.rSource = source
        state.rSplitter = splitter
        state.rMerger = merger
        state.rDst = dest
      }

      finalAudioStream
        .getTracks()
        .forEach((track) => state.remoteStream.addTrack(track))
      vTracks.forEach((track) => state.remoteStream.addTrack(track))
    }
  },
  REMOVE_REMOTE_STREAM(state) {
    state.remoteStream = null
    state.remoteAudioChannels = []
    state.rAudioCtx = null
    state.rGainNode = null
    state.rSource = null
    state.rDst = null
    state.rSplitter = null
    state.rMerger = null
  },
  MUTE_REMOTE_CHANNEL(state, channel) {
    let ch = _.find(state.remoteAudioChannels, { key: channel.key })
    if (ch) {
      if (channel.muted) {
        ch.gainNode.gain.setValueAtTime(0, state.rAudioCtx.currentTime)
      } else {
        ch.gainNode.gain.setValueAtTime(1, state.rAudioCtx.currentTime)
      }
    }
  },
  SAVE_VIDEO_RESOLUTION(state, resolution) {
    VueDeepSet.vueSet(
      state.formMediaConstraints,
      "video.width.exact",
      resolution.width
    )
    VueDeepSet.vueSet(
      state.formMediaConstraints,
      "video.height.exact",
      resolution.height
    )
  },
  SAVE_VIDEO_RESOLUTION_SCANNED(state, data) {
    // @todo maybe later will change this to other logic
    VueDeepSet.vueSet(
      state.scannedConstraintsList[data.indx],
      "width",
      data.resolution.width
    )
    VueDeepSet.vueSet(
      state.scannedConstraintsList[data.indx],
      "height",
      data.resolution.height
    )
  },
  SAVE_VIDEO_FRAME_RATE(state, frameRate) {
    VueDeepSet.vueSet(state.mediaConstraints, ["video", "frameRate"], frameRate)
  },
  SAVE_SCANNED_CONSTRAINTS(state, constraints) {
    if (
      !_.find(state.scannedConstraintsList, {
        deviceId: `${constraints.deviceId}`,
      })
    ) {
      state.scannedConstraintsList.push(constraints)
    }
  },
  SAVE_UNCHANGED_SCANNED_CONSTRAINTS(state, constraints) {
    let findedIndex = _.findIndex(state.unchangedScannedConstraintsList, {
      deviceId: `${constraints.deviceId}`,
    })
    if (findedIndex === -1) {
      state.unchangedScannedConstraintsList.push(constraints)
    } else {
      state.unchangedScannedConstraintsList[findedIndex] = constraints
    }
  },
  RESET_SCANNED_CONSTRAINTS(state) {
    state.scannedConstraintsList = []
  },
  SAVE_VIDEO_CODEC(state, codec) {
    VueDeepSet.vueSet(state.activeWebEngine, ["source", "video_codec"], codec)
  },
  INIT_SELECTED_SIGNAL_KEY(state, val) {
    state.activeWebEngine.signal_server_key = val
  },
  SAVE_WEBENGINE_CURRENT_RECEIVED(state, val) {
    state.currentReceived = val
  },
  SAVE_WEBENGINE_CURRENT_SENDED(state, val) {
    state.currentSended = val
  },
  SAVE_WEBENGINE_CURRENT_LOST(state, val) {
    state.currentLost = val
  },
  SET_GUEST_LINK(state, val) {
    state.guestLink = val ? `${window.location.origin}/webengine/${val}` : ""
  },
  SAVE_LOCAL_STREAM_ERRORS(state, data) {
    for (let param in data) {
      VueDeepSet.vueSet(state.errorsLocalStream, [param], data[param])
    }
  },
  SAVE_REMOTE_STREAM_ERRORS(state, data) {
    for (let param in data) {
      VueDeepSet.vueSet(state.errorsRemoteStream, [param], data[param])
    }
  },
  SAVE_DO_STREAM_RESTART(state, val) {
    state.doStreamRestart = val
  },
  SAVE_EXCLUDE_SENDER_PARAMS(state, val) {
    state.excludeSenderParams = val
  },
  SAVE_USE_JANUS(state, val) {
    state.useJanus = val
  },
}
