import { find, cloneDeep, forEach } from "lodash"
import * as VueDeepSet from "vue-deepset"
import moment from "moment"

export default {
  SAVE_BUNDLES_LIST(state, list) {
    state.bundlesList = list
  },
  SAVE_BUNDLES_COUNT(state, count) {
    state.bundlesCount = count
  },
  SAVE_BUNDLE_ITEM(state, value) {
    state.activeBundle = { ...state.activeBundle, ...value }
  },
  SAVE_SELECTED_BUNDLE_IN_LIST(state, item) {
    if (item) {
      if (!find(state.bundlesList, { _key: item._key })) {
        state.bundlesList.push(item)
      }
    }
  },
  RESET_BUNDLE_ITEM(state, value) {
    let defaultPlan = cloneDeep(state.defaultBundle)
    forEach(defaultPlan, (value, key) => {
      VueDeepSet.vueSet(state.activeBundle, [key], value)
    })
  },
  SET_BUNDLE_TYPE(state, type) {
    VueDeepSet.vueSet(state.activeBundle, ["type_key"], type)
  },
  SET_BUNDLE_TYPES(state, types) {
    state.bundleTypes = types
  },
  SAVE_PRODUCT_REGISTERED_ITEM(state, value) {
    forEach(value, (v, key) => {
      VueDeepSet.vueSet(state.activeProductRegistered, [key], v)
    })
  },
  RESET_PRODUCT_REGISTERED_ITEM(state, value) {
    let defaultItem = cloneDeep(state.defaultProductRegistered)
    forEach(defaultItem, (value, key) => {
      VueDeepSet.vueSet(state.activeProductRegistered, [key], value)
    })
  },
  SET_PRODUCT_STATUSES(state, value) {
    state.productStatuses = value
  },

  // @todo maybe delete this endpoint later, there is no need for registry
  SAVE_ORDERS_REGISTERED_LIST(state, list) {
    state.ordersRegisteredList = list
  },
  // @todo maybe delete this endpoint later, there is no need for registry
  SAVE_ORDERS_REGISTERED_COUNT(state, count) {
    state.ordersRegisteredCount = count
  },
  // @todo maybe delete this endpoint later, there is no need for registry
  RESET_ORDER_REGISTERED_ITEM(state, value) {
    let defaultItem = cloneDeep(state.defaultOrderRegistered)
    forEach(defaultItem, (value, key) => {
      VueDeepSet.vueSet(state.activeOrderRegistered, [key], value)
    })
  },
  // @todo maybe delete this endpoint later, there is no need for registry
  RESET_ORDER_REGISTERED_LIST(state) {
    state.ordersRegisteredList = []
  },
  // @todo maybe delete this endpoint later, there is no need for registry
  SAVE_ORDER_REGISTERED_ITEM(state, value) {
    forEach(value, (v, key) => {
      VueDeepSet.vueSet(state.activeOrderRegistered, [key], v)
    })
  },
  // @todo maybe delete this endpoint later, there is no need for registry
  SET_ORDERS_REGISTERED_FILTERS(state, value) {
    forEach(value, (v, key) => {
      VueDeepSet.vueSet(state.bundlesRegisteredFilters, [key], v)
    })
  },

  SAVE_EVENTS_LIST(state, list) {
    state.eventsList = list
  },
  RESET_EVENTS_LIST(state, list) {
    state.eventsList = []
  },
  SAVE_EVENTS_COUNT(state, count) {
    state.eventsCount = count
  },
  SET_EVENT_TIMEZONE_FILTER(state, value) {
    VueDeepSet.vueSet(state.eventFilters, ["timezone"], value)
  },
  SET_EVENT_ENGINE_FILTER(state, value) {
    VueDeepSet.vueSet(state.eventFilters, ["engine_key"], value)
  },
  RESET_EVENT_ITEM(state) {
    let defaultItem = cloneDeep(state.defaultEvent)
    forEach(defaultItem, (value, key) => {
      VueDeepSet.vueSet(state.activeEvent, [key], value)
    })
  },
  SAVE_EVENT_ITEM(state, value) {
    forEach(value, (v, key) => {
      let currValue = v
      if (key === "start_date" || key === "end_date") {
        VueDeepSet.vueSet(state.activeEvent, key, currValue)
        let keySet = key === "start_date" ? "start" : "end"
        currValue = moment.unix(v).toDate() //format('YYYY-MM-DD HH:mm')
        VueDeepSet.vueSet(state.activeEvent, ["range", keySet], currValue)

        if (key === "start_date") {
          VueDeepSet.vueSet(
            state.activeEvent,
            ["startDateTime"],
            moment.unix(v).toDate()
          )
        }
        if (key === "end_date") {
          VueDeepSet.vueSet(
            state.activeEvent,
            ["endDateTime"],
            moment.unix(v).toDate()
          )
        }
      } else {
        VueDeepSet.vueSet(state.activeEvent, [key], currValue)
      }
    })

    // @todo maybe remove later this is range old logic
    // forEach(value, (v, key) => {
    //   let currValue = v
    //   if (key === 'start_date' || key === 'end_date') {
    //     currValue = moment.unix(v).format('YYYY-MM-DD')
    //   }
    //   VueDeepSet.vueSet(state.activeEvent, [key], currValue)
    // })
    // if (value.start_date && value.time_zone) {
    //   state.activeEvent.time = moment.tz(moment.unix(value.start_date), value.time_zone).format('HH:mm')
    // }
  },
  SET_COMPANY_FOR_EVENT(state, value) {
    VueDeepSet.vueSet(state.activeEvent, ["group_key"], value)
  },
  SET_DATES_FOR_BUNDLE_EVENT(state, value) {
    let startDate = value[0]
    VueDeepSet.vueSet(state.activeEvent, ["start_date"], startDate)
    let endDate = value.length > 1 && value[1] ? value[1] : null
    VueDeepSet.vueSet(state.activeEvent, ["end_date"], endDate)
  },
  SET_EVENT_FIELD(state, v) {
    VueDeepSet.vueSet(state.activeEvent, [v.field], v.value)
  },
  ADD_EVENT_MEMBER(state, value) {
    state.activeEvent.members.push(value)
  },
  SET_TIMEZONE_EVENT(state, value) {
    state.activeEvent.time_zone = value
  },
  SET_DATETIME_EVENT(state, value) {
    state.activeEvent = { ...state.activeEvent, value }
  },
  LOADING_START_STOP_EVENT(state, value) {
    state.loadingStartStopEvent = value
  },
  REFRESH_EVENT_MEMBERS_STATUSES(state, value) {
    state.activeEvent.members.forEach((m, indx) => {
      let finded = value.find((d) => d.engine_key === m.engine_key)
      if (finded && finded.status !== m.status) {
        VueDeepSet.vueSet(
          state.activeEvent,
          ["members", indx, "status"],
          finded.status
        )
      }
    })
  },
  SET_MEMBER_RC_DATA(state, value) {
    VueDeepSet.vueSet(
      state.activeEvent,
      ["members", value.index, "rcEngineData"],
      value.rcEngineData
    )
  },
  REFRESH_EVENT_MEMBERS_PLAYLISTS(state, value) {
    let indx = state.activeEvent.members.findIndex(
      (m) => m.engine_key === value.engine_key
    )
    let newEventData = value.members.find(
      (m) => m.engine_key === value.engine_key
    )
    if (indx !== -1 && newEventData) {
      VueDeepSet.vueSet(
        state.activeEvent,
        ["members", indx, "playlist"],
        newEventData.playlist
      )
    }
  },
  SAVE_EVENT_MEMBERS_ROLES_LIST(state, list) {
    state.eventMembersRolesList = list
  },
  SAVE_EVENT_MEMBERS_ROLES_COUNT(state, count) {
    state.eventMembersRolesCount = count
  },
  RESET_EVENT_MEMBERS_ROLE_ITEM(state) {
    let defaultItem = cloneDeep(state.defaultEventMemberRole)
    forEach(defaultItem, (value, key) => {
      VueDeepSet.vueSet(state.activeEventMemberRole, [key], value)
    })
  },
  SAVE_EVENT_MEMBER_ROLE_ITEM(state, value) {
    forEach(value, (v, key) => {
      VueDeepSet.vueSet(state.activeEventMemberRole, [key], v)
    })
  },
  SAVE_ENGINE_LOCATIONS_LIST(state, list) {
    state.engineLocationsList = list
  },
  SAVE_ENGINE_LOCATIONS_COUNT(state, count) {
    state.engineLocationsCount = count
  },
  RESET_ENGINE_LOCATION_ITEM(state) {
    let defaultItem = cloneDeep(state.defaultEngineLocation)
    forEach(defaultItem, (value, key) => {
      VueDeepSet.vueSet(state.activeEngineLocation, [key], value)
    })
  },
  SAVE_ENGINE_LOCATION_ITEM(state, value) {
    forEach(value, (v, key) => {
      VueDeepSet.vueSet(state.activeEngineLocation, [key], v)
    })
  },
  SET_ENGINE_LOCATION_FIELD(state, v) {
    VueDeepSet.vueSet(state.activeEngineLocation, [v.field], v.value)
  },
  SAVE_BUNDLE_CONNECTIONS_LIST(state, list) {
    state.bundleConnectionsList = list
  },
  SAVE_BUNDLE_CONNECTIONS_COUNT(state, count) {
    state.bundleConnectionsCount = count
  },
  SAVE_BUNDLE_CONNECTION_ITEM(state, value) {
    state.activeBundleConnection = {
      ...state.activeBundleConnection,
      ...value,
    }
  },
  RESET_BUNDLE_CONNECTION_ITEM(state, value) {
    let defaultItem = cloneDeep(state.defaultBundleConnection)
    forEach(defaultItem, (value, key) => {
      VueDeepSet.vueSet(state.activeBundleConnection, [key], value)
    })
  },
  RESET_BUNDLE_CONNECTIONS_LIST(state, value) {
    state.bundleConnectionsList = []
  },
  RESET_DEAL_ITEM(state, value) {
    let defaultItem = cloneDeep(state.defaultDeal)
    forEach(defaultItem, (value, key) => {
      VueDeepSet.vueSet(state.activeDeal, [key], value)
    })
  },
  SAVE_DEAL_ITEM(state, value) {
    forEach(value, (v, key) => {
      VueDeepSet.vueSet(state.activeDeal, [key], v)
    })
  },
  RESET_DEALS_LIST(state) {
    state.dealsList = []
  },
  SAVE_DEALS_LIST(state, list) {
    state.dealsList = list
  },
  SAVE_DEALS_COUNT(state, count) {
    state.dealsCount = count
  },
  SAVE_LICENSES_LIST(state, list) {
    state.licensesList = list
  },
  SAVE_LICENSES_COUNT(state, count) {
    state.licensesCount = count
  },
  RESET_LICENSE_ITEM(state, value) {
    let defaultItem = cloneDeep(state.defaultLicense)
    forEach(defaultItem, (value, key) => {
      VueDeepSet.vueSet(state.activeLicense, [key], value)
    })
  },
  SAVE_LICENSE_STATUSES_LIST(state, value) {
    state.licenseStatuses = value
  },
}
