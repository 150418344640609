<template>
  <svg
    :width="size"
    :height="size"
    :viewBox="`0 0 ${size} ${size}`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 0.999878C3.245 0.999878 1 3.24488 1 5.99988C1 8.75488 3.245 10.9999 6 10.9999C8.755 10.9999 11 8.75488 11 5.99988C11 3.24488 8.755 0.999878 6 0.999878ZM6 9.99988C3.795 9.99988 2 8.20488 2 5.99988C2 3.79488 3.795 1.99988 6 1.99988C8.205 1.99988 10 3.79488 10 5.99988C10 8.20488 8.205 9.99988 6 9.99988ZM7.5 5.99988C7.5 6.82988 6.83 7.49988 6 7.49988C5.17 7.49988 4.5 6.82988 4.5 5.99988C4.5 5.16988 5.17 4.49988 6 4.49988C6.83 4.49988 7.5 5.16988 7.5 5.99988Z"
      fill="#C5C6C8"
    />
    <circle cx="6" cy="5.99988" r="2" fill="#34C8FF" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: "#ffffff",
    },
    size: {
      type: Number,
      default: 12,
    },
  },
}
</script>
