import _ from "lodash"
import { RemoteControlPlaylistsEngineService } from "@/common/api/api.service"
import EngineService from "@/common/engine.service"

export default {
  rcPlaylistsEnginesListAction(_context, data) {
    return RemoteControlPlaylistsEngineService.get(data)
  },
  rcPlaylistsEngineGetInfoAction(_context, data) {
    return RemoteControlPlaylistsEngineService.info(data)
  },
  rcPlaylistsEngineParsedInfoAction({ dispatch }, data) {
    dispatch("rcPlaylistsEngineGetInfoAction", data)
      .then(function (response) {
        let rcEngineData = response.data.data.rc_engine
        dispatch("setRcParsedData", rcEngineData)
      })
      .catch(function () {
        // console.log('error on RC_PLAYLISTS_ENGINE_PARSED_INFO', response);
      })
  },
  rcAllPlaylistsEngineParsedInfoAction(_context) {
    return new Promise((resolve, reject) => {
      RemoteControlPlaylistsEngineService.get()
        .then(function (response) {
          let allConnections = response.data.data.rc_engines
          _.forEach(allConnections, function (value, key) {
            let plData = EngineService.parsePlaylistsData(value.items)
            allConnections[key].playlists = plData[0]
            allConnections[key].haveGroups = plData[1]
          })
          resolve(allConnections)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  rcPlaylistsEngineUserRightsAction(_context, data) {
    return RemoteControlPlaylistsEngineService.rights(data)
  },
  rcPlaylistsKioskPermLinkHashAction(_context, params) {
    return RemoteControlPlaylistsEngineService.getKioskPermLinkHash(params)
  },
  rcPlaylistsKioskHashInfoAction(_context, hash) {
    return RemoteControlPlaylistsEngineService.getKioskHashInfo(hash)
  },
  rcPlaylistsKioskSubmitSurveyAction(_context, data) {
    return RemoteControlPlaylistsEngineService.setKioskSurvey(data)
  },
  rcRogersSaveConcertPlaylistAction({ commit }, data) {
    commit("rcRogersSaveConcertPlaylist", data)
  },
  rcRogersSavePlayedStatusAction({ commit }, data) {
    commit("rcRogersSavePlayedStatus", data)
  },
  rcSaveActivePlaylistKeyAction({ commit, dispatch }, data) {
    commit("rcSaveActivePlaylistKey", data)
  },
  setRcParsedData({ commit }, data) {
    let rcEngineData = data
    let plData = EngineService.parsePlaylistsData(rcEngineData.items)
    rcEngineData.playlists = plData[0]
    rcEngineData.haveGroups = plData[1]
    commit("SET_RC_PARSED_DATA", rcEngineData)
  },
}
