import _ from "lodash"
import { WebEnginesService } from "@/common/api/api.service"
import { removeWebrtcSourcesDublicates } from "@/common/webrtc/helpers"
import {
  WEBRTC_SOURCES_NO_AUDIO_INPUT,
  WEBRTC_SOURCES_RESCAN_DEVICES,
} from "./constants"
import GeneralService from "@/common/general.service"
import * as VueDeepSet from "vue-deepset"
import * as stringSimilarity from "string-similarity"
const TAGS_VIDEO_SOURCE = ["webcamera", "webcam", "camera", "logitech"]
const TAGS_AUDIO_SOURCE = ["webcamera", "webcam", "camera", "logitech"]

export default {
  /**
   * To avoid problems when cache of available source was reseted but selected video source was saved in backend, we must reinit selected
   * video source if is not founded in available webrtc sources
   *
   * @param commit
   * @param getters
   * @param dispatch
   * @param deviceInfos
   */
  initSelectedVideoWebrtcSource({ state, getters, dispatch }, params = {}) {
    // Init video selected source
    let selected = null
    let defaultVideoS = null
    let allVideoSources = getters.getAllVideoSources
    if (allVideoSources.length > 0) {
      let webrtcScansObj = WEBRTC_SOURCES_RESCAN_DEVICES
      let validList = _.filter(allVideoSources, function (val) {
        return (
          val &&
          !_.isNull(val.deviceId) &&
          val.deviceId !== webrtcScansObj.deviceId
        )
      })
      if (validList.length > 0) {
        // find in list of available media devices if there are some that match by some tags
        let findedByTag = validList.find((device) => {
          return TAGS_VIDEO_SOURCE.some((tag) =>
            device.text.toLowerCase().includes(tag)
          )
        })
        defaultVideoS = findedByTag
          ? findedByTag.deviceId
          : validList[0].deviceId
      }
    }
    // check if we must return default value for video source (in case of create webengine)
    let getDefaultValue =
      !_.isUndefined(params.getDefaultValue) && params.getDefaultValue
        ? true
        : false
    if (getDefaultValue) {
      selected = defaultVideoS
    } else {
      let dbVideoSByBrowser = _.get(state.activeWebEngine.source.video_source, [
        GeneralService.getBrowserName(),
      ])
      if (typeof dbVideoSByBrowser !== "undefined") {
        if (!_.isNull(dbVideoSByBrowser)) {
          let findInCurrentSources = _.find(
            allVideoSources,
            (o) => o.deviceId == dbVideoSByBrowser
          )
          selected = findInCurrentSources ? dbVideoSByBrowser : defaultVideoS
        } else {
          selected = dbVideoSByBrowser
        }
      } else {
        selected = defaultVideoS
      }
    }
    return dispatch("saveVideoInputMediaConstraints", {
      [GeneralService.getBrowserName()]: selected,
    })
  },
  /**
   * To avoid problems when cache of available source was reseted but selected audio source was saved in backend, we must reinit selected
   * audio source if is not founded in available webrtc sources
   *
   * @param commit
   * @param getters
   * @param dispatch
   * @param deviceInfos
   */
  initSelectedAudioWebrtcSource({ state, getters, dispatch }, params = {}) {
    let selected = null
    let defaultAudioS = null
    let allAudioSources = getters.getAllAudioSources
    if (allAudioSources.length > 0) {
      let webrtcScansObj = WEBRTC_SOURCES_RESCAN_DEVICES
      let validList = _.filter(allAudioSources, function (val) {
        return (
          val &&
          !_.isNull(val.deviceId) &&
          val.deviceId !== webrtcScansObj.deviceId
        )
      })
      if (validList.length > 0) {
        // Check by similarity video source name compared with available audio source names
        let selectedVideoSource = getters.getSelectedObjectVideoSourceWebengine
        if (selectedVideoSource) {
          let audioSourceBySimilarity = validList.find((device) => {
            let similarity = stringSimilarity.compareTwoStrings(
              selectedVideoSource.text,
              device.text
            )
            return similarity >= 0.5
          })
          if (audioSourceBySimilarity) {
            defaultAudioS = audioSourceBySimilarity.deviceId
          }
        }
        if (!defaultAudioS) {
          // find in list of available media devices if there are some that match by some tags
          let findedByTag = validList.find((device) =>
            TAGS_AUDIO_SOURCE.some((tag) =>
              device.text.toLowerCase().includes(tag)
            )
          )
          defaultAudioS = findedByTag
            ? findedByTag.deviceId
            : validList[0].deviceId
        }
      }
    }
    // check if we must return default value for audio source (in case of create webengine)
    let getDefaultValue =
      !_.isUndefined(params.getDefaultValue) && params.getDefaultValue
        ? true
        : false
    if (getDefaultValue) {
      selected = defaultAudioS
    } else {
      let dbAudioSByBrowser = _.get(state.activeWebEngine.source.audio_source, [
        GeneralService.getBrowserName(),
      ])
      if (typeof dbAudioSByBrowser !== "undefined") {
        if (!_.isNull(dbAudioSByBrowser)) {
          let findInCurrentSources = _.find(
            allAudioSources,
            (o) => o.deviceId == dbAudioSByBrowser
          )
          selected = findInCurrentSources ? dbAudioSByBrowser : defaultAudioS
        } else {
          selected = dbAudioSByBrowser
        }
      } else {
        selected = defaultAudioS
      }
    }
    return dispatch("saveAudioInputMediaConstraints", {
      [GeneralService.getBrowserName()]: selected,
    })
  },

  /**
   * Reset all Webrtc sources from cache
   *
   * @param commit
   * @param getters
   * @param dispatch
   * @param deviceInfos
   */
  resetAllWebrtcSources({ commit, getters, dispatch }, deviceInfos) {
    commit("RESET_ALL_WEBRTC_SOURCES", deviceInfos)
  },
  /**
   *
   * Save video&audio webrtc sources without processing
   *
   * @param commit
   * @param getters
   * @param dispatch
   * @param deviceInfos
   */
  saveRawWebrtcSources({ commit, getters, dispatch }, deviceInfos) {
    commit("SAVE_RAW_WEBRTC_SOURCES", deviceInfos)
  },

  setDialogWebrtcSourcesStatus({ commit }, status) {
    return new Promise((resolve) => {
      commit("SET_DIALOG_WEBRTC_SOURCES_STATUS", status)
      resolve()
    })
  },

  saveWebrtcSources({ commit, getters, dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      setTimeout(function () {
        let videoInputs = []
        let audioInputs = []
        let audioOutput = []

        _.forEach(data, function (val, key) {
          let findedKey = undefined
          if (val.kind === "audioinput") {
            findedKey = _.findKey(audioInputs, { deviceId: val.deviceId })
            let findedV = _.find(state.parsedListWebrtcAudioSources, {
              deviceId: val.deviceId,
            })
            let dataValue = JSON.parse(JSON.stringify(val))
            let notNullList = _.filter(audioInputs, function (val, key) {
              return !_.isNull(val.deviceId) && key != findedKey
            })
            dataValue.text =
              !_.isEmpty(findedV) && findedV.label != ""
                ? findedV.label
                : val.label || `microphone ${notNullList.length + 1}`
            if (findedKey) {
              audioInputs[findedKey] = dataValue
            } else {
              audioInputs.push(dataValue)
            }
          } else if (val.kind === "audiooutput") {
            val.text = val.label || `speaker ${key + 1}`
            audioOutput.push(val)
          } else if (val.kind === "videoinput") {
            findedKey = _.findKey(videoInputs, { deviceId: val.deviceId })
            let findedV = _.find(state.parsedListWebrtcVideoSources, {
              deviceId: val.deviceId,
            })
            let dataValue = JSON.parse(JSON.stringify(val))
            let notNullList = _.filter(videoInputs, function (val, key) {
              return !_.isNull(val.deviceId) && key != findedKey
            })
            dataValue.text =
              findedV && findedV.label != ""
                ? findedV.label
                : val.label || `camera ${notNullList.length + 1}`
            if (findedKey) {
              videoInputs[findedKey] = dataValue
            } else {
              videoInputs.push(dataValue)
            }
          } else {
            console.log("Some other kind of source/device: ", val)
          }
        })
        commit(
          "SAVE_LIST_WEBRTC_VIDEO_SOURCES",
          removeWebrtcSourcesDublicates(videoInputs)
        )
        commit(
          "SAVE_LIST_WEBRTC_AUDIO_SOURCES",
          removeWebrtcSourcesDublicates(audioInputs)
        )
        dispatch("checkNoVideoMediaInput", data)
        dispatch("checkNoAudioMediaInput", data)
        resolve()
      }, 10)
    })
  },

  saveListWebrtcVideoSources({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        commit("SAVE_LIST_WEBRTC_VIDEO_SOURCES", data)
        resolve()
      }, 10)
    })
  },

  saveListWebrtcAudioSources({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        commit("SAVE_LIST_WEBRTC_AUDIO_SOURCES", data)
        resolve()
      }, 10)
    })
  },

  initLocalMediaConstraints({ commit, dispatch }, data) {
    dispatch("saveVideoInputMediaConstraints", data)
    dispatch("saveAudioInputMediaConstraints", data)
    dispatch("saveLocalStreamTypeSelected", data)
    // @todo must refactor to get data from db not from sessions
    //dispatch(SAVE_MODEL_WEBENGINE_REMAINING_DATA_ACTION, data);
  },

  saveVideoInputMediaConstraints({ commit, state, dispatch }, data) {
    // Set selected webrtc video input source for webrtc media constraints
    return new Promise((resolve) => {
      commit("SAVE_FORM_SELECTED_WEBRTC_VIDEO_SOURCE", data)
      resolve()
    })
  },

  saveAudioInputMediaConstraints({ commit, state, dispatch }, data) {
    // Set selected webrtc audio input source for webrtc media constraints
    return new Promise((resolve) => {
      commit("SAVE_FORM_SELECTED_WEBRTC_AUDIO_SOURCE", data)
      resolve()
    })
  },

  checkNoVideoMediaInput({ commit, state, getters }, data) {
    // If we select "No audio" option we must delete from selection "No video" option and elsewhere add
    let selectedWebrtcVideoSource = getters.getSelectedWebrtcVideoDeviceId
    let changedInpts = state.listWebrtcVideoSources
    if (_.isNull(data.value)) {
      // remove option "No audio"
      // changedInpts = _.filter(changedInpts, function (val) {
      //     return !_.isNull(val.deviceId);
      // });
      commit(
        "SAVE_LIST_WEBRTC_VIDEO_SOURCES",
        removeWebrtcSourcesDublicates(changedInpts)
      )
    } else {
      if (
        !_.find(state.listWebrtcVideoSources, { deviceId: null }) &&
        !_.isNull(selectedWebrtcVideoSource)
      ) {
        // add option "No audio"
        // changedInpts.unshift(NO_VIDEO_MEDIA_INPUT);
        commit(
          "SAVE_LIST_WEBRTC_VIDEO_SOURCES",
          removeWebrtcSourcesDublicates(changedInpts)
        )
      }
    }
  },

  checkNoAudioMediaInput({ commit, state, getters }, data) {
    // If we select "No video" option we must delete from selection "No audio" option and elsewhere add
    let selectedWebrtcAudioSource = getters.getSelectedWebrtcAudioDeviceId
    let changedInpts = state.listWebrtcAudioSources
    if (_.isNull(data.value)) {
      // remove option "No audio"
      // changedInpts = _.filter(changedInpts, function (val) {
      //     return !_.isNull(val.deviceId);
      // });
      commit("SAVE_LIST_WEBRTC_AUDIO_SOURCES", changedInpts)
    } else {
      if (
        state.modelWebengine.localStreamTypeSelected == "type_screen_capture" ||
        (!_.find(state.listWebrtcAudioSources, { deviceId: null }) &&
          !_.isNull(selectedWebrtcAudioSource))
      ) {
        // add option "No audio"
        // changedInpts.unshift(WEBRTC_SOURCES_NO_AUDIO_INPUT);
        commit("SAVE_LIST_WEBRTC_AUDIO_SOURCES", changedInpts)
      }
    }
  },

  saveLocalStreamTypeSelected({ commit, state, dispatch }, data) {
    // Set selected webrtc video input source for webrtc media constraints
    let selectedVal = undefined
    if (_.has(data, "value")) {
      selectedVal = data.value
    } else {
      if (data.app.$session.has("currentLocalType")) {
        selectedVal = data.app.$session.get("currentLocalType")
      } else {
        selectedVal = state.modelWebengine.listLocalStreamTypes[0].id
      }
    }
    data.app.$session.set("currentLocalType", selectedVal)
    commit("SAVE_LOCAL_STREAM_TYPE_SELECTED", selectedVal)
    dispatch("checkNoAudioMediaInput", { app: data.app })
  },

  // [SAVE_MODEL_WEBENGINE_REMAINING_DATA_ACTION]({commit, state, dispatch}, data) {
  //     let modelWebengine = {}
  //
  //     // Set selected webrtc video input source for webrtc media constraints
  //     let selectedVal = '';
  //     if (data.app.$session.has('currentWebengineName')) {
  //         selectedVal = data.app.$session.get('currentWebengineName');
  //     }
  //     modelWebengine.name = selectedVal;
  //     //selectedVal = '';
  //     data.app.$session.set('currentLocalType', selectedVal);
  //     commit('SAVE_LOCAL_STREAM_TYPE_SELECTED', selectedVal);
  //     dispatch('checkNoAudioMediaInput', {app: data.app});
  // },

  saveListLocalTurns({ commit }, data) {
    commit("SAVE_LIST_LOCAL_TURNS", data)
  },

  saveListRemoteTurns({ commit }, data) {
    commit("SAVE_LIST_REMOTE_TURNS", data)
  },

  saveInputStream({ commit }, data) {
    commit("SAVE_INPUT_STREAM", data)
  },

  saveCommonRoomSignal({ commit }, data) {
    commit("SAVE_COMMON_ROOM_SIGNAL", data)
  },

  saveTurnLocal({ commit }, data) {
    commit("SAVE_TURN_LOCAL", data)
  },

  saveTurnRemote({ commit }, data) {
    commit("SAVE_TURN_REMOTE", data)
  },

  saveIsLocalStreamStarted({ commit }, data) {
    commit("SAVE_IS_LOCAL_STREAM_STARTED", data)
    if (data) {
      commit("SAVE_LOCAL_STREAM_ERRORS", { show: false })
    }
  },

  saveIsPreviewRemoteStreamStarted({ commit }, data) {
    commit("SAVE_IS_PREVIEW_REMOTE_STREAM_STARTED", data)
    if (data) {
      commit("SAVE_REMOTE_STREAM_ERRORS", { show: false })
    }
  },

  saveVolumeAudioLocalStream({ commit }, data) {
    commit("SAVE_VOLUME_AUDIO_LOCAL_STREAM", data)
  },

  saveVolumeAudioRemoteStream({ commit }, data) {
    commit("SAVE_VOLUME_AUDIO_REMOTE_STREAM", data)
  },

  webenginesGetList({ commit }, params) {
    return WebEnginesService.get(params)
  },

  webenginesGetSingle({ commit }, getParams) {
    return new Promise((resolve, reject) => {
      WebEnginesService.get(getParams).then(function (response) {
        let data = response.data.data.web_engine || {}
        //data['remote']['stream'] = null;
        commit("WEBENGINES_GET_SINGLE", data)
        resolve()
      })
    })
  },

  webengineSaveSingleByHash({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("WEBENGINES_GET_SINGLE", data)
      resolve()
    })
  },

  webengineGetLink({ state }, params) {
    return WebEnginesService.generatedLink(params)
  },

  webenginesCreateAction({ state }, formData) {
    return WebEnginesService.create(formData.data)
  },

  webenginesEditAction({ state }, formData) {
    return WebEnginesService.update(formData.slug, formData.data)
  },

  webenginesDeleteAction({ state }, item) {
    return WebEnginesService.destroy(item._key)
  },

  webenginesResetData({ commit }) {
    return new Promise((resolve) => {
      commit("SAVE_LOCAL_STREAM_ERRORS", {
        show: false,
        timeout: -1,
        text: "No text.",
        color: "error",
      })
      commit("SAVE_REMOTE_STREAM_ERRORS", {
        show: false,
        timeout: -1,
        text: "No text.",
        color: "error",
      })
      commit("WEBENGINES_RESET_DATA")
      resolve()
    })
  },

  webenginesSaveSelectedSignal({ commit }, value) {
    commit("WEBENGINES_SAVE_SELECTED_SIGNAL", value)
  },

  webenginesSetActiveSignal({ commit, state, rootState, getters }) {
    let signalKey = state.activeWebEngine.signal_server_key
    if (!signalKey) {
      let companyDefaultSignalKey = _.get(
        rootState.getDefaultSignalServer,
        "_key"
      )
      if (
        _.find(rootState.signalServersList, { _key: companyDefaultSignalKey })
      ) {
        signalKey = companyDefaultSignalKey
      }
    }
    commit("WEBENGINES_SET_ACTIVE_SIGNAL", signalKey)
  },

  webenginesSetUserName({ commit }, data) {
    commit("WEBENGINES_SET_USER_NAME", data)
  },

  webenginesGenerateLinkAction({ commit }, params) {
    return WebEnginesService.generateLink(params)
  },

  webenginesHashInfo({ commit, dispatch }, hash) {
    return WebEnginesService.getHashInfo(hash)
  },

  // remote streams actions
  saveRemoteStream({ commit }, stream) {
    return new Promise((resolve, reject) => {
      commit("SAVE_REMOTE_STREAM", stream)
      resolve()
    })
  },

  removeRemoteStream({ commit }) {
    return new Promise((resolve, reject) => {
      commit("REMOVE_REMOTE_STREAM")
      resolve()
    })
  },

  muteRemoteChannel({ commit, state }, channel) {
    commit("MUTE_REMOTE_CHANNEL", channel)
  },

  saveVideoResolution({ commit, state, getters }, resolution) {
    return new Promise((resolve) => {
      // save in form data video resolution
      commit("SAVE_VIDEO_RESOLUTION", resolution)
      // save scanned video resolution
      let currentScannedConstraints = getters.getCurrentScannedConstraints
      if (currentScannedConstraints) {
        let indx = _.findIndex(state.scannedConstraintsList, {
          deviceId: currentScannedConstraints.deviceId,
        })
        commit("SAVE_VIDEO_RESOLUTION_SCANNED", { resolution, indx })
      }
      resolve()
    })
  },

  saveVideoFrameRate({ commit, state }, frameRate) {
    commit("SAVE_VIDEO_FRAME_RATE", frameRate)
  },

  saveScannedConstraints({ commit, state }, constraints) {
    commit("SAVE_SCANNED_CONSTRAINTS", constraints)
  },

  saveUnchangedScannedConstraints({ commit, state }, constraints) {
    commit("SAVE_UNCHANGED_SCANNED_CONSTRAINTS", constraints)
  },

  resetScannedConstraints({ commit, state }, constraints) {
    commit("RESET_SCANNED_CONSTRAINTS", constraints)
  },

  saveVideoCodec({ commit, state }, codec) {
    commit("SAVE_VIDEO_CODEC", codec)
  },

  saveWebengineSendersSignal({ commit }, list) {
    commit("SAVE_WEBENGINE_SENDERS_SIGNAL", list)
  },

  // set selected signal server, when there is no value - did try select signal for company
  initSelectedSignalKey({ commit, getters, state, dispatch }) {
    return new Promise((resolve) => {
      let prmsGetSignal = new Promise((resolve) => {
        if (state.activeWebEngine.signal_server_key) {
          resolve(state.activeWebEngine.signal_server_key)
        } else {
          resolve(_.get(getters.getDefaultSignalServer, "_key"))
        }
      })
      prmsGetSignal.then((signalKey) => {
        commit("INIT_SELECTED_SIGNAL_KEY", signalKey)
        resolve()
      })
    })
  },
  saveWebengineCurrentReceived({ commit }, value) {
    commit("SAVE_WEBENGINE_CURRENT_RECEIVED", value)
  },
  saveWebengineCurrentSended({ commit }, value) {
    commit("SAVE_WEBENGINE_CURRENT_SENDED", value)
  },
  saveWebengineCurrentLost({ commit }, value) {
    commit("SAVE_WEBENGINE_CURRENT_LOST", value)
  },
  saveLocalStreamErrors({ commit }, value) {
    commit("SAVE_LOCAL_STREAM_ERRORS", value)
  },
  saveRemoteStreamErrors({ commit }, value) {
    commit("SAVE_REMOTE_STREAM_ERRORS", value)
  },
  saveDoStreamRestart({ commit }, value) {
    commit("SAVE_DO_STREAM_RESTART", value)
  },
  saveExcludeSenderParams({ commit }, value) {
    commit("SAVE_EXCLUDE_SENDER_PARAMS", value)
  },
  saveUseJanus({ commit }, value) {
    commit("SAVE_USE_JANUS", value)
  },
}
