import Vue from "vue"
import _ from "lodash"

export default {
  SET_RC_PARSED_DATA(state, data) {
    state.rcEngineData = data
  },
  rcRogersSaveConcertPlaylist(state, value) {
    state.rogersConcertPlaylist = value
  },
  rcRogersSavePlayedStatus(state, data) {
    let updatePlayed = _.filter(state.rogersConcertPlaylist, (o) =>
      _.has(o, "played")
    )
    _.forEach(updatePlayed, (val, key) => {
      Vue.set(state.rogersConcertPlaylist[key], "played", false)
    })
    let findKey = _.findKey(state.rogersConcertPlaylist, { pos: data.pos })
    if (findKey) {
      Vue.set(state.rogersConcertPlaylist[findKey], "played", data.val)
    }
  },
  rcSaveActivePlaylistKey(state, value) {
    state.activePlaylistKey = value
  },
  rcUpdateLastKioskPong(state, value) {
    state.lastKioskPong = value
  },
}
