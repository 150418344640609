import { Validator } from "vee-validate"

Validator.extend("isNumerical", {
  validate: (value) => {
    if (value) {
      return value.toString().match(/^[\d.]+$/) ? true : false
    }
    return true
  },
  getMessage: (field, args) => {
    return `${field} must be numeric`
  },
})

Validator.extend("emailWithSpaces", {
  validate: (value) => {
    if (value) {
      let isValid = value.toString().match(
        /* eslint-disable-next-line */
        /^\s*[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,}\s*$/
      )
      return isValid ? true : false
    }
    return true
  },
  getMessage: (field, args) => {
    return `The ${field} field must be a valid email.`
  },
})
