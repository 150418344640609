import Vue from "vue"
import _ from "lodash"
import * as VueDeepSet from "vue-deepset"

export default {
  saveTestInProgress(state, value) {
    state.testInProgress = value
  },
  saveIsTestInitiator(state, value) {
    state.isTestInitiator = value
  },
  speedTestGetSingle(state, value) {
    state.activeSpeedTest = { ...state.activeSpeedTest, ...value }
  },
  SPEED_TEST_RESET_DATA(state) {
    state.activeSpeedTest = _.cloneDeep(state.defaultSpeedTest)
    state.testInProgress = false
    VueDeepSet.vueSet(state.activeSpeedTest, ["stats", "raw"], [])
    VueDeepSet.vueSet(state.activeSpeedTest, ["stats", "calculated"], null)
    state.workerTasks = []
    state.clientId = null
    state.roomCliens = []
    state.workerData = {
      ptop: null,
      turns: [],
    }
    state.partnerInfo = {
      ip: null,
      location: null,
    }
    state.partnerDisconnected = false
    state.testCompletedDialog = false
    state.loaderPdf = false
    state.errorText = null
    // state.isTestInitiator = false;
  },
  SPEED_TEST_ERRORS(state, val) {
    state.errorText = val
  },
  speedTestSaveSignalUrl(state, value) {
    state.activeSpeedTest.signal_server_url = value
  },
  speedTestSaveTurnsUrls(state, value) {
    state.activeSpeedTest.turn_server_urls = value
  },
  speedTestSaveWorkerTasks(state, value) {
    state.workerTasks = value
  },
  speedTestSaveWebrtcPeers(state, data) {
    let findedTaskKey = _.findKey(state.workerTasks, { id: data.item.id })
    if (findedTaskKey) {
      let newVal = { ...state.workerTasks[findedTaskKey], ...data.value }
      Vue.set(state.workerTasks, findedTaskKey, newVal)
      // state.workerTasks[findedTaskKey] = {...state.workerTasks[findedTaskKey], ...data.value};
    }
  },
  speedTestSaveStatistics(state, value) {
    state.activeSpeedTest.stats.raw.push(value)
  },
  speedTestSaveRawStatistics(state, value) {
    state.activeSpeedTest.stats.raw.push(value)
  },
  speedTestSaveCalculatedStatistics(state, value) {
    state.activeSpeedTest.stats.calculated = value
  },
  speedTestResetStats(state) {
    VueDeepSet.vueSet(state.activeSpeedTest, ["stats", "raw"], [])
    VueDeepSet.vueSet(state.activeSpeedTest, ["stats", "calculated"], null)
  },
  speedTestSaveIsFinished(state, value) {
    if (!value) {
      VueDeepSet.vueSet(state.activeSpeedTest, ["stats", "raw"], [])
      VueDeepSet.vueSet(state.activeSpeedTest, ["stats", "calculated"], null)
    }
    state.activeSpeedTest.finishedLocal = value
  },
  speedTestSaveSource(state, value) {
    state.activeSpeedTest.source = value
  },
  speedTestSaveDestination(state, value) {
    state.activeSpeedTest.destination = value
  },
  speedTestSaveClientId(state, value) {
    state.clientId = value
  },
  speedTestSaveRoomCliens(state, value) {
    state.roomCliens = value
  },
  speedTestSavePartnerInfo(state, value) {
    state.partnerInfo = { ...state.partnerInfo, ...value }
  },
  speedTestPartnerDisconnected(state, value) {
    state.partnerDisconnected = value
  },
  speedTestShowCompletedDialog(state, value) {
    state.testCompletedDialog = value
  },
  speedTestLoaderPdf(state, value) {
    state.loaderPdf = value
  },
  SPEED_TEST_SAVE_DURATION(state, value) {
    VueDeepSet.vueSet(state.activeSpeedTest, ["duration"], parseInt(value))
  },
  SPEED_TEST_SAVE_BANDWIDTH(state, value) {
    VueDeepSet.vueSet(state.activeSpeedTest, ["bandwidth"], parseFloat(value))
  },
  SPEED_TEST_PEER_ACTIVE_CANDIDATE(state, val) {
    let findedTaskKey = _.findKey(state.workerTasks, { id: val.id })
    if (findedTaskKey) {
      VueDeepSet.vueSet(
        state.workerTasks,
        [findedTaskKey, "local", "peerData"],
        val?.peerData?.pair?.local
          ? _.pick(val.peerData.pair.local, [
              "address",
              "candidate",
              "component",
              "type",
              "port",
              "protocol",
            ])
          : {}
      )

      VueDeepSet.vueSet(
        state.workerTasks,
        [findedTaskKey, "remote", "peerData"],
        val?.peerData?.pair?.remote
          ? _.pick(val.peerData.pair.remote, [
              "address",
              "candidate",
              "component",
              "type",
              "port",
              "protocol",
            ])
          : {}
      )
    }
  },
  IS_SAVING_SPEED_TEST(state, value) {
    state.isSavingSpeedTest = value
  },
}
