import UserService from "@/common/api/user.service"

export default {
  getUserRole(context, _key) {
    return UserService.userRoles(_key)
      .then((response) => {
        context.commit("setUserRole", response.data.data.role)
        return response
      })
      .catch((error) => {
        console.log(error)
      })
  },
  getRolesList(context, params = { params: { p: 1, pp: 10 } }) {
    return UserService.userRolesList(params)
      .then((response) => {
        // console.log(response)
        context.commit("saveRolesList", response.data.data.result)
        context.commit("saveRolesCount", response.data.data.full_count)
        return response
      })
      .catch((error) => {
        console.log(error)
      })
  },
  createUserRole(context, formData) {
    // console.log('store createUserRole', formData)
    return UserService.userCreateUserRole(formData.data)
  },
  updateUserRole(context, formData) {
    // console.log('stare updateUserRole', formData)
    return UserService.userUpdateRole(formData.slug, formData.data)
  },
  getAllPermissions({ state }) {
    return UserService.getAllPermissionsList()
  },
  saveFlatPermissions(context, flatPermissions) {
    context.commit("setFlatPermissions", flatPermissions)
  },
  saveAllPermissions(context, allPermissions) {
    context.commit("setAllPermissions", allPermissions)
  },
}
