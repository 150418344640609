import Vue from "vue"
import Vuex from "vuex"
import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"

Vue.use(Vuex)

const state = {
  debugSignalServersList: [],
  debugSignalServersListCount: 0,
  debugTurnServersList: [],
  debugTurnServersListCount: 0,
  turnApiDebugInfo: [],
  debugJanusServersList: [],
  debugJanusServersListCount: 0,
  janusApiDebugInfo: [],
}

export default {
  state,
  mutations,
  getters,
  actions,
}
