import {
  SignalServersService,
  TurnServersService,
  JanusServersService,
  BalancingGroupsService,
  VersionMessagesService,
  PagesService,
} from "@/common/api/api.service"
import UserService from "../../common/api/user.service"

export default {
  signalServersList({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      if (!payload.params) payload.params = { p: 1, pp: 10 }
      payload.params.type = "signal_server"
      SignalServersService.get(payload)
        .then((response) => {
          let arrData = response?.data?.data?.settings_multi || []
          let count = response?.data?.data?.full_count || 0
          commit("SAVE_SIGNAL_SERVERS_LIST", arrData)
          commit("SAVE_SIGNAL_SERVERS_LIST_COUNT", count)
          resolve({ arrData, count })
        })
        .catch((err) => {
          console.log("STORE signalServersList ERROR", err)
          reject(err)
        })
    })
  },
  resetSignalServersList({ commit }) {
    return new Promise((resolve, reject) => {
      commit("RESET_SIGNAL_SERVERS_LIST")
      resolve()
    })
  },
  /**
   * Get data for selected signal server in popup (in case when async select)
   * //@todo maybe later refactor in backend to send field that will tell what engine to preserve in response
   *
   * @param commit
   * @param dispatch
   * @param params
   */
  signalSelectedInlist({ commit, dispatch }, params) {
    SignalServersService.get(params)
      .then((response) => {
        commit(
          "SAVE_SELECTED_SIGNAL_IN_LIST",
          response.data.data.settings_multi
        )
      })
      .catch((err) => {
        console.log("signalSelectedInlist ERROR", err)
      })
  },
  signalServersCreateAction(context, formData) {
    return SignalServersService.create(formData.data)
  },
  signalServersEditAction({ state }, formData) {
    return SignalServersService.update(formData.slug, formData.data)
  },
  signalServersCompanyGetItemAction({ state }, slug) {
    return SignalServersService.getItem(slug)
  },
  signalServersDeleteAction({ state }, item) {
    return SignalServersService.destroy(item._key)
  },
  defaultSignalServer() {
    let getParams = {
      params: {
        p: 1,
        pp: 10,
        "data.is_default": true,
        type: "signal_server",
      },
    }
    return SignalServersService.getDefault(getParams)
  },
  turnServersList({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      if (!params.params) params.params = { p: 1, pp: 10 }
      params = {
        ...params,
        ...{
          params: {
            type: "turn_server",
          },
        },
      }
      TurnServersService.get(params)
        .then((response) => {
          let arrData = response?.data?.data?.settings_multi || []
          let count = response?.data?.data?.full_count || 0
          commit("SAVE_TURN_SERVERS_LIST", arrData)
          commit("SAVE_TURN_SERVERS_LIST_COUNT", count)
          resolve({ arrData, count })
        })
        .catch((err) => {
          console.log("STORE turnServersList ERROR", err)
          reject(err)
        })
    })
  },
  resetTurnServersList({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SAVE_TURN_SERVERS_LIST", [])
      commit("SAVE_TURN_SERVERS_LIST_COUNT", 0)
      resolve()
    })
  },
  /**
   * Get data for selected turn server in popup (in case when async select)
   * //@todo maybe later refactor in backend to send field that will tell what engine to preserve in response
   *
   * @param commit
   * @param dispatch
   * @param params
   */
  turnSelectedInlist({ commit, dispatch }, params) {
    TurnServersService.get(params)
      .then((response) => {
        commit(
          "SAVE_SELECTED_SIGNAL_IN_LIST",
          response.data.data.settings_multi
        )
      })
      .catch((err) => {
        console.log("signalSelectedInlist ERROR", err)
      })
  },
  turnServersCompanyGetLists({ state }, params) {
    return TurnServersService.get(params)
  },
  turnServersCreateAction(context, formData) {
    return TurnServersService.create(formData.data)
  },
  turnServersEditAction({ state }, formData) {
    return TurnServersService.update(formData.slug, formData.data)
  },
  turnServersDeleteAction({ state }, item) {
    return TurnServersService.destroy(item._key)
  },
  janusServersCompanyGetLists({ state }, params) {
    return JanusServersService.get(params)
  },
  janusServersCreateAction(context, formData) {
    return JanusServersService.create(formData.data)
  },
  janusServersEditAction({ state }, formData) {
    return JanusServersService.update(formData.slug, formData.data)
  },
  janusServersDeleteAction({ state }, item) {
    return JanusServersService.destroy(item._key)
  },
  balancingGroupsGetListAction({ state }, params) {
    return BalancingGroupsService.get(params)
  },
  versionMsgsGetLists({ state }, params) {
    return VersionMessagesService.get(params)
  },
  versionMsgsCreateAction(context, formData) {
    return VersionMessagesService.create(formData.data)
  },
  versionMsgsEditAction({ state }, formData) {
    return VersionMessagesService.update(formData.slug, formData.data)
  },
  versionMsgsDeleteAction({ state }, item) {
    return VersionMessagesService.destroy(item._key)
  },
  pagesList(
    { state, commit, dispatch },
    params = { params: { p: 1, pp: 10 } }
  ) {
    return new Promise((resolve, reject) => {
      PagesService.get(params)
        .then((response) => {
          let arrData = response?.data?.data?.result || []
          let count = response?.data?.data?.full_count || 0
          commit("SAVE_PAGES_LIST", arrData)
          commit("SAVE_PAGES_LIST_COUNT", count)
          resolve({ arrData, count })
        })
        .catch((err) => {
          console.log("STORE pagesList ERROR", err)
          reject(err)
        })
    })
  },
  resetPageItem({ commit }) {
    return new Promise((resolve, reject) => {
      commit("RESET_PAGE_ITEM")
      resolve()
    })
  },
  getPageItem({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      PagesService.getItem(data.slug).then(function (response) {
        let data = response.data.data || {}
        commit("SAVE_PAGE_ITEM", data)
        resolve()
      })
    })
  },
  createPage({ commit }, data) {
    return PagesService.create(data.data)
  },
  editPage({ commit }, data) {
    return PagesService.update(data.slug, data.data)
  },
  deletePage({ state }, item) {
    return PagesService.destroy(item._key)
  },
  setPageContent({ commit }, val) {
    commit("SAVE_PAGE_ITEM", { content: val })
  },
}
