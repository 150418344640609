import { includes } from "lodash"

export const defineAbilityFor = function (permissions) {
  const usersPermissions = []
  let isSuperadmin = includes(permissions, "__superuser__")
  let superActions = ["read", "create", "update", "delete"]
  for (let superAction of superActions) {
    if (isSuperadmin) {
      usersPermissions.push({
        action: superAction,
        subject: "Superadmin",
      })
    }
  }

  let filteredPermissions = permissions.filter(function (item) {
    return item !== "__superuser__"
  })

  for (let p of filteredPermissions) {
    let splitted = p.split(".")
    let action
    let isWsRule = includes(splitted, "ws")
    if (isWsRule) {
      // @todo need to find best way to generate action name
      action = splitted.slice(2).join(".") || splitted.slice(1).join(".")
    } else {
      let type = splitted[splitted.length - 1]
      if (type === "_get_") {
        action = "read"
      } else if (type === "_post_") {
        action = "create"
      } else if (type === "_put_") {
        action = "update"
      } else if (type === "_delete_") {
        action = "delete"
      } else {
        action = "read"
      }
    }

    splitted.shift()
    splitted.pop()
    let permissionName = splitted.join("_")

    usersPermissions.push({
      action: action,
      subject: permissionName,
    })
  }

  usersPermissions.push({
    action: "read",
    subject: "All",
  })
  return usersPermissions
}

export default (store) => {
  const ability = store.getters.ability
  ability.update(store.state.auth.userPermissions)

  return store.subscribe((mutation) => {
    switch (mutation.type) {
      case "CREATE_SESSION":
        ability.update(mutation.payload)
        break
      case "DESTROY_SESSION":
        ability.update([])
        break
    }
  })
}
