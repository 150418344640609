import { signalApiInstance } from "./config"

export const STATUS_SERVER_ACTIVE = "status_server_active"
export const STATUS_SERVER_INACTIVE = "status_server_inactive"
export const STATUS_SERVER_UNKNOWN = "status_server_unknown"

export const SignalServerService = {
  health(baseURL) {
    signalApiInstance.defaults.baseURL = baseURL
    return signalApiInstance.request({ url: "v1/signal/health" })
  },
  configs(baseURL) {
    signalApiInstance.defaults.baseURL = baseURL
    return signalApiInstance.request({ url: "v1/signal/configs" })
  },
  turnsStatuses(baseURL) {
    signalApiInstance.defaults.baseURL = baseURL
    return signalApiInstance.request({ url: "v1/turn/status" })
  },
  janusesStatuses(baseURL) {
    signalApiInstance.defaults.baseURL = baseURL
    return signalApiInstance.request({ url: "v1/janus/status" })
  },
}
