// @todo this service and linked functionality with this must be deleted after user rules implementation

import cookies from "browser-cookies"
const ID_HASH_TYPE_KEY = "id_hash_type_key"

export const getHashLogin = () => {
  return (
    window.localStorage.getItem(ID_HASH_TYPE_KEY) ||
    cookies.get(ID_HASH_TYPE_KEY)
  )
}

export const saveHashLogin = (hashType) => {
  return new Promise((resolve) => {
    window.localStorage.setItem(ID_HASH_TYPE_KEY, hashType)
    cookies.set(ID_HASH_TYPE_KEY, hashType, { expires: 365 })
    setTimeout(() => {
      resolve()
    }, 1000)
  })
}

export const destroyHashLogin = () => {
  window.localStorage.removeItem(ID_HASH_TYPE_KEY)
  cookies.erase(ID_HASH_TYPE_KEY)
}

export default { getHashLogin, saveHashLogin, destroyHashLogin }
