import { TurnServersService } from "@/common/api/api.service"
import { JanusServersService } from "../../common/api/api.service"

function getCompleteUrl(item) {
  return item.protocol + "://" + item.hostname + ":" + item.port
}

export default {
  debugSignalServersList({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      dispatch("signalServersList", params)
        .then((data) => {
          let signalServers = data.arrData.map((val) => {
            val.data.url = getCompleteUrl(val.data)
            return val
          })
          commit("SAVE_DEBUG_SIGNAL_SERVERS_LIST", signalServers)
          commit("SAVE_DEBUG_SIGNAL_SERVERS_LIST_COUNT", data.count)
          resolve()
        })
        .catch((err) => {
          console.log("STORE debugSignalServersList ERROR", err)
          reject(err)
        })
    })
  },
  setStatusSignal({ commit }, data) {
    return new Promise((resolve) => {
      commit("SET_STATUS_SIGNAL", data)
      resolve()
    })
  },
  setConfigsSignal({ commit }, data) {
    return new Promise((resolve) => {
      commit("SET_CONFIGS_SIGNAL", data)
      resolve()
    })
  },
  debugTurnServersList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      if (!payload.params) payload.params = { p: 1, pp: 10 }
      payload.params.type = "turn_server"
      TurnServersService.get(payload)
        .then((response) => {
          let arrData = response?.data?.data?.settings_multi || []
          let count = response?.data?.data?.full_count || 0
          let turnServers = arrData.map((val) => {
            val.data.url = getCompleteUrl(val.data)
            return val
          })
          commit("SAVE_DEBUG_TURN_SERVERS_LIST", turnServers)
          commit("SAVE_DEBUG_TURN_SERVERS_LIST_COUNT", count)
          resolve()
        })
        .catch((err) => {
          console.log("STORE debugTurnServersList ERROR", err)
          reject(err)
        })
    })
  },
  resetTurnsApiDebugInfo({ commit }) {
    return new Promise((resolve) => {
      commit("RESET_TURNS_API_DEBUG_INFO")
      resolve()
    })
  },
  saveTurnsApiDebugInfo({ commit }, data) {
    return new Promise((resolve) => {
      commit("SAVE_TURNS_API_DEBUG_INFO", data)
      resolve()
    })
  },
  parseTurnsApiDebugInfo({ commit }, data) {
    return new Promise((resolve) => {
      commit("PARSE_TURNS_API_DEBUG_INFO", data)
      resolve()
    })
  },
  debugJanusServersList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      if (!payload.params) payload.params = { p: 1, pp: 10 }
      payload.params.type = "janus_server"
      JanusServersService.get(payload)
        .then((response) => {
          let arrData = response?.data?.data?.settings_multi || []
          let count = response?.data?.data?.full_count || 0
          let janusServers = arrData.map((val) => {
            val.data.url = getCompleteUrl(val.data)
            return val
          })
          commit("SAVE_DEBUG_JANUS_SERVERS_LIST", janusServers)
          commit("SAVE_DEBUG_JANUS_SERVERS_LIST_COUNT", count)
          resolve()
        })
        .catch((err) => {
          console.log("STORE debugJanusServersList ERROR", err)
          reject(err)
        })
    })
  },
  resetJanusesApiDebugInfo({ commit }) {
    return new Promise((resolve) => {
      commit("RESET_JANUSES_API_DEBUG_INFO")
      resolve()
    })
  },
  saveJanusesApiDebugInfo({ commit }, data) {
    return new Promise((resolve) => {
      commit("SAVE_JANUSES_API_DEBUG_INFO", data)
      resolve()
    })
  },
  parseJanusesApiDebugInfo({ commit }, data) {
    return new Promise((resolve) => {
      commit("PARSE_JANUSES_API_DEBUG_INFO", data)
      resolve()
    })
  },
}
