import _ from "lodash"
import { getCompleteUrl } from "@/common/webrtc/helpers"

export default {
  streamsActiveSignalKey: (state) => state.streamsConfigs.activeSignalKey,
  streamsActiveCompany: (state) => state.streamsConfigs.activeCompany,
  streamsCommonRoom: (state) => state.streamsConfigs.commonRoom,
  streamsDefaultSignalServer: (state, rootState, getters) => {
    let signal = null
    let companyInfo = state.streamsConfigs.activeCompany
    let activeSignalServer = _.get(companyInfo, "signal_server_key")
    if (activeSignalServer) {
      let signalData = _.find(rootState.signalServersList, function (o) {
        return o._key === activeSignalServer
      })
      signal = signalData
    } else {
      let firstSignal = _.get(rootState.signalServersList, "[0]")
      if (firstSignal) {
        signal = firstSignal
      }
    }
    return signal
  },
  streamsSignalRoom: (state, rootState) => {
    if (state.streamsConfigs.commonRoom) {
      return "aspx_room"
    }
    return state?.streamsConfigs?.activeCompany?.room
  },
  streamsActiveSignalServerUrl: (state, getters) => {
    let url = null
    let finded = _.find(getters.signalServersList, {
      _key: state.streamsConfigs.activeSignalKey,
    })
    if (!_.isEmpty(finded)) {
      url = getCompleteUrl(finded.data)
    }
    return url
  },
  streamsActive: (state) => state.streamsActive,
  streamsActiveCount: (state) => state.streamsActiveCount,
  streamsInactive: (state) => state.streamsInactive,
  streamsInactiveCount: (state) => state.streamsInactiveCount,
  streamsPeerReceiver: (state) => state.streamsPeerReceiver,
  dialogPreviewStream: (state) => state.dialogPreviewStream,
  dialogPreviewStreamHistory: (state) => state.dialogPreviewStreamHistory,
  streamActiveItem: (state) => state.streamActiveItem,
}
