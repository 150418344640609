import * as VueDeepSet from "vue-deepset"
import _ from "lodash"
import {
  STATUS_SERVER_ACTIVE,
  STATUS_SERVER_INACTIVE,
  STATUS_SERVER_UNKNOWN,
} from "@/common/signal.service"

export default {
  SAVE_DEBUG_SIGNAL_SERVERS_LIST(state, data) {
    state.debugSignalServersList = data
  },
  SAVE_DEBUG_SIGNAL_SERVERS_LIST_COUNT(state, data) {
    state.debugSignalServersListCount = data
  },
  SET_STATUS_SIGNAL(state, data) {
    let findedIndex = state.debugSignalServersList.findIndex(
      (s) => s._key === data.key
    )
    if (findedIndex !== -1) {
      VueDeepSet.vueSet(
        state.debugSignalServersList[findedIndex],
        ["status"],
        data.status
      )
    }
  },
  SET_CONFIGS_SIGNAL(state, data) {
    let findedIndex = state.debugSignalServersList.findIndex(
      (s) => s._key === data.key
    )
    if (findedIndex !== -1) {
      VueDeepSet.vueSet(
        state.debugSignalServersList[findedIndex],
        ["configs"],
        data.configs
      )
    }
  },
  SAVE_DEBUG_TURN_SERVERS_LIST(state, data) {
    state.debugTurnServersList = data
  },
  SAVE_DEBUG_TURN_SERVERS_LIST_COUNT(state, data) {
    state.debugTurnServersListCount = data
  },
  RESET_TURNS_API_DEBUG_INFO(state) {
    state.turnApiDebugInfo = []
  },
  SAVE_TURNS_API_DEBUG_INFO(state, data) {
    if (data && Array.isArray(data)) {
      state.turnApiDebugInfo = _.union(state.turnApiDebugInfo, data)
    }
  },
  PARSE_TURNS_API_DEBUG_INFO(state) {
    state.debugTurnServersList.forEach((turn, index) => {
      let debugsArrTurn = state.turnApiDebugInfo.filter(
        (s) => s.data.hostname === turn.data.hostname
      )
      if (
        debugsArrTurn &&
        Array.isArray(debugsArrTurn) &&
        debugsArrTurn.length >= 1
      ) {
        let isAlive =
          debugsArrTurn.length === 1
            ? debugsArrTurn[0].isAlive
            : debugsArrTurn.reduce(
                (accumulator, currentValue) =>
                  !!(accumulator && currentValue.isAlive),
                true
              )
        VueDeepSet.vueSet(
          state.debugTurnServersList[index],
          ["status"],
          isAlive ? STATUS_SERVER_ACTIVE : STATUS_SERVER_INACTIVE
        )
      } else {
        VueDeepSet.vueSet(
          state.debugTurnServersList[index],
          ["status"],
          STATUS_SERVER_UNKNOWN
        )
      }
    })
  },
  SAVE_DEBUG_JANUS_SERVERS_LIST(state, data) {
    state.debugJanusServersList = data
  },
  SAVE_DEBUG_JANUS_SERVERS_LIST_COUNT(state, data) {
    state.debugJanusServersListCount = data
  },
  RESET_JANUSES_API_DEBUG_INFO(state) {
    state.janusApiDebugInfo = []
  },
  SAVE_JANUSES_API_DEBUG_INFO(state, data) {
    if (data && Array.isArray(data)) {
      state.janusApiDebugInfo = _.union(state.janusApiDebugInfo, data)
    }
    if (data && typeof data === "object") {
      for (let janusGroup in data) {
        state.janusApiDebugInfo = _.union(
          state.janusApiDebugInfo,
          data[janusGroup]
        )
      }
    }
  },
  PARSE_JANUSES_API_DEBUG_INFO(state) {
    state.debugJanusServersList.forEach((janus, index) => {
      // @todo maybe on janus data change field for host (now here is full url i.e. https://j01-eu.arhtengine.com:443)
      let debugsArrJanus = state.janusApiDebugInfo.filter(
        (s) => s.host === janus.data.url
      )
      if (
        debugsArrJanus &&
        Array.isArray(debugsArrJanus) &&
        debugsArrJanus.length >= 1
      ) {
        let isAlive =
          debugsArrJanus.length === 1
            ? debugsArrJanus[0].healthy
            : debugsArrJanus.reduce(
                (accumulator, currentValue) =>
                  !!(accumulator && currentValue.healthy),
                true
              )
        VueDeepSet.vueSet(
          state.debugJanusServersList[index],
          ["status"],
          isAlive ? STATUS_SERVER_ACTIVE : STATUS_SERVER_INACTIVE
        )
      } else {
        VueDeepSet.vueSet(
          state.debugJanusServersList[index],
          ["status"],
          STATUS_SERVER_UNKNOWN
        )
      }

      function infoValue(field) {
        if (
          debugsArrJanus &&
          Array.isArray(debugsArrJanus) &&
          debugsArrJanus.length >= 1
        ) {
          return debugsArrJanus.length === 1
            ? debugsArrJanus[0][field]
            : debugsArrJanus.reduce((accumulator, currentValue) => {
                if (
                  Object.prototype.hasOwnProperty.call(accumulator, field) &&
                  accumulator[field]
                ) {
                  return accumulator[field]
                } else if (
                  Object.prototype.hasOwnProperty.call(currentValue, field) &&
                  currentValue[field]
                ) {
                  return currentValue[field]
                }
                return 0
              })
        }
        return undefined
      }
      // Save additional info for Janus
      VueDeepSet.vueSet(state.debugJanusServersList[index], ["info"], {
        loadUnits: infoValue("loadUnits"),
        cpuAverage: infoValue("cpuAverage"),
        memUsed: infoValue("memUsed"),
        netUsed: infoValue("netUsed"),
      })
    })
  },
}
