import Vue from "vue"
import Vuetify from "vuetify/lib"
// import 'vuetify/src/stylus/app.styl'
// import 'vuetify/dist/vuetify.min.css'
// import '@fortawesome/fontawesome-free/css/fontawesome.css'
import "@fortawesome/fontawesome-free/css/all.css"
// import colors from "vuetify/es5/util/colors"
import "@mdi/font/css/materialdesignicons.css"
import "@/assets/scss/vuetify/overrides.scss"
import SvgActive from "../components/icons/SvgActive"
import SvgReady from "../components/icons/SvgReady"

Vue.use(Vuetify)

const theme = {
  primary: "#1e88e5", // change header color from here || "#1e88e6", "#21c1d6", "#fc4b6c", "#563dea", "#9C27b0", "#ff9800"
  info: "#1e88e5",
  success: "#21c1d6",
  accent: "#fc4b6c",
  default: "#563dea",
}

export default new Vuetify({
  treeShake: true,
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
    dark: true, // If you want to set dark theme then dark:true else set to false
  },
  rtl: false, // If you want to set rtl theme then rtl:true else set to false
  icons: {
    values: {
      iconfont: "mdi",
      active: {
        component: SvgActive,
        props: {
          name: "fill",
        },
      },
      ready: {
        component: SvgReady,
        props: {
          name: "fill",
        },
      },
    },
  },
})
