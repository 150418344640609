import _ from "lodash"

export const parsePlaylistsData = (playlistsData) => {
  let playlists = []
  try {
    playlists = JSON.parse(playlistsData)
  } catch (err) {
    playlists = playlistsData
  }

  let haveGroups = false
  _.forEach(playlists, function (value, key) {
    let groupsPlaylists = {}
    let parsedItems = []
    _.forEach(value.items, function (value2, key2) {
      let group = value["groups"][key2]
      // if (group == "") {
      //     group = "default";
      // }

      if (_.isUndefined(playlists[key]["groupsData"])) {
        playlists[key]["groupsData"] = []
      }

      let itemObj = typeof value2 === "object" ? value2 : { name: value2 }

      let itemParsed = {
        ...itemObj,
        ...{
          pos: `${key2}`,
          loading: false,
        },
      }

      if (group != "") {
        if (!_.has(groupsPlaylists, group)) {
          groupsPlaylists[group] = []
        }

        groupsPlaylists[group].push(itemParsed)

        // groups data
        let groupDataIndex = _.findIndex(
          playlists[key]["groupsData"],
          function (o) {
            return o.group == group
          }
        )
        let findedGroupDataObj = null
        if (groupDataIndex == -1) {
          findedGroupDataObj = { group: group, items: [] }
          groupDataIndex =
            playlists[key]["groupsData"].push(findedGroupDataObj) - 1
        } else {
          findedGroupDataObj = playlists[key]["groupsData"][groupDataIndex]
        }
        findedGroupDataObj["items"].push(itemParsed)
        playlists[key]["groupsData"][groupDataIndex] = findedGroupDataObj

        if (!_.isEmpty(findedGroupDataObj)) {
          haveGroups = true
        }
      }
      parsedItems.push(itemParsed)
    })
    // console.log('playlists---', playlists);
    playlists[key]["groupsPlaylists"] = groupsPlaylists
    playlists[key]["parsedItems"] = parsedItems
  })
  // console.log('playlists----------', playlists);
  return [playlists, haveGroups]
}

export const parseUnattendedPresetEngines = (allEngines) => {
  let data = {}
  _.forEach(allEngines, function (val, key) {
    if (_.has(val.preset, "pk")) {
      _.setWith(
        data,
        "presets.[" + val.preset.pk + "].preset",
        val.preset,
        Object
      )
      _.setWith(
        data,
        "presets.[" + val.preset.pk + "].items." + key,
        val,
        Object
      )

      // if (_.isUndefined(data['presets'])) {
      //     data['presets'] = {};
      // }
      // if (_.isUndefined(data['presets'][val.preset.pk])) {
      //     data['presets'][val.preset.pk] = {};
      // }
      // if (_.isUndefined(data['presets'][val.preset.pk])) {
      //     data['presets'][val.preset.pk] = {};
      // }
      // data['presets'][val.preset.pk][key] = val;
    } else {
      _.setWith(data, "nopreset.items." + key, val, Object)
    }
  })
  return data
}

export const parseUnattendedTreeviewItem = (val, type) => {
  let item = {
    id: val._id,
    _key: val._key,
    name: val.name,
    treetype: type,
    is_online: val.is_online,
  }
  if (type == "preset") {
    item["children"] = []
    _.forEach(val._engines, function (val2) {
      item.children.push({
        id: val2._id,
        _key: val2._key,
        name: val2.name,
        treetype: "engine",
        is_online: val2.is_online,
      })
    })
  }
  return item
}

export const parseUnattendedPresetTreeviewEngines = (allEngines) => {
  let data = []
  _.forEach(allEngines["presets"], function (val) {
    data.push(parseUnattendedTreeviewItem(val, "preset"))
  })
  _.forEach(allEngines["engines"], function (val) {
    data.push(parseUnattendedTreeviewItem(val, "engine"))
  })
  return data
}

export const treeDeepFindById = (collection, id, pathString = "") => {
  var response = undefined
  let presetKey = null
  _.forEach(collection, function (val, key) {
    pathString = ""
    presetKey = null
    if (val.id == id) {
      response = val
      pathString = pathString + `[${key}]`
    } else if (!_.isUndefined(val.children)) {
      let result = treeDeepFindById(val.children, id, pathString)
      presetKey = val.id
      response = result.response
      pathString = pathString + `[${key}]children` + result.pathString
    }
    if (response) return false
  })
  return { response: response, presetKey: presetKey, pathString: pathString }
}

export const saveUnattedendSingle = (collection, engineData) => {
  let id, response, presetKey
  _.forEach(collection, function (val, key) {
    if (val.id == id && val.treetype == "engine") {
      collection[key] = engineData
    } else if (!_.isUndefined(val.children)) {
      //let result = treeDeepFindById(val.children, engineData)
    }
    if (response) return false
  })
  return { response: response, presetKey: presetKey }
}

export const treeDeepFindConnectionEnginesList = (collection, id) => {
  let engines = []
  let finded = treeDeepFindById(collection, id)
  if (finded.presetKey) {
    let presetItem = _.find(collection, { id: finded.presetKey })
    engines =
      _.filter(presetItem.children, function (o) {
        return o.id != id
      }) || []
  } else {
    engines = _.filter(collection, function (o) {
      return o.treetype == "engine" && o.id != id
    })
  }
  return engines
}

export default {
  parsePlaylistsData,
  parseUnattendedTreeviewItem,
  parseUnattendedPresetEngines,
  parseUnattendedPresetTreeviewEngines,
  treeDeepFindById,
  treeDeepFindConnectionEnginesList,
  saveUnattedendSingle,
}
