// let PORT_APPEND = process.env.NODE_ENV == 'development' ? ':8000' : '';
import axios from "axios"

const GRAYLOG_DEV_URL = "https://graylog.redox-dev.com:12202/gelf"
const GRAYLOG_PROD_URL = "https://graylog.arhtengine.com/prod-gelf"
const GRAYLOG_PROD_AUTH_TOKEN =
  "YXBwLmFyaHRlbmdpbmUuY29tLmdlbGYuaHR0cDpiSGUyQUpTZXFHVUFHUURnZ0padDRj"

let domain = process.env.VUE_APP_ARHT_WEB_DOMAIN
let webPort =
  process.env.VUE_APP_ARHT_WEB_PORT == "80"
    ? ""
    : ":" + process.env.VUE_APP_ARHT_WEB_PORT
if (
  window.location.hostname !== process.env.VUE_APP_ARHT_WEB_DOMAIN &&
  window.location.hostname !== "localhost"
) {
  // case when we use proxy
  domain = window.location.hostname
  webPort = window.location.port == "80" ? "" : `:${window.location.port}`
}

export const API_URL =
  process.env.VUE_APP_ARHT_WEB_PROTOCOL + domain + webPort + "/api"

export const siteApiInstance = axios.create({
  headers: { "Content-Type": "application/json" },
})

export const formApiInstance = axios.create({
  headers: { "Content-Type": "multipart/form-data" },
})

export const signalApiInstance = axios.create({
  timeout: 8000,
  headers: { "Content-Type": "application/json" },
})

export const graylogDevApiInstance = axios.create({
  baseURL: GRAYLOG_DEV_URL,
  headers: {
    "Content-Type": "application/json",
  },
})

export const CDN_URL = "https://cdn.arhtengine.com"

export const graylogProdApiInstance = axios.create({
  baseURL: GRAYLOG_PROD_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Basic ${GRAYLOG_PROD_AUTH_TOKEN}`,
  },
})

export default {
  API_URL,
  siteApiInstance,
  formApiInstance,
  signalApiInstance,
  graylogProdApiInstance,
  graylogDevApiInstance,
  CDN_URL,
}
