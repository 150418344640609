export default {
  supportLink: (state) => state.supportLink,
  signalServersList: (state) => state.signalServersList,
  signalServersListCount: (state) => state.signalServersListCount,
  pagesList: (state) => state.pagesList,
  pagesListCount: (state) => state.pagesListCount,
  activePage: (state) => state.activePage,
  turnServersList: (state) => state.turnServersList,
  turnServersListCount: (state) => state.turnServersListCount,
}
