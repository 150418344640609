import Vue from "vue"
import Vuex from "vuex"

import appSettings from "./appSettings/store"
import auth from "./auth/store"
import moduleCommon from "./common/store"
import moduleUnattended from "./engines/unattended/store"
import moduleEngineCommon from "./engines/common/store"
import moduleEnginePresets from "./engines/presets/store"
import moduleEngineRequests from "./engines/requests/store"
import moduleRemoteControlEnginePlaylists from "./engines/remote-control/store"
import moduleWebengines from "./engines/webengines/store"
import moduleEngineSpeedTest from "./engines/speed-test/store"
import moduleSettings from "./settings/store"
import moduleGroups from "./groups/store"
import moduleRoles from "./roles/store"
import moduleUsers from "./users/store"
import moduleInvites from "./invites/store"
import moduleDebug from "./debug/store"
import moduleStore from "./store/store"
import moduleStreams from "./engines/streams/store"
import moduleSupport from "./support/store"

import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"

var ls = new SecureLS({ isCompression: false })
import abilityPlugin from "./ability"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    appSettings,
    auth,
    mUnattended: moduleUnattended,
    moduleCommon,
    moduleEngineCommon,
    moduleEnginePresets,
    moduleEngineRequests,
    moduleRemoteControlEnginePlaylists,
    moduleWebengines,
    moduleEngineSpeedTest,
    moduleSettings,
    moduleGroups,
    moduleRoles,
    moduleUsers,
    moduleInvites,
    moduleStore,
    moduleDebug,
    moduleStreams,
    moduleSupport,
  },
  plugins: [
    createPersistedState({
      paths: [
        "auth.user",
        "auth.isAuthenticated",
        "auth.userPermissions",
        "moduleWebengines.scannedConstraintsList",
        "moduleWebengines.unchangedScannedConstraintsList",
        "moduleWebengines.rawWebrtcSources",
        "moduleWebengines.listWebrtcVideoSources",
        "moduleWebengines.listWebrtcAudioSources",
        "moduleWebengines.parsedListWebrtcVideoSources",
        "moduleWebengines.parsedListWebrtcAudioSources",
        "moduleWebengines.doStreamRestart",
        "moduleWebengines.useJanus",
        "moduleStreams.streamsConfigs",
        "moduleWebengines.excludeSenderParams",
        "moduleGroups.companyInfo",
        "moduleStore.eventFilters",
      ],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
    abilityPlugin,
  ],
})
