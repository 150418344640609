import Vue from "vue"
import Router from "vue-router"
import store from "@/store"
import _ from "lodash"

const Home = () => import("../views/Home.vue")
const Login = () => import("../views/common/Login.vue")
const LoginMicrosoft = () => import("../views/common/LoginMicrosoft.vue")
const SignUpFromHash = () => import("../views/common/SignUpFromHash.vue")
const SignUpMicrosoft = () => import("../views/common/SignUpMicrosoft.vue")
const ResetPassword = () => import("../views/common/ResetPassword.vue")
const ChangePassword = () => import("../views/common/ChangePassword.vue")
const ConfirmResetPassword = () =>
  import("../views/common/ConfirmResetPassword.vue")
const Logout = () => import("../views/common/Logout.vue")
const NotFoundComponent = () => import("../views/common/NotFoundComponent.vue")
const CompanyUsersList = () => import("../views/company/users/UsersList.vue")
const CompanyProfile = () => import("../views/company/CompanyProfile.vue")
const CompanyGroupsList = () => import("../views/company/groups/GroupsList.vue")
const CompanyRolesList = () => import("../views/company/roles/RolesList.vue")
const CompanyRoleCrud = () => import("../views/company/roles/RoleCrud.vue")
const UserProfile = () => import("../views/company/UserProfile.vue")
const EnginesUnattended = () =>
  import("../views/engines/unattended/Unattended.vue")
const EnginesList = () => import("../views/engines/common/EnginesList.vue")
const StreamsList = () => import("../views/engines/streams/StreamsList")
const StreamsListActive = () =>
  import("../views/engines/streams/StreamsListActive")
const StreamsListHistory = () =>
  import("../views/engines/streams/StreamsListHistory")
const EnginePresetsList = () =>
  import("../views/engines/presets/EnginePresetsList.vue")
const EnginesRegisterRequests = () =>
  import("../views/engines/requests/RegisterRequests.vue")
const EnginesPingTurns = () => import("../views/engines/PingTurns.vue")
const WebEnginesList = () =>
  import("../views/engines/webengines/WebEnginesList.vue")
const WebEngineStreaming = () =>
  import("../views/engines/webengines/WebEngineStreaming.vue")
const WebEngineDialog = () =>
  import("../views/engines/webengines/WebEngineDialog.vue")
const EnginesSpeedTest = () =>
  import("../views/engines/speed-test/SpeedTestList.vue")
const EnginesSpeedTestHistory = () =>
  import("../views/engines/speed-test/EnginesSpeedTestHistory.vue")
const SpeedTestDialog = () =>
  import("../views/engines/speed-test/SpeedTestDialog.vue")
const SpeedTestRun = () =>
  import("../views/engines/speed-test/RunTestBlock.vue")
const EngineRemoteControlConnectionsList = () =>
  import("../views/engines/remote-control/ConnectionsList.vue")
const ActiveEngineRCConnectionsList = () =>
  import("../views/engines/remote-control/ConnectionsListActive.vue")
const HistoryEngineRCConnectionsList = () =>
  import("../views/engines/remote-control/ConnectionsListHistory.vue")
const EngineRemoteControlConnectionPlaylists = () =>
  import("../views/engines/remote-control/ConnectionPlaylists.vue")
const SignalServersList = () =>
  import("../views/settings/signal-servers/SignalServersList.vue")
const TurnServersList = () =>
  import("../views/settings/turn-servers/TurnServersList.vue")
const JanusServersList = () =>
  import("../views/settings/janus-servers/JanusServersList.vue")
const VersionMessagesList = () =>
  import("../views/settings/version-messages/VersionMessagesList.vue")
const SignalServersDebug = () =>
  import("../views/debug/signal-servers/SignalServersList.vue")
const TurnServersDebug = () =>
  import("../views/debug/turn-servers/TurnServersList.vue")
const JanusServersDebug = () =>
  import("../views/debug/janus-servers/JanusServersList.vue")
const KioskRogersMini = () =>
  import("../views/engines/remote-control/kiosk/rogers/Mini")
const KioskNJ = () =>
  import("../views/engines/remote-control/kiosk/nj/Main.vue")
const LayoutDefault = () => import("../components/layouts/LayoutDefault.vue")
const LayoutLogin = () => import("../components/layouts/LayoutLogin.vue")
const LayoutKioskNJ = () => import("../components/layouts/LayoutKioskNJ.vue")
const LayoutKioskRogers = () =>
  import("../components/layouts/LayoutKioskRogers.vue")
const InvitesList = () => import("../views/company/invites/InvitesList")
const BundlesRegisteredList = () =>
  import("../views/store/registry/BundlesRegisteredList.vue")
const OrderRegisteredCrud = () =>
  import("../views/store/registry/OrderRegisteredCrud.vue")
const BundleCrud = () => import("../views/store/bundles/BundleCrud.vue")
const BundlesList = () => import("../views/store/bundles/BundlesList.vue")
const ClientBundles = () =>
  import("../views/store/bundles/client/ClientBundles.vue")
const BuyClientBundle = () =>
  import("../views/store/bundles/client/BuyClientBundle.vue")
const EventsList = () => import("../views/store/events/EventsList.vue")
const EventControlDialog = () =>
  import("../views/store/events/filters/location/EventFilterControlDialog")
const EventInviteHandle = () =>
  import("../views/store/events/EventInviteHandle.vue")
const EventCrud = () => import("../views/store/events/EventCrud.vue")
const EventSettings = () => import("../views/store/events/EventSettings.vue")
const EventControl = () =>
  import("../views/store/events/control/EventControl.vue")
const EventMembersRolesList = () =>
  import("../views/store/event_members_roles/EventMembersRolesList.vue")
const EventMemberRoleCrud = () =>
  import("../views/store/event_members_roles/EventMemberRoleCrud.vue")
const EngineLocationsList = () =>
  import("../views/store/locations/EngineLocationsList.vue")
const EngineLocationCrud = () =>
  import("../views/store/locations/EngineLocationCrud.vue")
const DealsList = () => import("../views/store/deals/DealsList.vue")
const DealCrud = () => import("../views/store/deals/DealCrud.vue")
const BundleConnectionsList = () =>
  import("../views/store/events/BundleConnectionsList.vue")
const LicensesList = () => import("../views/store/licenses/LicensesList.vue")
const LicenseCrud = () => import("../views/store/licenses/LicenseCrud")
const PagesList = () => import("../views/settings/pages/PagesList")
const PageCrud = () => import("../views/settings/pages/PageCrud")
const DownloadsPage = () => import("../views/support/downloads/DownloadsPage")
// const Billing = () => import("../views/store/Billing.vue")
const RemoteProducerStreamPreview = () =>
  import("../views/engines/remote-producer/StreamPreview")
const ImageDatachannelDemo = () =>
  import("../views/engines/remote-producer/ImageDatachannelDemo")

Vue.use(Router)

const hydrateProps = (route, props) => {
  Object.assign(route.meta, { props })
}

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: LayoutDefault,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          name: "home",
          component: Home,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/company/users",
          name: "company_users",
          component: CompanyUsersList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/company/invites",
          name: "company_invites",
          component: InvitesList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/company/list",
          name: "company_groups",
          component: CompanyGroupsList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/company/roles",
          name: "company_roles",
          component: CompanyRolesList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/company/role/create",
          name: "company_role_create",
          component: CompanyRoleCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/company/role/edit/:editedIndex",
          name: "company_role_edit",
          component: CompanyRoleCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/profile",
          name: "profile",
          component: UserProfile,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/company/profile",
          name: "company_profile",
          component: CompanyProfile,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/engines/unattended",
          name: "engines_unattended",
          component: EnginesUnattended,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/engines/register-requests",
          name: "engines_register_requests",
          component: EnginesRegisterRequests,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/engines/ping-turns",
          name: "engines_ping_turns",
          component: EnginesPingTurns,
          meta: {
            requiresAuth: true,
          },
        },
        // Web Engine
        {
          path: "/engines/webengines",
          name: "engines_webengine",
          component: WebEnginesList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/engines/webengine/create",
          name: "engines_webengine_create",
          component: WebEngineDialog,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/engines/webengine/edit/:editedIndex",
          name: "engines_webengine_edit",
          component: WebEngineDialog,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/engines/webengine/streaming/:editedIndex",
          name: "engines_webengine_streaming",
          component: WebEngineStreaming,
          meta: {
            requiresAuth: true,
          },
          async beforeEnter(to, from, next) {
            return new Promise((resolve) => {
              store.dispatch("webenginesResetData").then(() => {
                hydrateProps(to, { editedIndex: to.params.editedIndex })
                resolve({ editedIndex: to.params.editedIndex })
                next()
              })
            })
          },
          props: (route) => ({
            editedIndex: route.meta.props.editedIndex,
          }),
        },
        {
          path: "/webengine/:link_hash",
          name: "engines_webengine_hash",
          component: WebEngineStreaming,
          meta: {
            requiresAuth: false,
          },
          async beforeEnter(to, from, next) {
            return new Promise((resolve) => {
              let pLogout = Promise.resolve()
              if (store.getters.isAuthenticated) {
                pLogout = store.dispatch("logout")
              }
              pLogout.then(() => {
                store.dispatch("webenginesResetData").then(() => {
                  store
                    .dispatch("webenginesHashInfo", to.params.link_hash)
                    .then((result) => {
                      let data = _.get(result, "data.data")
                      store
                        .dispatch("loginByAccessTokenAction", {
                          access_token: data.access_token,
                        })
                        .then(() => {
                          store.dispatch("checkAuth").then(() => {
                            store
                              .dispatch("webengineSaveSingleByHash", {
                                ...{
                                  signal_server_url: data.signal_server_url,
                                  signal_server_room: data.room,
                                },
                                ...data.web_engine,
                              })
                              .then(() => {
                                resolve({
                                  link_hash: to.params.link_hash,
                                  editedIndex: data.web_engine._key,
                                })
                                hydrateProps(to, {
                                  link_hash: to.params.link_hash,
                                  editedIndex: data.web_engine._key,
                                })
                                next()
                              })
                          })
                        })
                    })
                    .catch((err) => {
                      console.log("error", err)
                    })
                })
              })
            })
          },
          props: (route) => ({
            link_hash: route.meta.props.link_hash,
            editedIndex: route.meta.props.editedIndex,
          }),
        },
        // Speed test
        {
          path: "/engines/speed-test",
          name: "engines_speed_test",
          component: EnginesSpeedTest,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/engines/speed-test/create",
          name: "engines_speed_test_create",
          component: SpeedTestDialog,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/engines/speed-test/history/:room",
          name: "engines_speed_test_history",
          component: EnginesSpeedTestHistory,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/engines/speed-test/edit/:editedIndex",
          name: "engines_speed_test_edit",
          component: SpeedTestDialog,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/engines/speed-test/run/:editedIndex",
          name: "engines_speed_test_run",
          component: SpeedTestRun,
          meta: {
            requiresAuth: true,
          },
          async beforeEnter(to, from, next) {
            return new Promise((resolve) => {
              store.dispatch("speedTestResetData").then(() => {
                hydrateProps(to, { editedIndex: to.params.editedIndex })
                resolve({ editedIndex: to.params.editedIndex })
                next()
              })
            })
          },
          props: (route) => ({
            editedIndex: route.meta.props.editedIndex,
          }),
        },
        {
          path: "/engines/speed-test/:link_hash",
          name: "engines_speed_test_hash",
          component: SpeedTestRun,
          meta: {
            requiresAuth: true,
          },
          async beforeEnter(to, from, next) {
            return new Promise((resolve) => {
              let pLogout = Promise.resolve()
              if (store.getters.isAuthenticated) {
                pLogout = store.dispatch("logout")
              }
              pLogout.then(() => {
                store.dispatch("speedTestResetData").then(() => {
                  SpeedTestsService.getHashInfo(to.params.link_hash)
                    .then((result) => {
                      let data = _.get(result, "data.data")
                      store
                        .dispatch("loginByAccessTokenAction", {
                          access_token: data.access_token,
                        })
                        .then(() => {
                          store.dispatch("checkAuth").then(() => {
                            let stData = data.room
                            store
                              .dispatch("speedTestSaveSingleByHash", stData)
                              .then(() => {
                                resolve({
                                  link_hash: to.params.link_hash,
                                  editedIndex: data.room._key,
                                })
                                hydrateProps(to, {
                                  link_hash: to.params.link_hash,
                                  editedIndex: data.room._key,
                                })
                                next()
                              })
                          })
                        })
                      // let stData = data.room;
                      // let p2 = store.dispatch('speedTestSaveSingleByHash', stData);
                      // Promise.all([p1, p2]).then(() => {
                      //   store.dispatch('checkAuth').then(() => {
                      //     resolve({link_hash: to.params.link_hash, editedIndex: data.room._key});
                      //     hydrateProps(to, {link_hash: to.params.link_hash, editedIndex: data.room._key});
                      //     next();
                      //   });
                      // });
                    })
                    .catch(() => {
                      store
                        .dispatch(
                          "speedTestErrors",
                          "Speed test could not be started - wrong or outdated link."
                        )
                        .then(() => {
                          hydrateProps(to, {
                            link_hash: to.params.link_hash,
                            editedIndex: null,
                          })
                          next()
                        })
                    })
                })
              })
            })
          },
          props: (route) => ({
            link_hash: route.meta.props.link_hash,
            editedIndex: route.meta.props.editedIndex,
          }),
        },
        {
          path: "/engines/list",
          name: "engines_list",
          component: EnginesList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/engines/streams",
          name: "engine-streams-list",
          component: StreamsList,
          meta: {
            requiresAuth: true,
          },
          props: true,
          children: [
            {
              path: "active",
              component: StreamsListActive,
              name: "engine-streams-list-active",
              props: true,
            },
            {
              path: "history",
              component: StreamsListHistory,
              name: "engine-streams-list-history",
              props: true,
            },
          ],
        },
        {
          path: "/engines/presets",
          name: "engines_presets",
          component: EnginePresetsList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/engines/remote-control",
          name: "engines_rc_connections",
          component: EngineRemoteControlConnectionsList,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "active",
              component: ActiveEngineRCConnectionsList,
              name: "active-engines-rc-connections",
              props: true,
            },
            {
              path: "history",
              component: HistoryEngineRCConnectionsList,
              name: "history-engines-rc-connections",
              props: true,
            },
          ],
        },
        {
          path: "/engines/remote-control/engine/:engine_name/project/:project/:rc_key",
          name: "engine_rc_connection",
          component: EngineRemoteControlConnectionPlaylists,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/engines/remote-producer-stream-preview",
          name: "remote_producer_stream_preview",
          component: RemoteProducerStreamPreview,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/engines/image-datachannel-demo",
          name: "remote_producer_image_datacannel_demo",
          component: ImageDatachannelDemo,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/settings/signal-servers",
          name: "signal_servers",
          component: SignalServersList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/settings/turn-servers",
          name: "turn_servers",
          component: TurnServersList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/settings/janus-servers",
          name: "janus_servers",
          component: JanusServersList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/settings/version-messages",
          name: "asp_version_messages",
          component: VersionMessagesList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/settings/pages",
          name: "pages-list",
          component: PagesList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/settings/page/create",
          name: "page-create",
          component: PageCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/settings/page/edit/:editedIndex",
          name: "page-edit",
          component: PageCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/debug/signal-servers",
          name: "debug-signal-servers",
          component: SignalServersDebug,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/debug/turn-servers",
          name: "debug-turn-servers",
          component: TurnServersDebug,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/debug/janus-servers",
          name: "debug-janus-servers",
          component: JanusServersDebug,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/store/bundles-list",
          name: "store-bundles-list",
          component: BundlesList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/store/bundles",
          name: "store-bundles-client-list",
          component: ClientBundles,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/store/bundle/buy/:bundleIndex",
          name: "store-bundle-client-buy",
          component: BuyClientBundle,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/store/bundle/create",
          name: "store-bundle-create",
          component: BundleCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/store/bundle/edit/:editedIndex",
          name: "store-bundle-edit",
          component: BundleCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/store/bundles/registry",
          name: "store-bundles-registered-list",
          component: BundlesRegisteredList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/store/bundle/registry/create",
          name: "store-bundle-registered-create",
          component: OrderRegisteredCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/store/bundle/registry/edit/:editedIndex",
          name: "store-bundle-registered-edit",
          component: OrderRegisteredCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/store/events",
          name: "store-events-list",
          component: EventsList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/store/event/control/:editedIndex",
          name: "store-event-control",
          component: EventControl,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/events/:hash",
          name: "store-event-invite",
          component: EventInviteHandle,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/store/event/create",
          component: EventCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
          children: [
            {
              path: "",
              component: EventSettings,
              name: "store-bundle-event-create-settings",
              props: true,
            },
            {
              path: "connections",
              component: BundleConnectionsList,
              name: "store-bundle-event-create-connections",
              props: true,
            },
          ],
        },
        {
          path: "/store/event/edit/:editedIndex",
          component: EventCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
          children: [
            {
              path: "",
              component: EventSettings,
              name: "store-bundle-event-edit-settings",
              props: true,
            },
            {
              path: "connections",
              component: BundleConnectionsList,
              name: "store-bundle-event-edit-connections",
              props: true,
            },
          ],
        },
        {
          path: "/store/event/playlists/:event_key",
          name: "store-event-filter-control",
          component: EventControlDialog,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/store/event/roles",
          name: "event-members-roles-list",
          component: EventMembersRolesList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/store/event/role/create",
          name: "event-member-role-create",
          component: EventMemberRoleCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/store/event/role/edit/:editedIndex",
          name: "event-member-role-edit",
          component: EventMemberRoleCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/store/locations",
          name: "engine-locations-list",
          component: EngineLocationsList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/store/location/create",
          name: "engine-location-create",
          component: EngineLocationCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/store/location/edit/:editedIndex",
          name: "engine-location-edit",
          component: EngineLocationCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        // {
        //   path: '/store/billing',
        //   name: 'billing',
        //   component: Billing,
        //   meta: {
        //     requiresAuth: true
        //   }
        // },
        {
          path: "/store/deals",
          name: "store-deals-list",
          component: DealsList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/store/deal/create",
          name: "store-deal-create",
          component: DealCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/store/deal/edit/:editedIndex",
          name: "store-deal-edit",
          component: DealCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/store/licenses",
          name: "store-licenses-list",
          component: LicensesList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/store/license/create",
          name: "store-license-create",
          component: LicenseCrud,
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/support/downloads",
          name: "downloads-page",
          component: DownloadsPage,
          meta: {
            requiresAuth: true,
          },
        },
        // {
        //     path: 'kiosk-rogers',
        //     name: 'kiosk-rogers',
        //     component: KioskRogers,
        //     meta: {
        //         requiresAuth: true
        //     }
        // },
      ],
    },

    {
      path: "/",
      name: "root_layout_4k",
      component: LayoutDefault,
      children: [
        {
          path: "/events/access/:link_hash",
          name: "events_access_hash",
          component: EventsList,
          meta: {
            requiresAuth: false,
          },
          async beforeEnter(to, from, next) {
            return new Promise(() => {
              let pLogout = Promise.resolve()
              if (store.getters.isAuthenticated) {
                pLogout = store.dispatch("logout")
              }
              pLogout.then(() => {
                store.dispatch("resetEventsList").then(() => {
                  store
                    .dispatch("eventsHashInfo", to.params.link_hash)
                    .then((result) => {
                      let hashInfo = _.get(result, "data.data")
                      store
                        .dispatch("loginByAccessTokenAction", {
                          access_token: hashInfo.access_token,
                        })
                        .then(() => {
                          store.dispatch("checkAuth").then(() => {
                            store
                              .dispatch(
                                "setEventEngineFilter",
                                hashInfo?.engine?._key
                              )
                              .then(() => {
                                hydrateProps(to, {
                                  link_hash: to.params.link_hash,
                                })
                                next()
                              })
                          })
                        })
                    })
                    .catch((err) => {
                      console.log("error", err)
                    })
                })
              })
            })
          },
          props: (route) => ({
            link_hash: route.meta.props.link_hash,
          }),
        },
        {
          path: "/store/event/playlists/:event_key/:link_hash",
          name: "store-event-filter-control-hash",
          component: EventControlDialog,
          meta: {
            requiresAuth: false,
          },
          async beforeEnter(to, from, next) {
            store
              .dispatch("eventsHashInfo", to.params.link_hash)
              .then(() => {
                hydrateProps(to, {
                  link_hash: to.params.link_hash,
                  event_key: to.params.event_key,
                })
                next()
              })
              .catch((err) => {
                console.log("error", err)
              })
          },
          props: (route) => ({
            link_hash: route.meta.props.link_hash,
            event_key: route.meta.props.event_key,
          }),
        },
      ],
    },

    {
      path: "/",
      name: "root_login",
      component: LayoutLogin,
      children: [
        {
          path: "login",
          name: "login",
          component: Login,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/login/microsoft/",
          name: "login_microsoft",
          component: LoginMicrosoft,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/auth/password/reset",
          name: "reset_password",
          component: ResetPassword,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/auth/password/reset/:reset_hash",
          name: "change_password",
          component: ChangePassword,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/auth/password/reset/:reset_hash/confirm",
          name: "reset_password_confirm",
          component: ConfirmResetPassword,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/users/invites/microsoft/",
          name: "sign_up_microsoft",
          component: SignUpMicrosoft,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/users/invites/:link_hash",
          name: "sign_up_from_hash",
          component: SignUpFromHash,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "/logout",
      name: "logout",
      component: Logout,
    },
    {
      path: "/engines/remote-control/kiosk/NJ",
      name: "root_kiosk_nj",
      component: LayoutKioskNJ,
      children: [
        {
          path: "/engines/remote-control/kiosk/NJ/:link_hash",
          name: "kiosk_nj",
          component: KioskNJ,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "/engines/remote-control/kiosk/ROGERS",
      name: "root_kiosk_rogers",
      component: LayoutKioskRogers,
      children: [
        {
          path: "/engines/remote-control/kiosk/ROGERS/:link_hash",
          name: "kiosk_rogers",
          component: KioskRogersMini,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "*",
      name: "root_not_found_component",
      component: LayoutDefault,
      children: [{ path: "*", component: NotFoundComponent }],
      meta: {
        requiresAuth: true,
      },
    },
  ],
})

import {
  RemoteControlPlaylistsEngineService,
  SpeedTestsService,
} from "@/common/api/api.service"

import { saveHashLogin } from "@/common/hashlogin.service"

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  store.dispatch("checkAuth").then(() => {
    store.dispatch("saveLoaderAction", false)
    // @todo maybe other logic to handle to.matched param
    if (
      to.matched.some(
        (record) =>
          !record.meta.requiresAuth &&
          !["root_kiosk_nj", "kiosk_nj", "kiosk_rogers"].includes(record.name)
      )
    ) {
      next()
    } else {
      let paramLinkHash = _.get(to, "params.link_hash")
      if (store.getters.isAuthenticated) {
        next()
        return
      } else if (paramLinkHash) {
        if (to.name === "engines_speed_test_hash") {
          next()
          return
        } else if (["kiosk_nj", "kiosk_rogers"].includes(to.name)) {
          let promiseHashInfo
          let fromHashPage
          if (["kiosk_nj", "kiosk_rogers"].includes(to.name)) {
            // Used auto login for kiosk
            promiseHashInfo =
              RemoteControlPlaylistsEngineService.getKioskHashInfo(
                paramLinkHash
              )
            fromHashPage = "kiosk"
          } else {
            // Used auto login for speed test
            promiseHashInfo = SpeedTestsService.getHashInfo(paramLinkHash)
            fromHashPage = "speed_test"
          }
          promiseHashInfo
            .then((result) => {
              store
                .dispatch("loginByAccessTokenAction", {
                  user: result.data.data.user,
                  access_token: result.data.data.access_token,
                })
                .then(() => {
                  store
                    .dispatch("checkAuth")
                    .then(() => {
                      saveHashLogin(fromHashPage).then(() => {
                        // @todo this functionality must be deleted after user rules implementation
                        next()
                        return
                      })
                    })
                    .catch(() => {
                      next("/login")
                    })
                })
                .catch(() => {
                  next("/login")
                })
            })
            .catch(() => {
              next("/login")
            })
        } else {
          next("/login")
        }
      } else {
        next("/login")
      }
    }
  })
})

export default router
