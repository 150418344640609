import jsSHA from "jssha"
import _ from "lodash"

export const removeWebrtcSourcesDublicates = function (devices) {
  let withGroups = []
  let withoutGroups = []
  _.forEach(devices, (val) => {
    if (val.groupId === "") {
      withoutGroups.push(val)
    } else {
      withGroups.push(val)
    }
  })
  let groupedDevices = _.groupBy(withGroups, (o) => o.groupId)

  let filteredDevices = []
  _.forEach(groupedDevices, (val) => {
    let validatedValue
    let withId = _.filter(val, (o) => {
      let arrN = ["default", "communications"]
      return !arrN.includes(o.deviceId)
    })
    if (withId.length > 0) {
      validatedValue = withId[0]
    } else {
      let findedDefault = _.find(val, { deviceId: "default" })
      validatedValue = findedDefault ? findedDefault : val[0]
    }
    if (_.has(validatedValue, "label")) {
      let label = validatedValue.label
      validatedValue.label = label.replace(/\([\w\d_.-]+:[\w\d_.-]+\)$/, "")
    }
    filteredDevices.push(validatedValue)
  })
  let finalDevices = [...filteredDevices, ...withoutGroups]
  return finalDevices
}

export const getTURNCredentials = function (name, secret) {
  var unixTimeStamp = parseInt(Date.now() / 1000) + 24 * 3600, // this credential would be valid for the next 24 hours
    username = [unixTimeStamp, name].join(":"),
    password

  var shaObj = new jsSHA("SHA-1", "TEXT")
  shaObj.setHMACKey(secret, "TEXT")
  shaObj.update(username)
  password = shaObj.getHMAC("B64")
  return {
    username: username,
    password: password,
  }
}

export const getCompleteUrl = (item) => {
  if (item.hostname == "test-jwn-signaling.staging.forasoft.com") {
    return item.protocol + "://" + item.hostname
  }
  return item.protocol + "://" + item.hostname + ":" + item.port
}

export const pingTurnsWebrtc = (turnsList) => {
  let pingPromises = []
  turnsList.forEach(function (val) {
    if (val._key) {
      let itemPromise = new Promise(function (resolve, reject) {
        let begin
        let pc
        let candidates

        let turnUser = {
          login: "arht-turn",
          password: "4d535ab1-8eg3-4558-8083-af0aa0283444",
        }
        var turnCredentials = getTURNCredentials(
          turnUser.login,
          turnUser.password
        )
        let turnUrl = `turn:${val.data.hostname}:${val.data.port}?transport=tcp`
        let iceServers = [
          {
            username: turnCredentials["username"],
            credential: turnCredentials["password"],
            urls: [turnUrl],
          },
        ]

        // Try to determine authentication failures and unreachable TURN
        // servers by using heuristics on the candidate types gathered.
        function getFinalResult() {
          let result = "Done"

          const server = iceServers[0]

          // if there are no candidates
          if (candidates.length < 1) {
            result = "No candidates"
          }

          // get the candidates types (host, srflx, relay)
          const types = candidates.map(function (cand) {
            return cand.type
          })

          // If the server is a TURN server we should have a relay candidate.
          // If we did not get a relay candidate but a srflx candidate
          // authentication might have failed.
          // If we did not get  a relay candidate or a srflx candidate
          // we could not reach the TURN server. Either it is not running at
          // the target address or the clients access to the port is blocked.
          //
          // This only works for TURN/UDP since we do not get
          // srflx candidates from TURN/TCP.
          if (
            server.urls[0].indexOf("turn:") === 0 &&
            server.urls[0].indexOf("?transport=tcp") === -1
          ) {
            if (types.indexOf("relay") === -1) {
              if (types.indexOf("srflx") > -1) {
                // a binding response but no relay candidate suggests auth failure.
                result = "Authentication failed?"
              } else {
                // either the TURN server is down or the clients access is blocked.
                result = "Not reachable?"
              }
            }
          }

          return result
        }

        function gatheringStateChange() {
          if (pc.iceGatheringState !== "complete") {
            return
          }
          const elapsed = ((window.performance.now() - begin) / 1000).toFixed(3)
          if (getFinalResult() == "Done") {
            reject({ _key: val._key, name: val.data.name, ping: elapsed })
          } else {
            resolve({ _key: val._key, name: val.data.name, ping: 100000000 })
          }
          pc.close()
          pc = null
        }

        /* eslint-disable-next-line */
        function iceCandidateError(e) {
          // The interesting attributes of the error are
          // * the url (which allows looking up the server)
          // * the errorCode and errorText
        }

        function gotDescription(desc) {
          begin = window.performance.now()
          candidates = []
          pc.setLocalDescription(desc)
        }

        /* eslint-disable-next-line */
        function noDescription(error) {
          resolve({ _key: val._key, name: val.data.name, ping: 100000000 })
        }

        function iceCallback(event) {
          const elapsed = ((window.performance.now() - begin) / 1000).toFixed(3)
          if (event.candidate) {
            if (event.candidate.candidate === "") {
              return
            }
            const { candidate } = event
            candidates.push(candidate)
            reject({ _key: val._key, name: val.data.name, ping: elapsed })
          } else if (
            !("onicegatheringstatechange" in RTCPeerConnection.prototype)
          ) {
            // should not be done if its done in the icegatheringstatechange callback.
            //appendCell(row, getFinalResult(), 7);
            if (getFinalResult() == "Done") {
              reject({ _key: val._key, name: val.data.name, ping: elapsed })
            } else {
              resolve({ _key: val._key, name: val.data.name, ping: 100000000 })
            }
            pc.close()
            pc = null
          }
        }

        // Create a PeerConnection with no streams, but force a m=audio line.
        const config = {
          iceServers: iceServers,
          iceTransportPolicy: "relay",
          iceCandidatePoolSize: 0,
        }
        const offerOptions = { offerToReceiveAudio: true }
        // Whether we gather IPv6 candidates.
        // Whether we only gather a single set of candidates for RTP and RTCP.
        pc = new RTCPeerConnection(config)
        pc.onicecandidate = iceCallback
        pc.onicegatheringstatechange = gatheringStateChange
        pc.onicecandidateerror = iceCandidateError
        pc.createOffer(offerOptions).then(gotDescription, noDescription)
      })
      pingPromises.push(itemPromise)
    }
  })
  return new Promise((resolve) => {
    Promise.all(pingPromises)
      .then((pingAll) => {
        if (pingAll.length > 0) {
          let minPingItem = _.minBy(pingAll, "ping")
          resolve(minPingItem)
        } else {
          resolve({ _key: null, name: "", ping: 100000000 })
        }
      })
      .catch((pingOne) => {
        resolve(pingOne)
      })
  })
}

// Get percent of video/audio packets lost for speed test
export const percentPacketLost = (received, lost) => {
  let packetsLost = parseInt(lost)
  let totalPackets = parseInt(received) + packetsLost
  let percentPacketLost = parseFloat((packetsLost * 100) / totalPackets)
  return decimalsPercentPacketLost(percentPacketLost)
}

export const decimalsPercentPacketLost = (val) => {
  if (val > 0) {
    return val.toFixed(2)
  }
  return val || 0
}

export default {
  getTURNCredentials,
  getCompleteUrl,
  pingTurnsWebrtc,
  removeWebrtcSourcesDublicates,
  percentPacketLost,
  decimalsPercentPacketLost,
}
