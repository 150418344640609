import { EngineService } from "@/common/api/api.service"

export default {
  enginesCompanyGetList({ commit, dispatch }, params) {
    if (!params) params = { show_active_preset: 1, p: 1, pp: 10 }
    dispatch("saveTableLoaderAction", true)
    EngineService.get(params)
      .then((response) => {
        commit("saveEnginesCompanyGetList", response.data.data.engines)
        commit("saveEnginesCompanyListCount", response.data.data.full_count)
        dispatch("saveTableLoaderAction", false)
      })
      .catch((err) => {
        console.log("STORE enginesCompanyGetList ERROR", err)
        dispatch("saveTableLoaderAction", false)
      })
  },
  resetEngineCompanyList({ commit }) {
    return new Promise((resolve) => {
      commit("saveEnginesCompanyGetList", [])
      commit("saveEnginesCompanyListCount", 0)
      resolve()
    })
  },
  /**
   * Get data for selected engine in popup (in case when async select)
   * //@todo maybe later refactor in backend to send field that will tell what engine to preserve in response
   *
   * @param commit
   * @param dispatch
   * @param params
   */
  engineSelectedInlist({ commit }, params) {
    EngineService.getItem(params)
      .then((response) => {
        // console.log("engineSelectedInlist", response)
        commit("SAVE_SELECTED_ENGINE_IN_LIST", response.data.data.engine)
      })
      .catch((err) => {
        console.log("engineSelectedInlist ERROR", err)
      })
  },
  enginesCreate(context, formData) {
    return EngineService.create(formData.data)
  },
  enginesEdit(_context, formData) {
    return EngineService.update(formData.slug, formData.data)
  },
  enginesDelete(_context, item) {
    return EngineService.destroy(item._key)
  },
  setLocalVideoStarted({ commit }, val) {
    return new Promise((resolve) => {
      commit("SET_LOCAL_VIDEO_STARTED", val)
      resolve()
    })
  },
}
