import {
  StoreService,
  EventService,
  EngineService,
} from "@/common/api/api.service"
import { CDN_URL } from "@/common/config"
import { default as EngService } from "@/common/engine.service"

export default {
  getBundlesList({ commit, dispatch }, params) {
    if (!params) params = { p: 1, pp: 10 }
    StoreService.bundles
      .getList(params)
      .then((response) => {
        commit("SAVE_BUNDLES_LIST", response.data.data.result)
        commit("SAVE_BUNDLES_COUNT", response.data.data.full_count)
      })
      .catch((error) => {
        console.error("STORE getBundlesList ERROR", error)
        dispatch("saveTableLoaderAction", false)
      })
  },
  /**
   * Get data for selected bundle in popup (in case when async select)
   * //@todo maybe later refactor in backend to send field that will tell what bundle to preserve in response
   *
   * @param commit
   * @param dispatch
   * @param params
   */
  bundleSelectedInlist({ commit, dispatch }, key) {
    StoreService.bundles
      .getItem(key)
      .then((response) => {
        commit("SAVE_SELECTED_BUNDLE_IN_LIST", response?.data?.data)
      })
      .catch((err) => {
        console.log("bundleSelectedInlist ERROR", err)
      })
  },
  getBundleItem({ commit }, data) {
    return new Promise((resolve, reject) => {
      StoreService.bundles.getItem(data.slug).then(function (response) {
        let data = response.data.data || {}
        commit("SAVE_BUNDLE_ITEM", data)
        resolve()
      })
    })
  },
  createBundle({ commit }, data) {
    return StoreService.bundles.create(data.data)
  },
  editBundle({ commit }, data) {
    return StoreService.bundles.update(data.slug, data.data)
  },
  deleteBundle({ commit }, data) {
    return StoreService.bundles.destroy(data._key)
  },
  resetBundleItem({ commit }) {
    return new Promise((resolve, reject) => {
      commit("RESET_BUNDLE_ITEM")
      resolve()
    })
  },
  setBundleType({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("SET_BUNDLE_TYPE", data)
      resolve()
    })
  },
  setBundleTypes({ commit }, params) {
    if (!params) params = { p: 1, pp: 10 }
    return new Promise((resolve, reject) => {
      StoreService.bundleTypes
        .getList(params)
        .then((response) => {
          commit("SET_BUNDLE_TYPES", response.data.data.result)
          resolve()
        })
        .catch((error) => {
          console.error("STORE getBundlesList ERROR", error)
          reject()
        })
    })
  },
  getProductRegisteredItem({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("SAVE_PRODUCT_REGISTERED_ITEM", data)
      resolve()
    })
  },
  resetProductRegisteredItem({ commit }) {
    return new Promise((resolve, reject) => {
      commit("RESET_PRODUCT_REGISTERED_ITEM")
      resolve()
    })
  },
  createProductRegistered({ commit }, data) {
    return StoreService.deals.products.registry.create(data.slug, data.data)
  },
  editProductRegistered({ commit }, data) {
    return StoreService.deals.products.registry.update(data.slug, data.data)
  },
  editProduct({ commit }, data) {
    return StoreService.deals.products.update(data.slug, data.data)
  },
  getProductStatuses({ commit }) {
    return StoreService.deals.products.statuses.getList().then((result) => {
      commit("SET_PRODUCT_STATUSES", result.data.data)
    })
  },

  // @todo maybe delete this endpoint later, there is no need for registry
  getOrdersRegisteredList({ commit, dispatch }, params) {
    if (!params) params = { p: 1, pp: 10 }
    StoreService.bundles.registry
      .getList(params)
      .then((response) => {
        commit("SAVE_ORDERS_REGISTERED_LIST", response.data.data.result)
        commit("SAVE_ORDERS_REGISTERED_COUNT", response.data.data.full_count)
      })
      .catch((error) => {
        console.error("STORE getOrdersRegisteredList ERROR", error)
        dispatch("saveTableLoaderAction", false)
      })
  },
  // @todo maybe delete this endpoint later, there is no need for registry
  resetOrderRegisteredList({ commit }) {
    return new Promise((resolve, reject) => {
      commit("RESET_ORDER_REGISTERED_LIST")
      resolve()
    })
  },
  // @todo maybe delete this endpoint later, there is no need for registry
  resetOrderRegisteredItem({ commit }) {
    return new Promise((resolve, reject) => {
      commit("RESET_ORDER_REGISTERED_ITEM")
      resolve()
    })
  },
  // @todo maybe delete this endpoint later, there is no need for registry
  setOrderRegisteredFilters({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit("SET_ORDERS_REGISTERED_FILTERS", value)
      resolve()
    })
  },
  // @todo maybe delete this endpoint later, there is no need for registry
  saveOrderRegisteredItem({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit("SAVE_ORDER_REGISTERED_ITEM", value)
      resolve()
    })
  },
  // @todo maybe delete this endpoint later, there is no need for registry
  getOrderRegisteredItem({ commit }, data) {
    return new Promise((resolve, reject) => {
      StoreService.orders.registry.getItem(data.slug).then(function (response) {
        let data = response.data.data || {}
        commit("SAVE_ORDER_REGISTERED_ITEM", data)
        resolve()
      })
    })
  },
  // @todo maybe delete this endpoint later, there is no need for registry
  createOrderRegistered({ commit }, data) {
    return StoreService.orders.registry.create(data.data)
  },
  // @todo maybe delete this endpoint later, there is no need for registry
  editOrderRegistered({ commit }, data) {
    return StoreService.orders.registry.update(data.slug, data.data)
  },
  // @todo maybe delete this endpoint later, there is no need for registry
  deleteOrderRegistered({ commit }, data) {
    return StoreService.orders.registry.destroy(data._key)
  },
  getEventsList({ commit, dispatch, getters }, params) {
    if (!params) params = { p: 1, pp: 10 }
    return new Promise((resolve, reject) => {
      EventService.getList(params)
        .then((response) => {
          let eventsList = response?.data?.data?.result || []
          eventsList = eventsList.map((e) => {
            let membersGrouped = {
              displays: e.members.filter(
                (r) => r.role?.name && r.role?.name.includes("Display")
              ),
              studios: e.members.filter(
                (r) => r.role?.name && r.role?.name.includes("Studio")
              ),
              operators: e.members.filter(
                (r) => r.role?.name && r.role?.name.includes("Operator")
              ),
            }
            return {
              ...e,
              ...membersGrouped,
              ...{
                icon: e.icon
                  ? `${CDN_URL}${e.icon}`
                  : `${window.location.origin}/images/66x66-808080.png`,
              },
            }
          })
          commit("SAVE_EVENTS_LIST", eventsList)
          commit("SAVE_EVENTS_COUNT", response?.data?.data?.full_count || 0)
          resolve({
            events: eventsList,
            count: response?.data?.data?.full_count || 0,
          })
        })
        .catch((error) => {
          console.error("STORE getEventsList ERROR", error)
          dispatch("saveTableLoaderAction", false)
        })
    })
  },
  resetEventsList({ commit }, val) {
    return new Promise((resolve, reject) => {
      commit("RESET_EVENTS_LIST", val)
      resolve()
    })
  },
  eventsHashInfo({ commit, dispatch }, hash) {
    return EventService.getHashInfo(hash)
  },
  setEventTimezoneFilter({ commit }, val) {
    return new Promise((resolve, reject) => {
      commit("SET_EVENT_TIMEZONE_FILTER", val)
      resolve()
    })
  },
  setEventEngineFilter({ commit }, val) {
    return new Promise((resolve, reject) => {
      commit("SET_EVENT_ENGINE_FILTER", val)
      resolve()
    })
  },
  resetEventItem({ commit }) {
    return new Promise((resolve) => {
      commit("RESET_EVENT_ITEM")
      commit("RESET_BUNDLE_CONNECTIONS_LIST")
      resolve()
    })
  },
  getEventItem({ commit }, data) {
    return new Promise((resolve) => {
      EventService.getItem(data.slug).then(function (response) {
        let item = response?.data?.data || {}
        item.icon = item.icon
          ? `${CDN_URL}${item.icon}`
          : `${window.location.origin}/images/66x66-808080.png`
        commit("SAVE_EVENT_ITEM", item)
        resolve()
      })
    })
  },
  saveEventItem({ commit }, data) {
    return new Promise((resolve) => {
      commit("SAVE_EVENT_ITEM", data)
      resolve()
    })
  },
  saveEventIcon({ commit }, data) {
    return EventService.saveIcon(data.slug, data.data)
  },
  createEvent({ commit }, data) {
    return EventService.create(data.data)
  },
  editEvent({ commit }, data) {
    return EventService.update(data.slug, data.data)
  },
  deleteEvent({ commit }, data) {
    return EventService.destroy(data._key)
  },
  setTimezoneEvent({ commit }, value) {
    commit("SET_TIMEZONE_EVENT", value)
  },
  setTimeEvent({ commit }, value) {
    commit("SET_DATETIME_EVENT", value)
  },
  /**
   * For not superuser, for event we must set group_key field as company for current user
   * @param commit
   * @param data
   */
  setCompanyForEvent({ commit, getters }, data) {
    return new Promise((resolve) => {
      if (data.editedIndex === -1 && !getters.isSuperUser) {
        commit("SET_COMPANY_FOR_EVENT", data.groupKey)
      }
      resolve()
    })
  },
  setDatesForEvent({ commit, getters }, data) {
    return new Promise((resolve, reject) => {
      commit("SET_DATES_FOR_BUNDLE_EVENT", data)
      resolve()
    })
  },
  setEventField({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("SET_EVENT_FIELD", data)
      resolve()
    })
  },
  addEventMember({ commit, getters }, data) {
    return new Promise((resolve, reject) => {
      commit("ADD_EVENT_MEMBER", data)
      resolve()
    })
  },
  inviteEventMember({ commit, getters }, data) {
    return EventService.invite(data)
  },
  createEventMember({ commit, getters }, data) {
    return EventService.members.create(data.slug, data.data)
  },
  updateEventMember({ commit, getters }, data) {
    return EventService.members.update(data.slug, data.data)
  },
  deleteEventMember({ commit, getters }, data) {
    return EventService.members.destroy(data.slug)
  },
  handshakeEventMember({ commit, getters }, data) {
    return EventService.members.handshake(data.slug)
  },
  inviteHashInfo({ commit, getters }, data) {
    return EventService.inviteHashInfo(data)
  },
  acceptEventInvite({ commit, getters }, data) {
    return EventService.acceptInvite(data)
  },
  declineEventInvite({ commit, getters }, data) {
    return EventService.declineInvite(data)
  },
  loadingStartStopEvent({ commit, getters }, data) {
    return new Promise((resolve, reject) => {
      commit("LOADING_START_STOP_EVENT", data)
      resolve()
    })
  },
  refreshEventMembersStatuses({ commit }, data) {
    return new Promise((resolve, reject) => {
      if (data.slug === -1) {
        resolve()
      } else {
        EventService.getItem(data.slug).then(function (response) {
          let members = response?.data?.data?.members || []
          commit("REFRESH_EVENT_MEMBERS_STATUSES", members)
          resolve()
        })
      }
    })
  },
  setMembersRcData({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      if (state.activeEvent._key && state.activeEvent.members.length > 0) {
        state.activeEvent.members.forEach((m, index) => {
          if (m.playlist) {
            let data = {
              slug: m.playlist._key,
            }
            dispatch("rcPlaylistsEngineGetInfoAction", data)
              .then(function (response) {
                let rcEngineData = response.data.data.rc_engine
                let plData = EngService.parsePlaylistsData(rcEngineData.items)
                rcEngineData.playlists = plData[0]
                rcEngineData.haveGroups = plData[1]
                commit("SET_MEMBER_RC_DATA", {
                  index: index,
                  member: m,
                  rcEngineData: rcEngineData,
                })
                resolve()
              })
              .catch(function (response) {
                resolve()
                console.log(
                  "error on RC_PLAYLISTS_ENGINE_PARSED_INFO",
                  response
                )
              })
          } else {
            resolve()
          }
        })
      } else {
        resolve()
      }
    })
  },
  refreshEventMembersPlaylists({ commit, state }, data) {
    if (
      data &&
      Object.prototype.hasOwnProperty.call(data, "engine_key") &&
      state.activeEvent._key
    ) {
      EventService.getItem(state.activeEvent._key).then(function (response) {
        let eData = response.data.data || {}
        commit("REFRESH_EVENT_MEMBERS_PLAYLISTS", {
          members: eData.members,
          engine_key: data.engine_key,
        })
      })
    }
  },
  getEventMembersRolesList({ commit, dispatch, getters }, params) {
    if (!params) params = { p: 1, pp: 10 }
    EventService.roles
      .getList(params)
      .then((response) => {
        commit("SAVE_EVENT_MEMBERS_ROLES_LIST", response.data.data.result)
        commit("SAVE_EVENT_MEMBERS_ROLES_COUNT", response.data.data.full_count)
      })
      .catch((error) => {
        console.error("STORE getEventMembersRolesList ERROR", error)
        dispatch("saveTableLoaderAction", false)
      })
  },
  resetEventMembersRoleItem({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit("RESET_EVENT_MEMBERS_ROLE_ITEM")
      resolve()
    })
  },
  getEventMemberRoleItem({ commit }, data) {
    return new Promise((resolve, reject) => {
      EventService.roles.getItem(data.slug).then(function (response) {
        let data = response.data.data || {}
        commit("SAVE_EVENT_MEMBER_ROLE_ITEM", data)
        resolve()
      })
    })
  },
  createEventMemberRole({ commit }, data) {
    return EventService.roles.create(data.data)
  },
  editEventMemberRole({ commit }, data) {
    return EventService.roles.update(data.slug, data.data)
  },
  deleteEventMemberRole({ commit }, data) {
    return EventService.roles.destroy(data._key)
  },
  getEngineLocationsList({ commit, dispatch, getters }, params) {
    if (!params) params = { p: 1, pp: 10 }
    return new Promise((resolve, reject) => {
      EngineService.locations
        .getList(params)
        .then((response) => {
          let list = response?.data?.data?.result || []
          let count = response?.data.data.full_count || 0
          commit("SAVE_ENGINE_LOCATIONS_LIST", list)
          commit("SAVE_ENGINE_LOCATIONS_COUNT", count)
          resolve({ result: list, full_count: count })
        })
        .catch((error) => {
          console.error("STORE getEngineLocationsList ERROR", error)
          dispatch("saveTableLoaderAction", false)
          reject(error)
        })
    })
  },
  resetEngineLocations({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit("SAVE_ENGINE_LOCATIONS_LIST", [])
      commit("SAVE_ENGINE_LOCATIONS_COUNT", 0)
      resolve()
    })
  },
  resetEngineLocationItem({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit("RESET_ENGINE_LOCATION_ITEM")
      resolve()
    })
  },
  getEngineLocationItem({ commit }, data) {
    return new Promise((resolve, reject) => {
      EngineService.locations.getItem(data.slug).then(function (response) {
        let item = response.data.data || {}
        item.icon = item.icon
          ? `${CDN_URL}${item.icon}`
          : `${window.location.origin}/images/66x66-808080.png`
        if (item.engine) {
          item.group_key = item?.engine?.company_key
        }
        commit("SAVE_ENGINE_LOCATION_ITEM", item)
        resolve(item)
      })
    })
  },
  createEngineLocation({ commit }, data) {
    return EngineService.locations.create(data.data)
  },
  editEngineLocation({ commit }, data) {
    return EngineService.locations.update(data.slug, data.data)
  },
  deleteEngineLocation({ commit }, data) {
    return EngineService.locations.destroy(data._key)
  },
  saveEngineLocationIcon({ commit }, data) {
    return EngineService.locations.saveIcon(data.slug, data.data)
  },
  setEngineLocationField({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("SET_ENGINE_LOCATION_FIELD", data)
      resolve()
    })
  },
  getBundleConnectionsList({ commit, dispatch }, params) {
    if (!params) params = { p: 1, pp: 10 }
    StoreService.bundles.connections
      .getList(params)
      .then((response) => {
        commit("SAVE_BUNDLE_CONNECTIONS_LIST", response.data.data.result)
        commit("SAVE_BUNDLE_CONNECTIONS_COUNT", response.data.data.full_count)
      })
      .catch((error) => {
        console.error("STORE getBundleConnectionsList ERROR", error)
        dispatch("saveTableLoaderAction", false)
      })
  },
  saveBundleConnectionItem({ commit }, data) {
    return new Promise((resolve, reject) => {
      StoreService.bundles.connections
        .getItem(data.slug)
        .then(function (response) {
          let data = response.data.data || {}
          commit("SAVE_BUNDLE_CONNECTION_ITEM", data)
          resolve()
        })
    })
  },
  resetBundleConnectionItem({ commit }) {
    return new Promise((resolve, reject) => {
      commit("RESET_BUNDLE_CONNECTION_ITEM")
      resolve()
    })
  },
  createBundleConnection({ commit }, data) {
    return StoreService.bundles.connections.create(data.data)
  },
  editBundleConnection({ commit }, data) {
    return StoreService.bundles.connections.update(data.slug, data.data)
  },
  deleteBundleConnection({ commit }, data) {
    return StoreService.bundles.connections.destroy(parseInt(data._key))
  },
  resetDealItem({ commit }) {
    return new Promise((resolve, reject) => {
      commit("RESET_DEAL_ITEM")
      resolve()
    })
  },
  resetDealsList({ commit }) {
    return new Promise((resolve, reject) => {
      commit("RESET_DEALS_LIST")
      resolve()
    })
  },
  getDealsList({ commit, dispatch }, params) {
    if (!params) params = { p: 1, pp: 10 }
    StoreService.deals.products
      .getList(params)
      .then((response) => {
        commit("SAVE_DEALS_LIST", response.data.data.result)
        commit("SAVE_DEALS_COUNT", response.data.data.full_count)
      })
      .catch((error) => {
        console.error("STORE getDealsList ERROR", error)
        dispatch("saveTableLoaderAction", false)
      })
  },
  getDealItem({ commit }, data) {
    return new Promise((resolve, reject) => {
      StoreService.deals.getItem(data.slug).then(function (response) {
        let data = response.data.data || {}
        commit("SAVE_DEAL_ITEM", data)
        resolve()
      })
    })
  },
  createDeal({ commit }, data) {
    return StoreService.deals.create(data.data)
  },
  editDeal({ commit }, data) {
    return StoreService.deals.update(data.slug, data.data)
  },
  deleteDeal({ commit }, data) {
    return StoreService.deals.destroy(data.hs_deal_id)
  },
  /**
   * Get data for selected product in popup (in case when async select)
   * //@todo maybe later refactor in backend to send field that will tell what product to preserve in response
   *
   * @param commit
   * @param dispatch
   * @param params
   */
  productSelectedInlist({ commit, dispatch }, key) {
    StoreService.bundles
      .getItem(key)
      .then((response) => {
        commit("SAVE_SELECTED_BUNDLE_IN_LIST", response?.data?.data)
      })
      .catch((err) => {
        console.log("bundleSelectedInlist ERROR", err)
      })
  },
  getLicensesList({ commit, dispatch }, params) {
    if (!params) params = { p: 1, pp: 10 }
    return new Promise((resolve, reject) => {
      StoreService.licenses
        .getList(params)
        .then((response) => {
          commit("SAVE_LICENSES_LIST", response.data.data.result)
          commit("SAVE_LICENSES_COUNT", response.data.data.full_count)
          resolve()
        })
        .catch((error) => {
          console.error("STORE getLicensesList ERROR", error)
          dispatch("saveTableLoaderAction", false)
          reject(error)
        })
    })
  },
  resetLicenseItem({ commit }) {
    return new Promise((resolve, reject) => {
      commit("RESET_LICENSE_ITEM")
      resolve()
    })
  },
  getLicensesTypesList({ commit, dispatch }, params) {
    if (!params) params = { p: 1, pp: 10 }
    return StoreService.licenses.types.getList(params)
  },
  createLicense({ commit }, data) {
    return StoreService.licenses.create(data.data)
  },
  revokeLicense({ commit }, data) {
    return StoreService.licenses.revoke(data)
  },
  getLicenseStatusesList({ commit }, params) {
    return StoreService.licenses.statuses
      .getList(params)
      .then((result) => {
        commit("SAVE_LICENSE_STATUSES_LIST", result.data.data)
      })
      .catch((err) => {
        console.error("Error on getLicenseStatusesList", err)
      })
  },
}
