import { PresetsEngineService } from "@/common/api/api.service"

export default {
  presetsEnginesGetList(_context, params) {
    return PresetsEngineService.get(params)
  },
  presetsEnginesCreate(context, formData) {
    return PresetsEngineService.create(formData.data)
  },
  presetsEnginesEdit(_context, formData) {
    return PresetsEngineService.update(formData.slug, formData.data)
  },
  presetsEnginesDelete(_context, item) {
    return PresetsEngineService.destroy(item._key)
  },
}
