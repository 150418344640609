import UserService from "@/common/api/user.service"
import _ from "lodash"

export default {
  companyProfileGetData(_context, data) {
    return UserService.userGroups(data)
  },
  companyProfileUpdate(_context, formData) {
    return UserService.userUpdateGroup(formData.slug, formData.data)
  },
  currentUserCompanyInfo({ dispatch, getters, commit }) {
    let _key = getters.currentUser.group_key || "0"
    return new Promise((resolve) => {
      dispatch("companyProfileGetData", _key)
        .then(function (response) {
          commit("CURRENT_USER_COMPANY_INFO", response.data.data.group)
          resolve()
        })
        .catch(() => {
          resolve()
        })
    })
  },
  saveLoaderAction({ commit }, value) {
    return new Promise((resolve) => {
      commit("saveLoader", value)
      resolve()
    })
  },
  saveWsConnAction({ commit }, value) {
    commit("saveWsConn", value)
  },
  saveTableLoaderAction({ commit }, value) {
    commit("saveTableLoader", value)
  },
  saveCurrentIpAction({ commit }, value) {
    commit("saveCurrentIp", value)
  },
  saveCompanySignal({ commit, getters, dispatch }) {
    return new Promise((resolve) => {
      let getParams = {
        params: {
          type: "active_signal_server",
          company_key: getters.currentUser.group_key,
        },
      }
      dispatch("signalServersList", getParams)
        .then(function (response) {
          let activeSignalServer = _.get(
            response,
            "data.data.settings_multi[0]"
          )
          commit("SAVE_COMPANY_SIGNAL", activeSignalServer)
          resolve()
        })
        .catch(() => {
          resolve()
        })
    })
  },
  setWsUrl({ commit }, value) {
    return new Promise((resolve) => {
      commit("SET_WS_URL", value)
      resolve()
    })
  },
}
