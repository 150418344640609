export default {
  bundlesList: (state) => state.bundlesList,
  bundlesCount: (state) => state.bundlesCount,
  activeBundle: (state) => state.activeBundle,
  bundleTypes: (state) => state.bundleTypes,
  productStatuses: (state) => state.productStatuses,
  // @todo maybe delete this activeOrderRegistered later, there is no need for registry
  ordersRegisteredList: (state) => state.ordersRegisteredList,
  // @todo maybe delete this activeOrderRegistered later, there is no need for registry
  ordersRegisteredCount: (state) => state.ordersRegisteredCount,
  // @todo maybe delete this activeOrderRegistered later, there is no need for registry
  filterByEngineRegistry: (state) =>
    state.bundlesRegisteredFilters.filterByEngine,
  // @todo maybe delete this activeOrderRegistered later, there is no need for registry
  filterByBundleRegistry: (state) =>
    state.bundlesRegisteredFilters.filterByBundle,
  // @todo maybe delete this activeOrderRegistered later, there is no need for registry
  activeProductRegistered: (state) => state.activeProductRegistered,
  // @todo maybe delete this activeOrderRegistered later, there is no need for registry
  activeOrderRegistered: (state) => state.activeOrderRegistered,
  eventsList: (state) => state.eventsList,
  eventsCount: (state) => state.eventsCount,
  eventFilters: (state) => state.eventFilters,
  activeEvent: (state) => state.activeEvent,
  eventMembersSave: (state) =>
    state.activeEvent.members.map((m) => ({
      engine_key: m.engine_key,
      role:
        m.role && Object.prototype.hasOwnProperty.call(m.role, "_key")
          ? m.role._key
          : null,
    })),
  loadingStartStopEvent: (state) => state.loadingStartStopEvent,
  eventMembersRolesList: (state) => state.eventMembersRolesList,
  eventMembersRolesCount: (state) => state.eventMembersRolesCount,
  activeEventMemberRole: (state) => state.activeEventMemberRole,
  engineLocationsList: (state) => state.engineLocationsList,
  engineLocationsCount: (state) => state.engineLocationsCount,
  activeEngineLocation: (state) => state.activeEngineLocation,
  bundleConnectionsList: (state) => state.bundleConnectionsList,
  bundleConnectionsCount: (state) => state.bundleConnectionsCount,
  activeBundleConnection: (state) => state.activeBundleConnection,
  dealsList: (state) => state.dealsList,
  dealsCount: (state) => state.dealsCount,
  activeDeal: (state) => state.activeDeal,
  dealsHasProducts: (state) => {
    let hasProducts = false
    state.dealsList.forEach((deal) => {
      if (deal.products.length > 0) {
        hasProducts = true
      }
    })
    return hasProducts
  },
  licensesList: (state) => state.licensesList,
  licensesCount: (state) => state.licensesCount,
  activeLicense: (state) => state.activeLicense,
  licenseStatuses: (state) => state.licenseStatuses,
}
