import _ from "lodash"

export default {
  saveEnginesCompanyGetList(state, enginesCompanyList) {
    state.enginesCompanyList = enginesCompanyList
  },
  saveEnginesCompanyListCount(state, count) {
    state.enginesCompanyListCount = count
  },
  SAVE_SELECTED_ENGINE_IN_LIST(state, engine) {
    // console.log("SAVE_SELECTED_ENGINE_IN_LIST", engine)
    if (engine) {
      if (!_.find(state.enginesCompanyList, { _key: engine._key })) {
        state.enginesCompanyList.push(engine)
      }
    }
  },
  SET_LOCAL_VIDEO_STARTED(state, value) {
    state.localVideoStarted = value
  },
}
