import Vue from "vue"
import Vuex from "vuex"
import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"

Vue.use(Vuex)

const state = {
  websocketCommonConnection: null,
  allEngines: {},
  presetEngines: {},
  presetTreeviewEngines: [],

  webEngine: {
    pk: "",
    name: "",
    active_video: "",
    active_audio: "",
    active_webrtc_constraints: "both",
    nickName: null,
    peerId: null,
    activeVideoConstraintResolution: "720p",
    activeVideoConstraintFrameRate: "30",
  },
  is_video_required: true,
  is_audio_required: true,
  engineWebrtcSources: [],
  signalSeverUrl: "https://signal-useast.arhtengine.com:8889",
  webrtcConstraints: {
    both: {
      label: "Video & Audio",
      constraints: { video: true, audio: true },
    },
    video: {
      label: "Only Video",
      constraints: { video: true, audio: false },
    },
    audio: {
      label: "Only Audio",
      constraints: { video: false, audio: true },
    },
  },
  activeWebrtcConstraints: "both",
  webrtcVideoConstraints: {
    resolutions: {
      "360p": {
        width: {
          min: "640",
          max: "640",
        },
        height: {
          min: "360",
          max: "360",
        },
      },
      "480p": {
        width: {
          min: "848",
          max: "848",
        },
        height: {
          min: "480",
          max: "480",
        },
      },
      "720p": {
        width: {
          min: "1280",
          max: "1280",
        },
        height: {
          min: "720",
          max: "720",
        },
      },
      "1080p": {
        width: {
          min: "1920",
          max: "1920",
        },
        height: {
          min: "1080",
          max: "1080",
        },
      },
    },
    frameRates: {
      20: {
        frameRate: {
          min: "20",
          max: "20",
        },
      },
      30: {
        frameRate: {
          min: "30",
          max: "30",
        },
      },
      60: {
        frameRate: {
          min: "60",
          max: "60",
        },
      },
    },
  },
  error: null,
  browserWebrtcConn: null,
  webcameraStarted: false,
  websocketConnCompany: null,
  unattendedEngines: {},
  supervisedEngines: {},
  selectedEngine: -1,
  selectedConnectionEngine: -1,
  enginesInfo: {},
  conn_websocket_company: null,
  showSetupStreamItem: false,
}

export default {
  state,
  mutations,
  getters,
  actions,
}
