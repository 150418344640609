import UserService from "@/common/api/user.service"
import ApiService from "@/common/api/api.service"
import _ from "lodash"
import { defineAbilityFor } from "../ability"
import { CommonConnectionService } from "@/common/websocket.service"

export default {
  login(context, credentials) {
    return new Promise((resolve, reject) => {
      UserService.userLogin(credentials)
        .then(({ data }) => {
          let loginData = {
            user: data.data.user,
            access_token: data.data.access_token,
          }
          context.commit("setAuth", loginData)
          let parsedPermissions = defineAbilityFor(data.data.user.permissions)
          context.commit("CREATE_SESSION", parsedPermissions)
          context
            .dispatch("currentUserCompanyInfo")
            .then(() => {
              resolve(data)
            })
            .catch(() => {
              console.log("Error on currentUserCompanyInfo")
            })
        })
        .catch((error) => {
          console.log("catch login", error)
          context.dispatch("resetCompanyInfo")
          context.commit("PURGE_AUTH")
          context.commit("DESTROY_SESSION")
          if (!_.isUndefined(error)) {
            context.commit("setError", error.data)
          }
          reject(error)
        })
    })
  },
  loginByAccessTokenAction(context, { access_token }) {
    return new Promise((resolve) => {
      UserService.destroyToken()
      UserService.saveToken(access_token)
      resolve()
    })
  },
  writeUserMicrosoft(context, data) {
    return new Promise((resolve, reject) => {
      let loginData = {
        user: data.data.user,
        access_token: data.data.access_token,
      }
      context.commit("setAuth", loginData)
      let parsedPermissions = defineAbilityFor(data.data.user.permissions)
      context.commit("CREATE_SESSION", parsedPermissions)
      context
        .dispatch("currentUserCompanyInfo")
        .then(() => {
          resolve(data)
        })
        .catch(() => {
          console.log("Error on currentUserCompanyInfo")
          reject()
        })
    })
  },
  logout(context) {
    context.dispatch("resetCompanyInfo")
    context.commit("PURGE_AUTH")
    context.commit("DESTROY_SESSION")
    context
      .dispatch("closeWsConnection")
      .then(() => {
        context.dispatch("saveWsConnAction", null)
        context.dispatch("setAuthWsAction", false)
      })
      .catch((err) => {
        console.error("Error on close WS connection", err)
      })
  },
  register(context, credentials) {
    return new Promise((resolve, reject) => {
      UserService.userCreate(credentials)
        .then(({ data }) => {
          context.commit("setAuth", data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit("setError", response.data)
          reject(response)
        })
    })
  },
  checkAuth(context) {
    return new Promise((resolve) => {
      if (UserService.getToken() && context.getters.currentUser) {
        ApiService.setHeader()
      } else {
        context.commit("PURGE_AUTH")
        context.commit("DESTROY_SESSION")
      }
      resolve()
    })
  },
  updateUser(context, payload) {
    const { email, username, password, image, bio } = payload
    const user = {
      email,
      username,
      bio,
      image,
    }
    if (password) {
      user.password = password
    }
    return UserService.userUpdate(user).then(({ data }) => {
      context.commit("setAuth", data.user)
      return data
    })
  },

  resetPassword(context, data) {
    return UserService.userSendResetEmail(data)
  },
  resetPasswordCheckHash(context, data) {
    return UserService.userResetPasswordCheckHash(data.slug)
  },
  resetPasswordConfirmation(context, data) {
    return UserService.userResetPasswordConfirmation(data)
  },
  changePasswordForgot(context, data) {
    return UserService.userResetPassword(data.slug, data.data)
  },
  setAuthWsAction(context, value) {
    return new Promise((resolve) => {
      context.commit("setAuthWs", value)
      resolve()
    })
  },
  acceptTermsPrivacy({ commit }) {
    return new Promise((resolve, reject) => {
      UserService.acceptTermsPrivacy()
        .then(() => {
          commit("ACCEPT_TERMS_PRIVACY")
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  closeWsConnection(context) {
    return CommonConnectionService.close(
      context.getters.getWebsocketCommonConnection
    )
  },
}
