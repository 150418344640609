import Vue from "vue"
import moment from "moment"
import momentTimezone from "moment-timezone"

Vue.filter("capitalize", function (value) {
  if (!value) return ""
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter("lowercase", function (value) {
  if (!value) return ""
  value = value.toString()
  return value.toLowerCase()
})

Vue.filter("dateFormat", function (value) {
  return value ? moment(parseInt(value)).format("MMM D, YYYY") : ""
})

Vue.filter("dateTimeFormat", function (value) {
  return value ? moment(parseInt(value)).format("MMM D, YYYY HH:mm") : ""
})

Vue.filter("unixDateFormat", function (value) {
  return value ? moment.unix(value).format("MMM D, YYYY") : ""
})

Vue.filter(
  "unixDateTimeFormat",
  function (value, format = "MMM D, YYYY HH:mm") {
    return value ? moment.unix(value).format(format) : ""
  }
)

Vue.filter("unixTimeFormat", function (value, format = "h:mm") {
  return moment.unix(value).format(format)
})

Vue.filter(
  "unixTimeFormatTimezone",
  function (value, timezone, format = "h:mm") {
    return momentTimezone.tz(moment.unix(value), timezone).format(format)
  }
)

Vue.filter("unixDateFormatTimezone", function (value, timezone) {
  return momentTimezone
    .tz(moment.unix(value), timezone)
    .format("MMM D, YYYY HH:mm")
})

Vue.filter(
  "dateFormatTimezone",
  function (value, timezone, format = "MMM D, YYYY HH:mm") {
    return momentTimezone.tz(value, timezone).format(format)
  }
)

Vue.filter("toFixed", function (value, precision = 2) {
  return value ? value.toFixed(precision) : ""
})
