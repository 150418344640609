import Vue from "vue"
import Vuex from "vuex"
import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"

Vue.use(Vuex)

const state = {
  listWebrtcVideoSources: [],
  listWebrtcAudioSources: [],
  parsedListWebrtcVideoSources: [],
  parsedListWebrtcAudioSources: [],
  listWebcameraResolutions: [
    { name: "QVGA [320x240]", width: 320, height: 240 },
    { name: "VGA [640x480]", width: 640, height: 480 },
    { name: "HD [1280x720]", width: 1280, height: 720 },
    { name: "Full HD [1920x1080]", width: 1920, height: 1080 },
  ],
  // local form constraints that are not stored in backend
  formMediaConstraints: {
    audio: {
      deviceId: undefined,
    },
    video: {
      deviceId: undefined,
      width: {
        min: "0",
        max: "0",
        exact: null,
      },
      height: {
        min: "0",
        max: "0",
        exact: null,
      },
      frameRate: {
        min: "0",
        max: "0",
      },
    },
  },
  mediaConstraints: {
    audio: {
      deviceId: undefined,
    },
    video: {
      deviceId: undefined,
      width: {},
      height: {},
      frameRate: null,
    },
  },
  // scanned devices list info about best resolution/frame rate constraints
  scannedConstraintsList: [],
  // scanned unchanged in form devices list info about best resolution/frame rate constraints, used for handle errors
  unchangedScannedConstraintsList: [],
  webrtcConstraints: {
    both: {
      label: "Video & Audio",
      constraints: { video: true, audio: true },
    },
    video: {
      label: "Only Video",
      constraints: { video: true, audio: false },
    },
    audio: {
      label: "Only Audio",
      constraints: { video: false, audio: true },
    },
  },
  peers: {},
  roomMembers: {
    all: [],
    senders: [],
    receivers: [],
  },
  webengineSendersSignal: [],
  modelWebengine: {
    name: "test_vitaly_",
    listLocalTurns: [{ _key: null, data: { name: "Not selected" } }],
    listRemoteTurns: [{ _key: null, data: { name: "Not selected" } }],
    turnLocal: null,
    turnRemote: null,
    forceTurnLocal: false,
    forceTurnRemote: false,
    listLocalStreamTypes: [
      {
        id: "type_webcamera",
        name: "Webcamera",
      },
      {
        id: "type_screen_capture",
        name: "Screen capture",
      },
    ],
    localStreamTypeSelected: "type_webcamera",
    inputStream: null,
    selectedSignal: null,
    commonRoomSignal: false,
    bandwidth: "2.5",
    localAudioVolume: 0.3,
    remoteAudioVolume: 1,
    local: {
      audioVolume: 0.3,
      muted: false,
      error: null,
      listVideoCodecs: ["h264", "vp8", "vp9"],
    },
    remote: {
      audioVolume: 1,
      muted: false,
    },
  },
  isLocalStreamStarted: false,
  isPreviewRemoteStreamStarted: false,
  defaultDataWebengine: {
    _key: null,
    name: "",
    signal_server_key: null,
    common_room: false,
    is_active: true,
    source: {
      video_source: {},
      audio_source: {},
      // min_framerate: "25",
      // max_framerate: "30",
      turn_server_key: null,
      force_stop: false,
      stream_type: "type_webcamera",
      resolution: {
        width: null,
        height: null,
      },
      video_codec: "h264",
      /**
       * Max framerate for RTCRtpSender, RTCRtpSendParameters
       */
      maxFramerate: 30,
      ptime: 60,
      autoGainControl: false,
      echoCancellation: true,
      noiseSuppression: true,
      sampleRate: 44100,
      sampleSize: 16,
      maxAverageBitrate: 520,
    },
    remote: {
      stream: null,
      turn_server_key: null,
      force_stop: false,
      bandwidth: "2.5",
    },
  },
  activeWebEngine: {
    _key: null,
    name: "",
    signal_server_key: null,
    signal_server_url: null, // can be set when dispatch from hash
    signal_server_room: null, // can be set when dispatch from hash
    common_room: false,
    is_active: true,
    guestLink: "",
    source: {
      video_source: {},
      audio_source: {},
      // max/min framerate for stream when get bu getUserMedia
      // min_framerate: "25",
      // max_framerate: "30",
      turn_server_key: null,
      force_stop: false,
      stream_type: "type_webcamera",
      user_name_webengine: null,
      resolution: {
        width: null,
        height: null,
      },
      video_codec: "h264",
      /**
       * Max framerate for RTCRtpSender, RTCRtpSendParameters
       */
      maxFramerate: 30,
      ptime: 60,
      autoGainControl: false,
      echoCancellation: true,
      noiseSuppression: true,
      sampleRate: 44100,
      sampleSize: 16,
      maxAverageBitrate: 520,
    },
    remote: {
      stream: null,
      turn_server_key: null,
      force_stop: false,
      bandwidth: "2.5",
    },
  },
  rawWebrtcSources: [],
  /**
   * Flag that tell us if we must or not request for WebRTC sources
   */
  dialogWebrtcSources: false,

  // remote stream
  remoteStream: null,
  remoteAudioChannels: [],
  rAudioCtx: null,
  rGainNode: null,
  rSource: null,
  rDst: null,
  rSplitter: null,
  rMerger: null,

  currentReceived: 0,
  currentSended: 0,
  currentLost: 0,
  guestLink: null,
  errorsLocalStream: {
    show: false,
    timeout: -1,
    text: "No text.",
    color: "error",
  },
  errorsRemoteStream: {
    show: false,
    timeout: -1,
    text: "No text.",
    color: "error",
  },
  doStreamRestart: false,
  excludeSenderParams: false,
  useJanus: false,
}

export default {
  state,
  mutations,
  getters,
  actions,
}
