import { RequestsEngineService } from "@/common/api/api.service"

export default {
  requestsEnginesGetList(_context) {
    return RequestsEngineService.get()
  },
  requestsEnginesAccept(context, data) {
    return RequestsEngineService.accept(data)
  },
  requestsEnginesDecline(_context, data) {
    return RequestsEngineService.decline(data)
  },
}
