import _ from "lodash"
import * as VueDeepSet from "vue-deepset"

export default {
  SAVE_SIGNAL_SERVERS_LIST(state, data) {
    state.signalServersList = data
  },
  SAVE_SIGNAL_SERVERS_LIST_COUNT(state, data) {
    state.signalServersListCount = data
  },
  SAVE_SELECTED_SIGNAL_IN_LIST(state, signals) {
    if (signals.length > 0) {
      signals.forEach((s) => {
        if (!_.find(state.signalServersList, { _key: s._key })) {
          state.signalServersList.push(s)
        }
      })
    }
  },
  RESET_SIGNAL_SERVERS_LIST(state) {
    state.signalServersList = []
    state.signalServersListCount = 0
  },
  SAVE_PAGES_LIST(state, data) {
    state.pagesList = data
  },
  SAVE_PAGES_LIST_COUNT(state, data) {
    state.pagesListCount = data
  },
  RESET_PAGE_ITEM(state, value) {
    let defaultItem = _.cloneDeep(state.defaultPage)
    _.forEach(defaultItem, (value, key) => {
      VueDeepSet.vueSet(state.activePage, [key], value)
    })
  },
  SAVE_PAGE_ITEM(state, value) {
    _.forEach(value, (v, key) => {
      VueDeepSet.vueSet(state.activePage, [key], v)
    })
  },
  SAVE_TURN_SERVERS_LIST(state, data) {
    state.turnServersList = data
  },
  SAVE_TURN_SERVERS_LIST_COUNT(state, data) {
    state.turnServersListCount = data
  },
  SAVE_SELECTED_TURN_IN_LIST(state, turns) {
    if (turns.length > 0) {
      turns.forEach((s) => {
        if (!_.find(state.turnServersList, { _key: s._key })) {
          state.turnServersList.push(s)
        }
      })
    }
  },
}
