import UserService from "@/common/api/user.service"
import { API_URL } from "@/common/config"
import querystring from "querystring"
import { siteApiInstance, formApiInstance } from "../config"

export default class ApiService {
  static init(axiosErrorCb) {
    siteApiInstance.defaults.baseURL = API_URL
    formApiInstance.defaults.baseURL = API_URL
    siteApiInstance.interceptors.response.use(null, axiosErrorCb)
    formApiInstance.interceptors.response.use(null, axiosErrorCb)
  }

  static setHeader() {
    const token = UserService.getToken()
    if (token) {
      siteApiInstance.defaults.headers.common["X-Access-Token"] = token
      formApiInstance.defaults.headers.common["X-Access-Token"] = token
    }
  }

  static query(resource, params) {
    return siteApiInstance.get(resource, params).catch((error) => {
      console.error(`[RWV] ApiService ${error}`)
    })
  }

  static get(resource, payload = {}) {
    let params = {}
    resource = resource.replace(/\/(\s+)?$/, "")
    let url = `${resource}/`
    for (let [key, value] of Object.entries(payload)) {
      if (key === "slug") {
        url = `${resource}/${value}/`
      } else {
        params[key] = value
      }
    }

    return siteApiInstance.get(url, params)
    //   .catch((error) => {
    //   console.error(`[RWV] ApiService ${error}`)
    // })
  }

  static post(resource, payload) {
    return siteApiInstance.post(`${resource}`, payload, {
      crossDomain: true,
    })
  }

  static update(resource, slug = "", params) {
    resource = resource.replace(/\/(\s+)?$/, "") // remove last slash
    return siteApiInstance.put(`${resource}/${slug}`, JSON.stringify(params))
    // return siteApiInstance.put(`${resource}`, params);
  }

  static put(resource, payload) {
    return siteApiInstance.put(`${resource}`, JSON.stringify(payload))
  }

  static delete(resource, params) {
    return siteApiInstance
      .delete(resource, { params: params })
      .catch((error) => {
        console.error(`[RWV] ApiService ${error}`)
      })
  }

  static patch(resource, payload) {
    const data = JSON.stringify(payload)
    return siteApiInstance.patch(resource, data).catch((error) => {
      console.error(`[RWV] UserService PATCH ${error}`)
    })
  }

  static postForm(resource, params) {
    return formApiInstance.post(`${resource}`, params, {
      crossDomain: true,
    })
  }

  static putForm(resource, params) {
    return formApiInstance.put(`${resource}`, params)
  }
}

export const EngineService = {
  get(params) {
    return ApiService.get("1.0/engines/", params)
  },
  create(data) {
    return ApiService.post("1.0/engines/", data)
  },
  update(slug, params) {
    return ApiService.put(`1.0/engines/${slug}/`, params)
  },
  destroy(slug) {
    return ApiService.delete(`1.0/engines/${slug}/`)
  },
  getItem(slug) {
    return ApiService.get(`1.0/engines/${slug}/`)
  },
  locations: {
    getList(params) {
      return ApiService.get("1.0/engines/locations/", params)
    },
    getItem(slug) {
      return ApiService.get(`1.0/engines/locations/${slug}/`)
    },
    create(params) {
      // @todo return this after will be resolved problem with store images on backend servers cluster (now is stored in db in base 64 format)
      //return ApiService.postForm("1.0/engines/locations/", params);
      return ApiService.post("1.0/engines/locations/", params)
    },
    update(slug, params) {
      return ApiService.put(`1.0/engines/locations/${slug}/`, params)
      // @todo return this after will be resolved problem with store images on backend servers cluster (now is stored in db in base 64 format)
      // return ApiService.putForm(`1.0/engines/locations/${slug}/`, params);
    },
    destroy(slug) {
      return ApiService.delete(`1.0/engines/locations/${slug}/`)
    },
    saveIcon(slug, params) {
      return formApiInstance.put(`1.0/engines/locations/${slug}/image/`, params)
    },
  },
}

export const PresetsEngineService = {
  get(params) {
    return ApiService.get("1.0/engines/presets/", params)
  },
  create(params) {
    return ApiService.post("1.0/engines/presets/", params)
  },
  update(slug, params) {
    return ApiService.put(`1.0/engines/presets/${slug}/`, params)
  },
  destroy(slug) {
    return ApiService.delete(`1.0/engines/presets/${slug}/`)
  },
}

export const UnattendedEngineService = {
  get(params) {
    return ApiService.get("1.0/unattended/", params)
  },
  Input: {
    order(params) {
      return ApiService.post("1.0/unattended/io/order/", params)
    },
    change(slug, params) {
      return ApiService.put(`1.0/unattended/connections/${slug}/`, params)
    },
  },
  Output: {
    order(params) {
      return ApiService.post("1.0/unattended/io/order/", params)
    },
    change(slug, params) {
      return ApiService.put(`1.0/unattended/connections/${slug}/`, params)
    },
  },
  Connection: {
    destroy(slug) {
      return ApiService.delete(`1.0/unattended/connections/${slug}/`)
    },
    Incoming: {
      create(params) {
        return ApiService.post("1.0/unattended/connections/", params)
      },
    },
    Bidirectional: {
      create(params) {
        return ApiService.post("1.0/unattended/connections/", params)
      },
    },
  },
}

export const RequestsEngineService = {
  get(slug) {
    return ApiService.get("1.0/engine/request/list/", slug)
  },
  accept(params) {
    return ApiService.post("1.0/engine/request/process/", params)
  },
  decline(params) {
    return ApiService.post("1.0/engine/request/process/", params)
  },
}

export const RemoteControlPlaylistsEngineService = {
  get(data) {
    return ApiService.get("1.0/engines/rc/", data)
  },
  info(data) {
    return ApiService.get("1.0/engines/rc/", data)
  },
  rights(params) {
    return ApiService.post("1.0/engine/remote-control-playlist/rights/", params)
  },
  getKioskPermLinkHash(params) {
    return ApiService.post("1.0/kiosk/set/perm/link/", params)
  },
  getKioskHashInfo(hash) {
    return ApiService.post(`1.0/kiosk/${hash}/`)
  },
  setKioskSurvey(data) {
    return ApiService.post(`1.0/kiosk/survey/${data.hash}/`, {
      form: data.form,
    })
  },
}

export const SignalServersService = {
  get(params) {
    return ApiService.get("1.0/settings/multi/", params)
  },
  create(params) {
    return ApiService.post("1.0/settings/multi/", params)
  },
  update(slug, params) {
    return ApiService.put(`1.0/settings/multi/${slug}/`, params)
  },
  destroy(slug) {
    return ApiService.delete(`1.0/settings/multi/${slug}/`)
  },
  getItem(slug) {
    return ApiService.get(`1.0/settings/multi/${slug}/`)
  },
  getDefault(params) {
    return ApiService.get(`1.0/settings/multi/`, params)
  },
}

export const TurnServersService = {
  get(params) {
    return ApiService.get("1.0/settings/multi/", params)
  },
  create(params) {
    return ApiService.post("1.0/settings/multi/", params)
  },
  update(slug, params) {
    return ApiService.put(`1.0/settings/multi/${slug}/`, params)
  },
  destroy(slug) {
    return ApiService.delete(`1.0/settings/multi/${slug}/`)
  },
  debug: {
    get(params) {
      return ApiService.get("1.0/debug/info/turns/status/", params)
    },
  },
}

export const JanusServersService = {
  get(params) {
    return ApiService.get("1.0/settings/multi/", params)
  },
  create(params) {
    return ApiService.post("1.0/settings/multi/", params)
  },
  update(slug, params) {
    return ApiService.put(`1.0/settings/multi/${slug}/`, params)
  },
  destroy(slug) {
    return ApiService.delete(`1.0/settings/multi/${slug}/`)
  },
}

export const BalancingGroupsService = {
  get(params) {
    return ApiService.get("1.0/settings/balancing/groups/", params)
  },
}

export const VersionMessagesService = {
  get(params) {
    return ApiService.get("1.0/app/version/message/", params)
  },
  create(params) {
    return ApiService.post("1.0/app/version/message/", params)
  },
  update(slug, params) {
    return ApiService.put(`1.0/app/version/message/${slug}/`, params)
  },
  destroy(slug) {
    return ApiService.delete(`1.0/app/version/message/${slug}/`)
  },
}

export const WebEnginesService = {
  get(params) {
    return ApiService.get("1.0/web/engines/", params)
  },
  create(params) {
    return ApiService.post("1.0/web/engines/", params)
  },
  update(slug, params) {
    return ApiService.put(`1.0/web/engines/${slug}/`, params)
  },
  destroy(slug) {
    return ApiService.delete(`1.0/web/engines/${slug}/`)
  },
  generateLink(params) {
    return ApiService.post("1.0/web/engines/access/", params)
  },
  generatedLink(params) {
    let url = `1.0/web/engines/access/?${querystring.stringify(params)}`
    return siteApiInstance.get(url)
  },
  getHashInfo(hash) {
    return ApiService.post(`1.0/web/engines/access/${hash}/`)
  },
}

export const SpeedTestsService = {
  get(params) {
    return ApiService.get("1.0/debug/speed/test/", params)
  },
  create(params) {
    return ApiService.post("1.0/debug/speed/test/", params)
  },
  update(slug, params) {
    return ApiService.put(`1.0/debug/speed/test/${slug}/`, params)
  },
  destroy(slug) {
    return ApiService.delete(`1.0/debug/speed/test/${slug}/`)
  },
  getPdf(params) {
    params.responseType = "arraybuffer"
    return ApiService.get("1.0/debug/speed/test/report/", params)
  },
  generateLink(params) {
    return ApiService.post("1.0/debug/speed/test/set/perm/link/", params)
  },
  getHashInfo(hash) {
    return ApiService.post(`1.0/debug/speed/test/perm/link/${hash}/`)
  },
  postSpeedTestResult(slug, params) {
    return ApiService.post(`1.0/debug/speed/test/${slug}/results/`, params)
  },
  getListSpeedTestResult(slug) {
    return ApiService.get(`1.0/debug/speed/test/${slug}/results/`)
  },
  deleteSpeedTestResult(slug, id) {
    return ApiService.delete(`1.0/debug/speed/test/${slug}/results/${id}`)
  },
}

export const KioskService = {
  create() {
    return ApiService.post("1.0/kiosk/set/perm/link/")
  },
}

export const EventService = {
  getList(params) {
    return ApiService.get("1.0/events/", params)
  },
  getItem(slug) {
    return ApiService.get(`1.0/events/${slug}/`)
  },
  create(params) {
    return ApiService.post("1.0/events/", params)
  },
  update(slug, params) {
    return ApiService.put(`1.0/events/${slug}/`, params)
  },
  destroy(slug) {
    return ApiService.delete(`1.0/events/${slug}/`)
  },
  invite(params) {
    return ApiService.post("1.0/events/invites/", params)
  },
  inviteHashInfo(slug) {
    return ApiService.get(`1.0/events/invites/${slug}/`)
  },
  acceptInvite(params) {
    return ApiService.post("1.0/events/invites/accept/", params)
  },
  declineInvite(params) {
    return ApiService.post("1.0/events/invites/decline/", params)
  },
  saveIcon(slug, params) {
    return formApiInstance.put(`1.0/events/${slug}/image/`, params)
  },
  getHashInfo(hash) {
    return ApiService.get(`1.0/events/access/${hash}/`)
  },
  members: {
    create(slug, params) {
      return ApiService.post(`1.0/events/${slug.event_key}/members/`, params)
    },
    update(slug, params) {
      return ApiService.put(
        `1.0/events/${slug.event_key}/members/${slug.member_key}/`,
        params
      )
    },
    destroy(slug) {
      return ApiService.delete(
        `1.0/events/${slug.event_key}/members/${slug.member_key}/`
      )
    },
    handshake(slug) {
      return ApiService.put(
        `1.0/events/${slug.event_key}/members/${slug.member_key}/handshake/`
      )
    },
  },
  roles: {
    getList(params) {
      return ApiService.get("1.0/events/roles/", params)
    },
    getItem(slug) {
      return ApiService.get(`1.0/events/roles/${slug}/`)
    },
    create(params) {
      return ApiService.post("1.0/events/roles/", params)
    },
    update(slug, params) {
      return ApiService.put(`1.0/events/roles/${slug}/`, params)
    },
    destroy(slug) {
      return ApiService.delete(`1.0/events/roles/${slug}/`)
    },
  },
}

/**
 * Store service API
 *
 */
export const StoreService = {
  events: {
    getList(params) {
      return ApiService.get("1.0/events/", params)
    },
    getItem(slug) {
      return ApiService.get(`1.0/events/${slug}/`)
    },
    create(params) {
      return ApiService.post("1.0/events/", params)
    },
    update(slug, params) {
      return ApiService.put(`1.0/events/${slug}/`, params)
    },
    destroy(slug) {
      return ApiService.delete(`1.0/events/${slug}/`)
    },
    invite(params) {
      return ApiService.post("1.0/events/invites/", params)
    },
    inviteHashInfo(slug) {
      return ApiService.get(`1.0/events/invites/${slug}/`)
    },
    acceptInvite(params) {
      return ApiService.post("1.0/events/invites/accept/", params)
    },
    declineInvite(params) {
      return ApiService.post("1.0/events/invites/decline/", params)
    },
    roles: {
      getList(params) {
        return ApiService.get("1.0/events/roles/", params)
      },
      getItem(slug) {
        return ApiService.get(`1.0/events/roles/${slug}/`)
      },
      create(params) {
        return ApiService.post("1.0/events/roles/", params)
      },
      update(slug, params) {
        return ApiService.put(`1.0/events/roles/${slug}/`, params)
      },
      destroy(slug) {
        return ApiService.delete(`1.0/events/roles/${slug}/`)
      },
    },
  },
  // @todo remove later (instead of bundle will be order products to assign to registry)
  bundles: {
    getList(params) {
      return ApiService.get("1.0/bundles/", params)
    },
    getItem(slug) {
      return ApiService.get(`1.0/bundles/${slug}/`)
    },
    create(params) {
      return ApiService.post("1.0/bundles/", params)
    },
    update(slug, params) {
      return ApiService.put(`1.0/bundles/${slug}/`, params)
    },
    destroy(slug) {
      return ApiService.delete(`1.0/bundles/${slug}/`)
    },
    registry: {
      getList(params) {
        return ApiService.get("1.0/registry/", params)
      },
      getItem(slug) {
        return ApiService.get(`1.0/registry/${slug}/`)
      },
      create(params) {
        return ApiService.post("1.0/registry/", params)
      },
      update(slug, params) {
        return ApiService.put(`1.0/registry/${slug}/`, params)
      },
      destroy(slug) {
        return ApiService.delete(`1.0/registry/${slug}/`)
      },
    },
    connections: {
      getList(params) {
        return ApiService.get("1.0/connections/", params)
      },
      getItem(slug) {
        return ApiService.get(`1.0/connections/${slug}/`)
      },
      create(params) {
        return ApiService.post("1.0/connections/", params)
      },
      update(slug, params) {
        return ApiService.put(`1.0/connections/${slug}/`, params)
      },
      destroy(slug) {
        return ApiService.delete(`1.0/connections/${slug}/`)
      },
    },
  },

  licenses: {
    getList(params) {
      return ApiService.get("1.0/licenses/", params)
    },
    getItem(slug) {
      return ApiService.get(`1.0/licenses/${slug}/`)
    },
    create(params) {
      return ApiService.post("1.0/licenses/", params)
    },
    update(slug, params) {
      return ApiService.put(`1.0/licenses/${slug}/`, params)
    },
    destroy(slug) {
      return ApiService.delete(`1.0/licenses/${slug}/`)
    },
    revoke(params) {
      return ApiService.put(`1.0/licenses/revoke/`, params)
    },
    types: {
      getList(params) {
        return ApiService.get("1.0/licenses/types/", params)
      },
    },
    statuses: {
      getList(params) {
        return ApiService.get("1.0/licenses/states/", params)
      },
    },
  },

  deals: {
    getList(params) {
      return ApiService.get("1.0/deals/", params)
    },
    getItem(slug) {
      return ApiService.get(`1.0/deals/${slug}/`)
    },
    create(params) {
      return ApiService.post("1.0/deals/", params)
    },
    update(slug, params) {
      return ApiService.put(`1.0/deals/${slug}/`, params)
    },
    destroy(slug) {
      return ApiService.delete(`1.0/deals/${slug}/`)
    },
    products: {
      getList(params) {
        return ApiService.get("1.0/products/", params)
      },
      getItem(slug) {
        return ApiService.get(`1.0/products/${slug}/`)
      },
      create(params) {
        return ApiService.post("1.0/products/", params)
      },
      update(slug, params) {
        return ApiService.put(`1.0/products/${slug}/`, params)
      },
      destroy(slug) {
        return ApiService.delete(`1.0/products/${slug}/`)
      },
      registry: {
        create(slug, params) {
          return ApiService.post(`1.0/engines/${slug}/upgrade/`, params)
        },
        update(slug, params) {
          return ApiService.put(`1.0/engines/${slug}/upgrade/`, params)
        },
        destroy(slug) {
          return ApiService.delete(`1.0/registry/${slug}/`)
        },
      },
      statuses: {
        getList(params) {
          return ApiService.get("1.0/products/statuses/", params)
        },
      },
    },
  },

  bundleTypes: {
    getList(params) {
      return ApiService.get("1.0/bundles/types/", params)
    },
  },

  plans: {
    getList(params) {
      return ApiService.get("1.0/plans/", params)
    },
    getItem(slug) {
      return ApiService.get(`1.0/plans/${slug}/`)
    },
    create(params) {
      return ApiService.post("1.0/plans/", params)
    },
    update(slug, params) {
      return ApiService.put(`1.0/plans/${slug}/`, params)
    },
    destroy(slug) {
      return ApiService.delete(`1.0/plans/${slug}/`)
    },

    purchases: {
      getList(params) {
        return ApiService.get("1.0/plans/orders/", params)
      },
      getItem(slug) {
        return ApiService.get(`1.0/plans/orders/${slug}/`)
      },
      create(params) {
        return ApiService.post("1.0/plans/orders/", params)
      },
      update(slug, params) {
        return ApiService.put(`1.0/plans/orders/${slug}/`, params)
      },
      destroy(slug) {
        return ApiService.delete(`1.0/plans/orders/${slug}/`)
      },
    },
  },
}

export const PagesService = {
  get(params) {
    return ApiService.get("1.0/pages/", params)
  },
  getItem(slug) {
    return ApiService.get(`1.0/pages/${slug}/`)
  },
  create(params) {
    return ApiService.post("1.0/pages/", params)
  },
  update(slug, params) {
    return ApiService.put(`1.0/pages/${slug}/`, params)
  },
  destroy(slug) {
    return ApiService.delete(`1.0/pages/${slug}/`)
  },
}
