import _ from "lodash"
import { getCompleteUrl } from "@/common/webrtc/helpers"

export default {
  getTestInProgres: (state) => state.testInProgress,
  getIsTestInitiator: (state) => state.isTestInitiator,
  getActiveSpeedTest: (state) => state.activeSpeedTest,
  getActiveTurnsList: (state, rootState) => {
    // get selected turn servers objects list by pks from db
    return _.find(rootState.getFilledTurnsList, function (o) {
      return state.activeSpeedTest.turn_server_urls.includes(o._key)
    })
  },
  getSelectedSignalByUrl: (state, rootState) => (speedTestKey) => {
    let signals = rootState.getFilledSignalsList
    let signalData = null
    if (speedTestKey !== -1) {
      signalData = _.find(signals, function (o) {
        let sUrl = getCompleteUrl(o.data)
        return sUrl == state.activeSpeedTest.signal_server_url
      })
    } else {
      signalData = rootState.getDefaultSignalServer
    }
    if (signalData) {
      let sUrl = getCompleteUrl(signalData.data)
      state.activeSpeedTest.signal_server_url = sUrl
    }
    return signalData
  },
  getSelectedTurnsByUrls: (state, rootState) => {
    return _.filter(rootState.getFilledTurnsList, function (o) {
      let sUrl = getCompleteUrl(o.data)
      let selectedTurnUrls = state.activeSpeedTest.turn_server_urls
      return selectedTurnUrls.includes(sUrl)
    })
  },
  getWorkerTasks: (state) => state.workerTasks,
  isLastWorkerTask: (state) => (currTask) => {
    let lastTask = state.workerTasks.slice(-1)
    return currTask.id === lastTask.id ? true : false
  },
  inProgressWorkerTask: (state) =>
    state.workerTasks.find((o) => o.status === "in-progress"),
  getSpeedTestMiliSecondsDuration: (state) =>
    state.activeSpeedTest.duration * 60 * 1000,
  getSpeedTestClientId: (state) => state.clientId,
  getSpeedTestWsPartnerId: (state) => {
    let partnerArr = state.roomCliens.filter((v) => v !== state.clientId)
    return _.get(partnerArr, "[0]")
  },
  getSpeedTestRoomClients: (state) => state.roomCliens,
  showDialogClientNotAllowed: (state, getters) => {
    return !getters.isCurrentClientAllowed
  },
  isCurrentClientAllowed: (state, getters) => {
    let roomClients = getters.getSpeedTestRoomClients
    let clientId = getters.getSpeedTestClientId
    return roomClients.includes(clientId)
  },
  showDialogTestOnlyTurns: (state, getters) => {
    let roomClients = getters.getSpeedTestRoomClients
    return (
      !state.testInProgress &&
      !getters.showDialogClientNotAllowed &&
      roomClients.length == 1
    )
  },
  showRunTestDialog: (state, getters) => {
    let roomClients = getters.getSpeedTestRoomClients
    return (
      !getters.getShowLoader &&
      !getters.showDialogClientNotAllowed &&
      !state.testInProgress &&
      !getters.isPartnerOut &&
      roomClients.length == 2
    )
  },
  showPartnerDisconnectedDialog: (state, getters) => {
    return (
      !getters.getShowLoader && state.testInProgress && getters.isPartnerOut
    ) //state.partnerDisconnected;
  },
  showTestCompletedDialog: (state, getters) => {
    return state.testInProgress && state.testCompletedDialog
  },
  getSpeedTestPartnerInfo: (state) => {
    let pInfo = ""
    let ipInfo = _.get(state.partnerInfo, "ip")
    let locationCityInfo = _.get(state.partnerInfo, "location.city")
    if (ipInfo) pInfo = pInfo + ipInfo
    if (locationCityInfo) pInfo = pInfo + ` (${locationCityInfo})`
    return pInfo
  },
  getLoaderPdf: (state) => state.loaderPdf,
  /**
   * Check is current user can save data,
   * if is first in list of Ws clients when there are more than 1 (used for ensure one time save of stats)
   */
  currentUserCanSaveData: (state) => {
    if (state.roomCliens.length > 1) {
      return state.roomCliens[0].includes(state.clientId)
    }
    return true
  },
  speedTestSyncData: (state) => state.activeSpeedTest?.sync_data || {},
  stSyncDataClients: (state, getters) =>
    getters.speedTestSyncData &&
    Array.isArray(getters.speedTestSyncData?.clients)
      ? getters.speedTestSyncData.clients
      : [],
  isTwoPointTest: (state, getters) =>
    getters.speedTestSyncData.type === "two-points",
  isPartnerOut: (state, getters, dispatch) => {
    let roomCliens = getters.getSpeedTestRoomClients
    let clientWsId = getters.getSpeedTestClientId

    return (
      getters.isTwoPointTest &&
      ((roomCliens.length === 1 && roomCliens.includes(clientWsId)) ||
        (roomCliens.length > 1 &&
          getters.stSyncDataClients.length > 1 &&
          roomCliens.includes(clientWsId) &&
          !getters.stSyncDataClients.every((arr2Item) =>
            roomCliens.includes(arr2Item)
          )))
    )
  },
  // if current ws client was not in previous test while some of participant disconnected
  currentClientNotInHistory: (state, getters) => {
    return (
      getters.isPartnerOut &&
      !getters.stSyncDataClients.includes(getters.getSpeedTestClientId)
    )
  },
  clientNotAllowDisconnect: (state, getters) =>
    !getters.getShowLoader &&
    getters.isCurrentClientAllowed &&
    getters.currentClientNotInHistory,
}
