import Vue from "vue"
import router from "../router"
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"

if (["development", "production"].includes(process.env.NODE_ENV)) {
  let envs = {
    "dev.arht.redox-dev.com": "development",
    "stage-arht.redox-dev.com": "stage",
    "app.arhtengine.com": "production",
  }
  Sentry.init({
    Vue,
    dsn: "https://d0ea3e11b90047ab842b21ed06dd21c7@o1205638.ingest.sentry.io/6363124",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_ARHT_WEB_DOMAIN, /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: envs[process.env.VUE_APP_ARHT_WEB_DOMAIN],
  })
}
