import momentTimezone from "moment-timezone"

export default {
  bundlesList: [],
  bundlesCount: 0,
  defaultBundle: {
    _key: null,
    name: "",
    is_active: true,
    description: "",
    expiration: "",
    price: "",
    connections: "",
    type_key: null,
  },
  activeBundle: {
    _key: null,
    name: "",
    is_active: false,
    description: "",
    expiration: "",
    price: "",
    connections: "",
    type_key: null,
  },
  bundleTypes: [],
  defaultProductRegistered: {
    engine: null,
    product: null,
    white_list: [],
  },
  activeProductRegistered: {
    engine: null,
    product: null,
    white_list: [],
  },
  productStatuses: [],

  // @todo maybe delete this later, there is no need for registry
  ordersRegisteredList: [],
  // @todo maybe delete this later, there is no need for registry
  ordersRegisteredCount: 0,
  // @todo maybe delete this later, there is no need for registry
  bundlesRegisteredFilters: {
    filterByEngine: null,
    filterByBundle: null,
  },
  // @todo maybe delete this later, there is no need for registry
  defaultOrderRegistered: {
    _key: null,
    bundle: null,
    engine: null,
    white_list: [],
    expiration_date: null,
    active_connections: 0,
  },
  // @todo maybe delete this later, there is no need for registry
  activeOrderRegistered: {
    _key: null,
    bundle: null,
    engine: null,
    white_list: [],
    expiration_date: null,
    active_connections: 0,
  },

  eventsList: [],
  eventsCount: 0,
  eventFilters: {
    timezone: momentTimezone.tz.guess(true),
    engine_key: null,
  },
  defaultEvent: {
    _key: null,
    name: "",
    description: "",
    start_date: null,
    end_date: null,
    country: null,
    city: null,
    group_key: null,
    time: null,
    time_zone: null,
    members: [],
    range: {
      start: null,
      end: null,
    },
    is_started: false,
    icon: null,
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    startDateTime: null,
    endDateTime: null,
    is_scheduled: false,
  },
  activeEvent: {
    _key: null,
    name: "",
    description: "",
    start_date: null,
    end_date: null,
    country: null,
    city: null,
    group_key: null,
    time: null,
    time_zone: null,
    members: [],
    range: {
      start: null,
      end: null,
    },
    is_started: false,
    icon: null,
    startDateTime: null,
    endDateTime: null,
    is_scheduled: false,
  },
  eventMembersRolesList: [],
  eventMembersRolesCount: 0,
  defaultEventMemberRole: {
    name: "",
    is_capture_studio: false,
  },
  activeEventMemberRole: {
    name: "",
    is_capture_studio: false,
  },
  engineLocationsList: [],
  engineLocationsCount: 0,
  defaultEngineLocation: {
    name: "",
    engine: null,
    country: "",
    city: "",
    time_zone: null,
    role: null,
    icon: null,
    group_key: null,
    address: {
      district: "",
      street: "",
    },
  },
  activeEngineLocation: {
    name: "",
    engine: null,
    country: "",
    city: "",
    time_zone: null,
    role: null,
    icon: null,
    group_key: null,
    address: {
      district: "",
      street: "",
    },
  },
  bundleConnectionsList: [],
  bundleConnectionsCount: 0,
  defaultBundleConnection: {
    _key: null,
    event_key: null,
    receiver_engine_key: null,
    sender_engine_key: null,
  },
  activeBundleConnection: {
    _key: null,
    event_key: null,
    receiver_engine_key: null,
    sender_engine_key: null,
  },
  loadingStartStopEvent: false,
  dealsList: [],
  dealsCount: 0,
  defaultDeal: {
    name: "",
    hubspot_key: null,
    hs_contact_email: "",
  },
  activeDeal: {
    name: "",
    hubspot_key: null,
    hs_contact_email: "",
  },
  licensesList: [],
  licensesCount: 0,
  defaultLicense: {
    serial_number: "",
    email: "",
    hardware_id: "",
  },
  activeLicense: {
    serial_number: "",
    email: "",
    hardware_id: "",
  },
  licenseStatuses: [],
}
