<template>
  <v-card class="mb-7" :height="height">
    <v-toolbar flat>
      <v-icon v-if="icon" class="mr-2">{{ icon }}</v-icon>
      <v-toolbar-title>
        {{ heading }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider></v-divider>
    <div class="pa-4">
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  name: "BaseCard",

  props: {
    heading: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      defailt: "",
    },
    height: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),

  methods: {},
}
</script>
