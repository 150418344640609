import cookies from "browser-cookies"
import { API_URL } from "../config"
import ApiService from "./api.service"

export default class UserService extends ApiService {
  static API_URL = API_URL // 'https://us.redox-dev.com',
  static API_VERSION = "1.0"
  static ID_CURENT_USER_KEY = "id_current_user"
  static ID_TOKEN_KEY = "id_token"
  static logs = false

  // localstorage start
  static getCurrentUser() {
    return window.localStorage.getItem(this.ID_CURENT_USER_KEY)
  }

  static saveCurrentUser(user) {
    window.localStorage.setItem(this.ID_CURENT_USER_KEY, JSON.stringify(user))
  }

  static destroyCurrentUser() {
    window.localStorage.removeItem(this.ID_CURENT_USER_KEY)
  }

  static getToken() {
    return (
      window.localStorage.getItem(this.ID_TOKEN_KEY) ||
      cookies.get(this.ID_TOKEN_KEY)
    )
  }

  static saveToken(token) {
    try {
      window.localStorage.setItem(this.ID_TOKEN_KEY, token)
    } catch (err) {
      console.error("Error on save in local storage", err)
    }
    cookies.set(this.ID_TOKEN_KEY, token, { expires: 365 })
  }

  static destroyToken() {
    window.localStorage.removeItem(this.ID_TOKEN_KEY)
    cookies.erase(this.ID_TOKEN_KEY)
  }

  // localstorage end

  // Auth
  // https://us.redox-dev.com/doc/#api-Auth-PostApi10UsersAuthLogin
  static userLogin(credentials) {
    if (this.logs) console.warn("userLogin", credentials)
    const url = `${this.API_URL}/${this.API_VERSION}/users/auth/login/`
    return this.post(url, credentials)
  }

  // Auth Password
  // https://us.redox-dev.com/doc/#api-Auth_Password-GetApi10UsersAuthPasswordResetReset_hash
  static userResetPasswordCheckHash(reset_hash) {
    if (this.logs) console.warn("userResetPasswordCheckHash", reset_hash)
    const url = `${this.API_URL}/${this.API_VERSION}/users/auth/password/reset/${reset_hash}/`
    return this.get(url)
  }

  // https://us.redox-dev.com/doc/#api-Auth_Password-DeleteApi10UsersAuthPasswordResetReset_hash
  static userResetPasswordDeleteHash(reset_hash) {
    if (this.logs) console.warn("userResetPasswordDeleteHash", reset_hash)
    const url = `${this.API_URL}/${this.API_VERSION}/users/auth/password/reset/${reset_hash}/`
    return this.delete(url)
  }

  // https://us.redox-dev.com/doc/#api-Auth_Password-PutApi10UsersAuthPasswordResetReset_hash
  static userResetPassword(hash, payload) {
    if (this.logs) console.warn("userResetPassword", hash)
    const url = `${this.API_URL}/${this.API_VERSION}/users/auth/password/reset/${hash}/`
    return this.put(url, payload)
  }

  static userResetPasswordConfirmation(hash) {
    if (this.logs) console.warn("userResetPasswordConfirmation", hash)
    const url = `${this.API_URL}/${this.API_VERSION}/users/auth/password/reset/${hash}/confirm/`
    return this.post(url)
  }

  // https://us.redox-dev.com/doc/#api-Auth_Password-PostApi10UsersAuthPasswordReset
  static userSendResetEmail(payload) {
    if (this.logs) console.warn("userSendResetEmail", payload)
    // const data = JSON.stringify(payload);
    const url = `${this.API_URL}/${this.API_VERSION}/users/auth/password/reset/`
    return this.post(url, payload)
  }

  // General
  // https://us.redox-dev.com/doc/#api-General-PostApi10UsersHealthCheck
  static userApiHealthCheck() {
    if (this.logs) console.warn("userApiHealthCheck")
    const url = `${this.API_URL}/${this.API_VERSION}/users/health/check/`
    return this.post(url)
  }

  // Accesses - Get permissions
  // https://us.redox-dev.com/doc/#api-Accesses-GetApi10UsersPermissions
  static getAllPermissionsList() {
    if (this.logs) console.warn("getAllPermissionsList")
    const url = `${this.API_URL}/${this.API_VERSION}/users/permissions/`
    return this.get(url)
  }

  // Users
  // https://us.redox-dev.com/doc/#api-Users-PostApi10UsersGroups
  static userCreateUserGroup(payload) {
    if (this.logs) console.warn("userCreateUserGroup", payload)
    const url = `${this.API_URL}/${this.API_VERSION}/users/groups/`
    return this.post(url, payload)
  }

  // https://us.redox-dev.com/doc/#api-Users-PostApi10UsersRoles
  static userCreateUserRole(payload) {
    if (this.logs) console.warn("userCreateUserRole", payload)
    const url = `${this.API_URL}/${this.API_VERSION}/users/roles/`
    return this.post(url, payload)
  }

  // https://us.redox-dev.com/doc/#api-Users-PostApi10Users
  static userCreate(payload) {
    if (this.logs) console.warn("userCreate", payload)
    const url = `${this.API_URL}/${this.API_VERSION}/users/`
    return this.post(url, payload)
  }

  // https://us.redox-dev.com/doc/#api-Users-DeleteApi10Users_key
  static userDelete(_key) {
    if (this.logs) console.warn("userDelete", _key)
    const url = `${this.API_URL}/${this.API_VERSION}/users/${_key}`
    return this.delete(url)
  }

  // https://stage.us.redox-dev.com/doc/#api-Users-DeleteApi10UsersKeyDeletePermanently
  static permanentlyUserDelete(_key) {
    if (this.logs) console.warn("permanentlyUserDelete", _key)
    const url = `${this.API_URL}/${this.API_VERSION}/users/${_key}/delete/permanently/`
    return this.delete(url)
  }

  // https://us.redox-dev.com/doc/#api-Users-GetApi10Users_key
  static userItem() {
    let currentUser = JSON.parse(this.getCurrentUser())
    if (this.logs) console.warn("userItem", currentUser)
    const url = `${this.API_URL}/${this.API_VERSION}/users/${currentUser._key}`
    return this.get(url)
  }

  // https://us.redox-dev.com/doc/#api-Users-GetApi10UsersGroups
  static userGroupsList(payload = {}) {
    if (this.logs) console.warn("userGroupsList", payload)
    const url = `${this.API_URL}/${this.API_VERSION}/users/groups/`
    return this.get(url, payload)
  }

  // https://us.redox-dev.com/doc/#api-Users-GetApi10UsersRoles
  static userRolesList(payload = {}) {
    if (this.logs) console.warn("userRolesList", payload)
    const url = `${this.API_URL}/${this.API_VERSION}/users/roles/`
    return this.get(url, payload)
  }

  // https://us.redox-dev.com/doc/#api-Users-GetApi10Users
  static usersList(payload = {}) {
    if (this.logs) console.warn("usersList", payload)
    const url = `${this.API_URL}/${this.API_VERSION}/users/`
    return this.get(url, payload)
  }

  // https://us.redox-dev.com/doc/#api-Groups-PatchApi10UsersGroups
  static bulkUpdateSignalServerGroups(payload) {
    if (this.logs) console.warn("bulkUpdateSignalServerGroups", payload)
    const url = `${this.API_URL}/${this.API_VERSION}/users/groups/`
    return this.patch(url, payload)
  }

  // https://us.redox-dev.com/doc/#api-Users-PutApi10UsersGroups_key
  static userUpdateGroup(_key, payload) {
    if (this.logs) console.warn("userUpdateGroup", _key, payload)
    const url = `${this.API_URL}/${this.API_VERSION}/users/groups/${_key}`
    return this.put(url, payload)
  }

  // https://us.redox-dev.com/doc/#api-Users-PutApi10UsersRoles_key
  static userUpdateRole(_key, payload) {
    if (this.logs) console.warn("userUpdateRole", _key, payload)
    const url = `${this.API_URL}/${this.API_VERSION}/users/roles/${_key}`
    return this.put(url, payload)
  }

  // https://us.redox-dev.com/doc/#api-Users-PutApi10Users_key
  static userUpdate(_key, payload) {
    if (this.logs) console.warn("userUpdate", _key, payload)
    const url = `${this.API_URL}/${this.API_VERSION}/users/${_key}`
    return this.put(url, payload)
  }

  // https://us.redox-dev.com/doc/#api-Users-GetApi10UsersGroups_key
  static userGroups(_key) {
    if (this.logs) console.warn("userGroups", _key)
    const url = `${this.API_URL}/${this.API_VERSION}/users/groups/${_key}`
    return this.get(url)
  }

  // https://us.redox-dev.com/doc/#api-Users-GetApi10UsersRoles_key
  static userRoles(_key) {
    if (this.logs) console.warn("userRoles", _key)
    const url = `${this.API_URL}/${this.API_VERSION}/users/roles/${_key}`
    return this.get(url)
  }

  // Invites

  // https://us.redox-dev.com/doc/#api-Users_Invites-GetApi10UsersInvitesRegisterHash
  static inviteCheck(hash) {
    if (this.logs) console.warn("inviteCheck", hash)
    const url = `${this.API_URL}/${this.API_VERSION}/users/invites/register/${hash}`
    return this.get(url)
  }

  // https://us.redox-dev.com/doc/#api-Users-GetApi10UsersInvites
  static invitesList(payload = {}) {
    if (this.logs) console.warn("invitesList", payload)
    const url = `${this.API_URL}/${this.API_VERSION}/users/invites/`
    return this.get(url, payload)
  }

  // https://us.redox-dev.com/doc/#api-Users-DeleteApi10UsersInvitesHash
  static inviteDelete(_key) {
    if (this.logs) console.warn("inviteDelete", _key)
    const url = `${this.API_URL}/${this.API_VERSION}/users/invites/${_key}`
    return this.delete(url)
  }

  // https://us.redox-dev.com/doc/#api-Users-PostApi10UsersInvites
  static sendInvitation(payload) {
    if (this.logs) console.warn("sendInvitation", payload)
    const url = `${this.API_URL}/${this.API_VERSION}/users/invites/`
    return this.post(url, payload)
  }

  static resendEmailInvitation(payload) {
    if (this.logs) console.warn("resendEmailInvitation", payload)
    const url = `${this.API_URL}/${this.API_VERSION}/users/invites/${payload}/resend/`
    return this.post(url)
  }

  // https://us.redox-dev.com/doc/#api-Users_Invites-PostApi10UsersInvitesRegisterHash
  static setUserDataByInvitation(_key, payload) {
    if (this.logs) console.warn("setUserDataByInvitation", _key, payload)
    const url = `${this.API_URL}/${this.API_VERSION}/users/invites/register/${_key}`
    return this.post(url, payload)
  }

  // https://us.redox-dev.com/doc/#api-Users-PostApi10UsersTermsPrivacy
  static acceptTermsPrivacy() {
    const url = `${this.API_URL}/${this.API_VERSION}/users/terms/privacy/`
    return this.post(url)
  }

  static getSignUpMicrosoftLink(payload = {}) {
    const url = `${this.API_URL}/${this.API_VERSION}/users/invites/register/microsoft/`
    return this.get(url, payload)
  }

  static signUpMicrosoft(payload = {}) {
    const url = `${this.API_URL}/${this.API_VERSION}/users/invites/register/microsoft/`
    return this.post(url, payload)
  }

  static getLoginMicrosoftLink(payload = {}) {
    const url = `${this.API_URL}/${this.API_VERSION}/users/auth/microsoft/`
    return this.get(url, payload)
  }

  static loginMicrosoft(payload = {}) {
    const url = `${this.API_URL}/${this.API_VERSION}/users/auth/microsoft/`
    return this.post(url, payload)
  }
}
