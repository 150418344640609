import { pickBy } from "lodash"

export default {
  isVideoRequired: (state) => state.is_video_required,
  isAudioRequired: (state) => state.is_audio_required,
  getWebEngine: (state) => state.webEngine,
  getEngineWebrtcSources: (state) => state.engineWebrtcSources,
  getActiveWebrtcConstraints: (state) => state.activeWebrtcConstraints,
  getSimpleArrayActiveWebrtcConstraints: (state) =>
    state.webrtcConstraints[state.activeWebrtcConstraints].constraints,
  getArrayActiveWebrtcConstraints: (state) => {
    let activeWebrtcConstraints =
      state.webrtcConstraints[state.activeWebrtcConstraints].constraints
    let videoConstraints = false
    let audioConstraints = false

    if (activeWebrtcConstraints.video) {
      videoConstraints = Object.assign(
        state.webrtcVideoConstraints.resolutions[
          state.webEngine.activeVideoConstraintResolution
        ],
        state.webrtcVideoConstraints.frameRates[
          state.webEngine.activeVideoConstraintFrameRate
        ],
        {
          deviceId: { exact: state.webEngine.active_video },
        }
      )
    }
    if (activeWebrtcConstraints.audio) {
      audioConstraints = {
        deviceId: { exact: state.webEngine.active_audio },
      }
    }
    return {
      video: videoConstraints,
      audio: audioConstraints,
    }
  },
  getAllWebrtcVideoConstraints: (state) => state.webrtcVideoConstraints,
  getBrowserWebrtcConn: (state) => state.browserWebrtcConn,
  getIfWebcameraStarted: (state) => state.webcameraStarted,
  getCurrentWebcameraLocalStream: (state) => {
    let localStream = null
    if (state.browserWebrtcConn) {
      let localStreamsArr = state.browserWebrtcConn.webrtc.localStreams
      if (localStreamsArr.length > 0) {
        localStream = localStreamsArr[0]
      }
    }
    return localStream
  },
  getCurrentWebcameraPeerId: (state) => {
    let peerId = null
    if (state.browserWebrtcConn) {
      peerId = state.browserWebrtcConn.connection.getSessionid()
    }
    return peerId
  },
  specificEnginesList: (state) => (type) => {
    return pickBy(state.allEngines, function (value, key) {
      if (type == "unattended") {
        return value.isUnattendedOnline == true
      }
      return value.isUnattendedOnline == false
      //return value.Info.control_type == type && value.Show == true;
    })
  },
  getAllEngines: (state) => state.allEngines,
  getPresetAllEngines: (state) => {
    return state.presetEngines
  },
  getPresetTreeviewEngines: (state) => {
    return state.presetTreeviewEngines
  },
  getUnattendedEngines: (state, getters) => {
    return getters.specificEnginesList("unattended")
  },
  getSupervisedEngines: (state, getters) => {
    return getters.specificEnginesList("supervised")
  },
  getCurrentSelectedEngine: (state) => state.selectedEngine,
  getSelectedConnectionEngine: (state) => state.selectedConnectionEngine,
  getUnattendedEnginesInfo: (state) => state.enginesInfo,
  showSetupStreamItem: (state) => state.showSetupStreamItem,
}
