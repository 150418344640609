<template>
  <v-app
    id="materialpro"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <router-view :key="$route.path" />
    <v-snackbar
      v-model="snackbar.show"
      :bottom="snackbar.y === 'bottom'"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
      :color="snackbar.color"
    >
      <span v-html="snackbar.text"></span>
      <v-btn class="ml-2" color="pink" @click="snackbar.show = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import _ from "lodash"

export default {
  name: "App",
  data() {
    return {
      routeName: "",
      snackbar: {
        show: false,
        y: "top",
        x: null,
        mode: "",
        timeout: 3000,
        text: "No text.",
        color: "success",
      },
    }
  },
  created: function () {
    let app = this
    app.$store.dispatch("action_InitPallette")
    app.$root.$on("snackbar", (params) => {
      _.forEach(params, function (val, key) {
        app.snackbar[key] = val
      })
    })
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/components/global.scss";
</style>
