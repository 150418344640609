<template>
  <v-list-group
    :group="group"
    :prepend-icon="item.icon"
    :sub-group="subGroup"
    :active-class="`primary white--text`"
    v-model="isActive"
    :data-testid="testid"
  >
    <template v-slot:activator>
      <v-list-item-icon v-if="item.icon" class="sicon">
        <v-icon v-text="item.icon" />
      </v-list-item-icon>

      <v-list-item-content>
        <!-- <v-list-item-title v-text="item.title" /> -->
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in children">
      <!-- {{checkPermission(child)}} -->
      <BaseItemSubGroup
        v-if="child.children && checkPermission(child)"
        :key="`sub-group-${i}`"
        :item="child"
        class="second-dd"
        :testid="getTestId(child, i)"
      />

      <BaseItem
        v-if="!child.children && checkPermission(child)"
        :item="child"
        text
        :key="`item-group-${i}`"
        :testid="testid"
      />
    </template>
  </v-list-group>
</template>

<script>
// Utilities
import kebabCase from "lodash/kebabCase"

export default {
  name: "BaseItemGroup",

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      default: () => ({
        icon: undefined,
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: [],
        permission: [],
      }),
    },
    subGroup: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    testid: {
      type: String,
      default: "undefined",
    },
  },

  computed: {
    children() {
      return this.item.children.map((item) => ({
        ...item,
        to: !item.to ? undefined : `${this.item.group}/${item.to}`,
      }))
    },
    group() {
      return this.genGroup(this.item.children)
    },
    isActive: {
      get() {
        return (
          this.item.paths &&
          this.item.paths.some((val) => val === this.$route.name)
        )
      },
      set() {},
    },
    getTestId(item, i) {
      return Object.prototype.hasOwnProperty.call(item, "testid")
        ? item.testid
        : `test-id-${i}`
    },
  },

  methods: {
    genGroup(children) {
      return children
        .filter((item) => item.to)
        .map((item) => {
          const parent = item.group || this.item.group
          let group = `${parent}/${kebabCase(item.to)}`

          if (item.children) {
            group = `${group}|${this.genGroup(item.children)}`
          }

          return group
        })
        .join("|")
    },
    checkPermission: function (items) {
      let permission = false
      for (let item of items.permission) {
        if (this.$can(item[0], item[1])) permission = true
      }
      if (
        Object.prototype.hasOwnProperty.call(items, "excludePermission") &&
        items.excludePermission.length > 0
      ) {
        for (let item of items.excludePermission) {
          if (this.$can(item[0], item[1])) permission = false
        }
      }
      return permission
    },
  },
}
</script>
