import Vue from "vue"
import Vuex from "vuex"
import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"

Vue.use(Vuex)

const state = {
  testInProgress: false,
  isTestInitiator: false, // param that mark that current sender is initiator of test
  defaultSpeedTest: {
    name: "",
    description: "",
    signal_server_url: "",
    turn_server_urls: [],
    duration: 5,
    bandwidth: 10,
    stats: {
      raw: [],
      calculated: null,
    },
    finished: false,
    source: "",
    destination: "",
    finishedLocal: false,
    sync_data: {},
  },
  activeSpeedTest: {
    name: "",
    description: "",
    signal_server_url: "",
    turn_server_urls: [],
    duration: 5,
    bandwidth: 10,
    stats: {
      raw: [],
      calculated: null,
    },
    finished: false,
    source: "",
    destination: "",
    sync_data: {},
  },
  speedTestCurrentSettings: {},
  workerTasks: [],
  workerData: {
    ptop: null,
    turns: [],
  },
  clientId: null,
  roomCliens: [],
  partnerInfo: {
    ip: null,
    location: null,
  },
  partnerDisconnected: false,
  testCompletedDialog: false,
  loaderPdf: false,
  isSavingSpeedTest: false,
  errorText: null,
}

export default {
  state,
  mutations,
  getters,
  actions,
}
