import UserService from "@/common/api/user.service"

export default {
  getUserGroups(context, params = { params: { p: 1, pp: 10 } }) {
    return new Promise((resolve, reject) => {
      UserService.userGroupsList(params)
        .then((response) => {
          let groupsList = response?.data?.data?.result || []
          let groupsCount = response?.data?.data?.full_count || 0
          context.commit("SET_USER_GROUPS_LIST", groupsList)
          context.commit("SET_USER_GROUPS_COUNT", groupsCount)
          resolve({ result: groupsList, full_count: groupsCount })
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  groupCreate(context, formData) {
    return UserService.userCreateUserGroup(formData.data)
  },
  groupUpdate(context, formData) {
    return UserService.userUpdateGroup(formData.slug, formData.data)
  },
  getUserGroup(context, _key) {
    return UserService.userGroups(_key)
      .then((response) => {
        // console.log('getUserGroup', response)
        return response.data.data.group
      })
      .catch((error) => {
        console.log(error)
      })
  },
  updateSignalServerGroups(context, data) {
    return UserService.bulkUpdateSignalServerGroups(data)
      .then((response) => {
        // console.log('updateSignalServerGroups', response)
        return response
      })
      .catch((error) => {
        console.log(error)
      })
  },
  /**
   * Get data for selected company in popup (in case when async select)
   * //@todo maybe later refactor in backend to send field that will tell what company to preserve in response
   *
   * @param commit
   * @param dispatch
   * @param params
   */
  companySelectedInlist({ commit, dispatch }, params) {
    UserService.userGroupsList(params)
      .then((response) => {
        commit("SAVE_SELECTED_COMPANY_IN_LIST", response.data.data)
      })
      .catch((err) => {
        console.log("companySelectedInlist ERROR", err)
      })
  },
  resetCompanyInfo({ commit }) {
    commit("RESET_COMPANY_INFO")
  },
}
