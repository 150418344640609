import * as VueDeepSet from "vue-deepset"

export default {
  STREAMS_INIT_ACTIVE_SIGNAL(state, value) {
    VueDeepSet.vueSet(state.streamsConfigs, ["activeSignalKey"], value)
  },
  STREAMS_SAVE_ACTIVE_SIGNAL(state, value) {
    VueDeepSet.vueSet(state.streamsConfigs, ["activeSignalKey"], value)
  },
  STREAMS_INIT_ACTIVE_COMPANY(state, value) {
    VueDeepSet.vueSet(state.streamsConfigs, ["activeCompany"], value)
  },
  STREAMS_SAVE_ACTIVE_COMPANY(state, value) {
    VueDeepSet.vueSet(state.streamsConfigs, ["activeCompany"], value)
  },
  STREAMS_SAVE_COMMON_ROOM(state, value) {
    VueDeepSet.vueSet(state.streamsConfigs, ["commonRoom"], value)
  },
  STREAMS_SAVE_ACTIVE_LIST(state, value) {
    state.streamsActive = value ? value?.streams : []
    state.streamsActiveCount = value ? value?.count : 0
  },
  STREAMS_SAVE_INACTIVE_LIST(state, value) {
    state.streamsInactive = value ? value?.streams : []
    state.streamsInactiveCount = value ? value?.count : 0
  },
  RESET_STREAMS_ACTIVE_LIST(state) {
    state.streamsActive = []
    state.streamsActiveCount = 0
  },
  RESET_STREAMS_INACTIVE_LIST(state) {
    state.streamsInactive = []
    state.streamsInactiveCount = 0
  },
  SET_STREAMS_PEER_RECEIVER(state, value) {
    state.streamsPeerReceiver = value
  },
  SAVE_DIALOG_PREVIEW_STREAM(state, value) {
    state.dialogPreviewStream = value
  },
  SAVE_DIALOG_PREVIEW_STREAM_HISTORY(state, value) {
    state.dialogPreviewStreamHistory = value
  },
  SET_STREAM_ACTIVE_ITEM(state, value) {
    state.streamActiveItem = value
  },
}
